.query-builder {
  .qb-drag-handler {
    display: none;
  }

  .ant-btn {
    border-color: $grey;
    padding: rem(4) rem(8);
    //	font-family: 'Roboto', sans-serif;
    font-size: rem(13);
    height: auto;
    outline:none;box-shadow:none;
  }

.ant-switch-checked{
  background-color: $contrastColor;
}
  .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
    border-color: $grey;
  }

  .ant-btn:focus,
  .ant-btn:hover {
    &:not([disabled]) {
      color: $contrastColor;
      border-color: darken($grey,12);
    }
  }

  .ant-select-selection:hover {
    border-color: darken($grey,12);
  }

  .ant-input-number:hover {
    border-color: darken($grey,12);
  }



    .ant-btn-danger {
      color:$red;

      &:hover {
        background-color: inherit;
          color: darken($red,7)!important;
          border-color:$red!important;
      }
    }.ant-btn-primary:not([disabled]){
      background-color: $contrastColor!important;color:$white!important;
      &:focus,&:hover{
        background-color: $contrastColor!important;color:$white!important;
      }
    }


  .ant-input-number-focused,
  .ant-select-focused .ant-select-selection {
    box-shadow: none;
  }

  .group--conjunctions {
    .ant-btn-group {
      .ant-btn:first-child {
        display: none;
      }

      .ant-btn:nth-child(2) {
        border-bottom-left-radius: rem(4);
        border-top-left-radius: rem(4);
      }
    }
  }

  .ant-btn,
  .ant-select-selection {
    border-radius: rem(4);
  }

  .group--header {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(12);
  }

  .group-or-rule-container,
  .rule-container {
    border-left: rem(4) solid $grey;
    padding: rem(12) rem(16);
    max-width: 100%;
    border-radius: rem(3);
    margin-bottom: rem(12);
    padding-right: 0;
    .group-container{
      border-color:darken($grey,6);
        background-color: lighten($grey, 6);
    }
      .rule-container {
        background-color: lighten($grey, 4);
          border-color: $contrastColor;
      }
  }

  .widget--sep{
    text-align:center;
    padding:rem(6);
    span{font-size:rem(11);font-weight: bold;color:lighten($greyDark, 8);text-transform: uppercase;}
  }



  .group-or-rule {
    &.rule {
      display: flex;
      flex-direction: row;
      width: 100%;

      .rule--field,
      .rule--operator,
      .rule--value {
        padding: rem(4);
      }
    }
  }

  .rule--header {
    order: 999999;
    align-self: center;
    justify-self: right;
    margin-left: auto;
    padding:0 rem(8);

    button {
      border: none;
      background: none;
      padding: 0;
      margin: 0!important !important;
      padding: rem(8)!important !important;
    }
  }
}


.querybuilder-statistics{

.inner-statistics{
    background-color: lighten($greyDark,10);
    color: $white;
    text-align: right;
    display:flex;
    justify-content: space-between;
    align-items: center;

    .query{
      font-size:rem(11);
    }

    span {
      font-weight: bold;
      transition: opacity 0.75s;
    }

    &.--loading {
      span {
        
        //opacity: 0.3;
      }
    }







&.segment-population-stats{
    background-color: lighten($grey,3);
    color:$greyDark;padding:rem(8) rem(16);border-radius:rem(2);
}


  }

}
