/*
    #FORM-CONFIG & USER
    #FORM-CONFIG & USER
    #FORM-CONFIG & USER
*/
.form-config,
.form-segments,
.form-triggers,
.form-user {
	max-width: rem(800);
	background: $white;
	border-radius: $borderRadius;
	margin: 0 auto $gutterBig;
	border: $border;
	text-align: left;
}

.form-config__title,
.form-segments__title,
.form-triggers__title,
.form-user__title {
	font-size: rem(20);
	line-height: rem(30);
	padding: $gutterBig;
	color: $greyDark;
	border-bottom: $border;
	margin-bottom: $gutter;
	@include mq(little-mobile) {
		padding: $gutter;
	}
}

.form-config__container-flex,
.form-segments__container-flex,
.form-triggers__container-flex,
.form-user__container-flex {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 0 $gutterBig;
	@include mq(little-mobile) {
		padding: 0 $gutterMidBig;
	}
}

.form-segments__container{
	display: flex;
	flex-direction: column;;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 0 $gutterBig;
	@include mq(little-mobile) {
		padding: 0 $gutterMidBig;
	}
}

.form-user__container-span-flex {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
}

.form-config__el,
.form-segments__el,
.form-triggers__el,
.form-user__el {
	margin: $gutter 0;
	position: relative;
	width: 100%;
	@include mq(little-mobile) {
		margin: $gutter 0;
	}
	// &:first-child{
	//     margin-top: 0;
	// }
	&--50 {
		width: calc(50% - #{rem(15)});
		// &:nth-child(2){ margin-top: 0;}
		@include mq(mobile) {
			width: 100%;
		}
	}

	.password-meter {
		width: 100%;
		background: darken($grey, 2%);
		display: block;
		border-radius: 20px;
		margin: 5px 0 0;
		height: 10px;
		overflow: hidden;

		.score {
			height: 100%;
			display: block;
			transition: all 0.5s;

			&--0 {
				width: 0;
				background: none;
			}

			&--10 {
				width: 20%;
				background: #ff5722;
			}

			&--20 {
				width: 40%;
				background: #ff9800;
			}

			&--30 {
				width: 60%;
				background: #ffc107;
			}

			&--40 {
				width: 80%;
				background: #ffeb3b;
			}

			&--50 {
				width: 100%;
				background: #8bc34a;
			}
		}
	}
}

.form-config__label,
.form-segments__label,
.form-triggers__label,
.form-user__label {
	@extend %label;
	margin-top: calc(#{$gutter} / 3);
}

.form-config__input,
.form-segments__input,
.form-triggers__input,
.form-user__input {
	@extend %input;
	padding: $gutter;
}

.form-config__container-button,
.form-segments__container-button,
.form-triggers__container-button,
.form-user__container-button {
	margin: 0 $gutterMidBig $gutterBig;
	@include mq(little-mobile) {
		margin: 0 $gutter $gutter calc(#{$gutterMidBig} / 2);
	}
}

.form-config__button,
.form-segments__button,
.form-triggers__button,
.form-user__button {
	@include button-default(big, color);
	margin: $gutterMidSmall calc(#{$gutterMidBig} / 2);
	@include mq(little-mobile) {
		margin: $gutterMidSmall calc(#{$gutterMidBig} / 2);
	}
	// &:first-child{ margin-top: 0; }
	&--delete {
		@include button-default(big, light);
	}

	&--cancel {
		@include button-default(big, light);
		border: 0;

		&:hover {
			border: 0;
		}
	}
}
/*
    #UPLOAD FILE
    #UPLOAD FILE
    #UPLOAD FILE
*/
.form-user__el-file {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	//shame scss
	.form-user__label {
		width: 100%;
	}
}

.upload-file-button {
	margin: $gutterMidSmall $gutterMidSmall $gutterMidSmall 0;
	@include button-default(medium, color);

	.icon {
		font-size: rem(20);
		margin-bottom: rem(-7);
		transform: translate3d(0,#{rem(-2)},0);
	}
}

.upload-file-button__input {
	display: none;
}

.form-user__delete-file {
	@include button-default(medium, light);
	margin-left: $gutter;
}

.upload-file-img {
	margin: $gutterMidSmall;
	height: rem(44);
}
/*
    #CIRCLE PIKER
    #CIRCLE PIKER
    #CIRCLE PIKER
*/
.react-select-container {
	width: 100%;

	> .react-select__control {
		height: rem(54);
		border: $border;
		border-radius: $borderRadius;

		&:hover {
			border-color: darken($grey, 4%);
		}
	}
	// > .react-select__menu{
	//     z-index: 999999;
	// }
	.is-open {
		z-index: 100 !important;
	}
}
/*
    #CIRCLE PIKER
    #CIRCLE PIKER
    #CIRCLE PIKER
*/
.circle-picker {
	// shame css
	width: 100% !important;
	//padding: $gutterSmall;
	//border: $border;
	//border-radius: $borderRadius;
	margin-bottom: 0 !important;

	> span > div {
		margin: $gutterMidSmall !important;
	}
}
/*
  #ERROR-MESSAGE
  #ERROR-MESSAGE
  #ERROR-MESSAGE
*/
.error-message {
	display: inline;
	// background: $red;
	color: $contrastColor;
	// margin-top: calc(#{$gutter} / 3);
	// border-radius: $borderRadius;
	// padding: 0 $gutterMidSmall;
}

.daterange-form__group {
	display: flex;
	align-items: center;
}

.daterange-value__group {
	display: flex;
	align-items: center;
//	width:50%;

	.form-config__input{
		width:rem(160);
	}

	input {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right: none;
	}

	.react-select-container {
		min-width: rem(140);

		.react-select__control {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}

.daterange-operator {
	padding: 0 rem(16);
	color: $greyDark;
	font-size: rem(24);
	font-weight: 500;
}
