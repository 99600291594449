@import 'forms/01-main';
@import 'forms/02-login';
@import 'forms/03-config-and-user'; // TO CLEAN
@import '/node_modules/react-date-range/dist/styles.css';
@import '/node_modules/react-date-range/dist/theme/default.css';

.react-select-container {
	outline: none;

	.react-select__control--is-focused {
		box-shadow: none;
	}
}

.rdrDateRangePickerWrapper {
	.rdrDateDisplayItemActive input {
		outline: none;
	}

	.rdrDateDisplay {
		color: $contrastColor!important;
		background-color: $greyLight;
	}

	.rdrStaticRange {
		&:hover {
			.rdrStaticRangeLabel {
				background-color: darken($greyLight,2%);
			}
		}

		&.rdrStaticRangeSelected {
			color: $contrastColor!important;
		}
	}

	.rdrEndEdge,
	.rdrInRange,
	.rdrStartEdge {
		background-color: $contrastColor;
	}

	.rdrDayHovered {
		span {
			border-color: $contrastColor;
		}
	}

	.rdrDayInPreview, .rdrDayEndPreview, .rdrDayStartPreview {
		border-color: $contrastColor;
	}
}
