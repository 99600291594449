.segments-container {
	// @extend .profile-container;
	// border-top: $border;
}

.segments-subcontainer {
	@extend .team-container;
}

.segments-list {
	@extend .team-list;
}

.segments-el {
	@extend .team-el;
	/*border-left-width: rem(4);    border-left-style: solid;*/
}

.segments-el__container {
	@extend .team-el__container;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.segments-el__name {
	@extend .team-el__name;
}

.segments-el__description {
	@extend .team-el__email;
}

.segments-el__button {
	@extend .team-el__button;
}

.button-segments-add {
	@extend .button-team-add;
}



.segments-list-grab {
	@extend .rules-list-grab;
	border-left: none;
	margin-left: -$gutter*2;
	padding-left: $gutter;
	padding-right: $gutter;
	width: rem(96);
}

.segments-list-grab__el {
	@extend .rules-list-grab__el;

	width: rem(26);

	&:before,
	&:after {
		width: rem(26);
	}
}