
/*
	#CONFIGURATION
	#CONFIGURATION
	#CONFIGURATION
*/
.configuration-container, .profile-container, .segments-container, .triggers-container, .dashboard-container{
	// background: lighten($grey, 2%);
	// min-height: 100vh;
	padding-bottom: rem(30);
}
	.tags-container, .domains-container, .trackers-container, .scoring-container, .rules-container, .rule-container, .organization-container, .account-container, .team-container, .plugin-container{
		margin: 0 auto;
		
		max-width: rem(800);
		border-radius: $borderRadius;
		padding: 0 $gutter;
		padding-top: $gutterBig;
		@include mq(mobile){  margin-bottom: calc(#{$gutterBig} * 2 );  }
		@include mq(little-mobile){ padding: 0 $gutterMidSmall; padding-top: $gutterBig;}
	}
/*
	#BREADCRUMB
	#BREADCRUMB
	#BREADCRUMB
*/
.breadcrumb{
	
	font-size: rem(14);
	color: $greyDark;

}
	.breadcrumb__el{
		display: inline-block;
		width: rem(10); height: rem(10);
		margin: 0 rem(10) 0 rem(6);
		position: relative;
		border-top: $border; border-right: $border; border-color: $greyDark;
		transform: rotate(45deg);
	}
/*
	#PANELS-LOADER
	#PANELS-LOADER
	#PANELS-LOADER
*/
@keyframes animateGradient { 
	0% { background-position: 100% 0%; }
	100% { background-position: 0% 0%; }
}
.panels-loader{
	@extend .form-config
}
.panels-loader__title{
	@extend .form-config__title;
	display: flex;
	align-items: center;
	&:before{
		content:'';
		display: inline-block;
		width: rem(90); height: rem(20);
		border-radius: $borderRadius;
		background: $grey;
		/* */
		background: linear-gradient(to right, $grey, rgba($grey, .0), $grey, rgba($grey, .0));
		background-size: 300% 100%;
		animation: animateGradient 1.5s linear infinite;
	}
}
.panels-loader__container{
	@extend .form-config__container-flex;
}
.panels-loader__el{
	@extend .form-config__el
}
.panels-loader__label{
	@extend .form-config__label;
	display: flex;
	align-items: center;
	&:before{
		content:'';
		display: inline-block;
		width: rem(60); height: rem(16);
		border-radius: $borderRadius;
		background: $grey;
		/* */
		background: linear-gradient(to right, $grey, rgba($grey, .5), $grey, rgba($grey, .5));
		background-size: 300% 100%;
		animation: animateGradient 1s linear infinite;
	}
}
.panels-loader__input{
	@extend .form-config__input;
	display: flex;
	align-items: center;
	&:hover{ border: $border; }
	&:before{
		content:'';
		display: inline-block;
		width: rem(250); height: rem(16);
		border-radius: $borderRadius;
		background: $grey;
		/* */
		background: linear-gradient(to right, $grey, rgba($grey, .5), $grey, rgba($grey, .5));
		background-size: 300% 100%;
		animation: animateGradient 1s linear infinite;
	}
}
.panels-loader__button{
	font-size: rem(16);
	line-height: rem(30);
	padding: $gutter $gutterBig;
	margin: 20px 0;
	border: none;
	border-radius: $borderRadius;

	display: inline-block;
	margin: rem(15) rem(10) rem(30) rem(30);
	width: rem(120); height: rem(60);
	/* */
	background: linear-gradient(to right, $contrastColor, rgba($contrastColor, .7), $contrastColor, rgba($contrastColor, .7));
	background-size: 300% 100%;
	animation: animateGradient 1.5s linear infinite;
	&--delete{
		margin-left: rem(15);
		/* */
		background: linear-gradient(to right, $grey, rgba($grey, .5), $grey, rgba($grey, .5));
		background-size: 300% 100%;
		animation: animateGradient 1.5s linear infinite;
	}
	&--cancel{
		padding: 0;
		height: rem(18);
		width: rem(60);
		margin-left: rem(30); margin-bottom: rem(50);
		/* */
		background: linear-gradient(to right, $grey, rgba($grey, .5), $grey, rgba($grey, .5));
		background-size: 300% 100%;
		animation: animateGradient 1.5s linear infinite;
	}
}