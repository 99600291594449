/*
    #FROM-LOGIN
    #FROM-LOGIN
    #FROM-LOGIN
*/

.app-login{
	width: 100vw; height: 100vh;
	background: darken($grey, 5%);
	//background: darken($contrastColor, 17%);

	.background{
		height:100vh;
		width:100%; overflow:hidden;
		div{
			height:100vh;
			width:100%;
			filter:blur(rem(10));
			opacity: .5;
			transform:scale(1.1);
		}
	}
}


.form-login {
	$loginPanelBackground: darken($white,1.2%);
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate3d(-50%,-50%,0);
	font-size: rem(14);
	line-height: rem(30);
	// max-width: 500px;
	width: 500px;
	margin: 0 auto;
	padding: $gutterMidBig $gutterBig;
	border-radius: $borderRadius;
	background: $loginPanelBackground;
	text-align: center;
	box-shadow: $shadow;
	@include mq(mobile) {
		padding: $gutterMidBig;
		width: 90%;
	}

	.login-separator {
		@include separator-default($loginPanelBackground, big)
	}
}

.form-login__title {
	font-size: rem(36);
	font-weight: 300;
	color: $greyDark;
	line-height: rem(30);
	margin: $gutterBig 0/*calc(#{$gutterMidBig} / 2)*/;
	text-align: left;
}

.form-el {
	margin: $gutterMidBig calc(#{$gutterMidBig} / 2);
	position: relative;
	text-align: left;

	svg {
		color: darken($grey, 20%);
		position: absolute;
		z-index: 0;
		left: rem(15);
		top: 50%;
		margin-bottom: rem(-2);
		transform: translate3d(0,-50%,0);
	}

	&--button {
		margin: 0 0 $gutterBig;
	}

	&--infos {
		margin: $gutterBig calc(#{$gutterMidBig} / 2) $gutterBig calc(#{$gutterMidBig} / 2);
		// margin: $gutter calc(#{$gutterMidBig} / 2) $gutterMidSmall calc(#{$gutterMidBig} / 2);
	}
}

.form-el__label {
	@extend %label;
}

.form-el__input {
	@extend %input;
}

.form-el__infos {
	color: $greyDark;
}

.button-login {
	@include button-default(big, color);
	// display: inline-block
	display: inline;
	margin: calc(#{$gutterMidBig} / 2);
}

.button-login-type {
	@include button-default(medium, color);
	display: block;
	width: 100%;
	margin: $gutter 0;
	border: none;
	box-shadow: none;
	box-shadow: 0 1px 4px rgba($black, .3);

	&.button-login-type__social{
		background-size: rem(26);
		background-position: rem(12);
		background-repeat: no-repeat;
	}

	&.button-login-type__google {
		background-color: white;
		color: darken($grey,50%);
		background-image: url("../images/pictos/google.svg");
	}
}

.button-cancel {
	@include button-default(big, light);
	margin: calc(#{$gutterMidBig} / 2);
}

.button-forgot {
	@include button-default(medium, light);
	line-height: rem(30);
	text-transform: inherit;
	display: inline;
	margin: calc(#{$gutterMidBig} / 2);
	padding: $gutter;
	border-color: transparent;

	&:first-letter {
		text-transform: uppercase;
	}
	@include mq(mobile) {
		margin-left: calc(#{$gutterSmall} * 2);
		margin-right: 0;
	}
}

.error-message-login {
	display: block;
	color: $greyDark;
	color: $contrastColor;
	margin: calc(#{$gutterMidBig} / 2);
	text-align: left;
	animation: errorMessageLogin 0.6s ease-out forwards;
	opacity: 0;
	transform: translate3d(0,0,0);
}
@keyframes errorMessageLogin {
	100% {
		opacity: 1;
		transform: translate3d(#{$gutter},0,0);
	}
}
