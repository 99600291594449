/*
	#FILTER
	#FILTER
	#FILTER
*/
.container-list-filters {
	z-index: 1;
	padding-top: $gutter;
	margin: 0 $gutter;
	// margin-top: -#{$gutter};
	// margin-top: $paddingStickyBox;
}

.mob-container-list-filters {
	// position: fixed;
	// top: 30px;
	// border-top: $border; border-bottom: $border;
	border: $border;
	border-radius: $borderRadius;
	margin-top: $paddingStickyBox;

	@include mq(mobile) {
		// flex-direction: column;
		// // align-items: center;
		// justify-content: flex-start;
		position: fixed;
		z-index: 20;
		left: 50%;
		top: 50%;
		width: 100%;
		height: 100%;
		background: rgba($contrastColor, .8);
		padding: $gutter;
		opacity: 0;
		transform: translate3d(-50%, 100%, 0);

		&.-display {
			opacity: 1;
			transform: translate3d(-50%, -50%, 0);

			>.list-filters {
				opacity: 1;
				transform: translate3d(-50%, -50%, 0);
			}
		}
	}
}

.list-filters {
	display: flex;
	background: $white;
	padding: $gutterMidSmall;

	@include mq(tablet) {
		flex-wrap: wrap;
	}

	@include mq(mobile) {
		flex-direction: column;
		// align-items: center;
		justify-content: flex-start;
		position: fixed;
		z-index: 20;
		left: 50%;
		top: 50%;
		width: calc(100% - #{rem(30)});
		height: auto;
		// transform: translate3d(-50%,100%,0);
		border: 0;
		// background: rgba($contrastColor, .9);
		border-radius: $borderRadius;
		padding: 0;
		// opacity: 1; 
		transition: all .2s ease-out;
		transform: translate3d(-50%, -48%, 0);
	}

	// @include mq(little-mobile){
	// 	width: calc(100% - #{rem(15)});  height: calc(100% - #{rem(180)});
	// }
}

.list-filters__el {
	position: relative;
	flex-grow: 2;
	padding: $gutterMidSmall;
	height: auto;

	@include mq(tablet) {
		width: calc(50% - #{rem(15)});

		@include mq(mobile) {
			width: 100%;
		}
	}

	@include mq(mobile) {
		padding: $gutterMidSmall $gutterMidBig;
		flex-grow: 0;
	}

	&:last-child {
		margin-right: 0;
	}

	.svg-input-filters {
		color: darken($grey, 20%);
		position: absolute;
		z-index: 0;
		left: rem(22.5);
		top: 50%;
		transform: translate3d(0, -50%, 0);

		@include mq(mobile) {
			left: rem(35);
		}
	}

	&--search {
		@include mq(tablet) {
			width: 100%;
		}
	}

	&--date {
		@include mq(tablet) {
			display: none;
		}
	}

	&--button {
		flex-grow: 0;

		@include mq(tablet) {
			width: auto;
			text-align: center;
		}

		@include mq (mobile) {
			padding: $gutterMidBig;
			padding-top: $gutterMidSmall;
		}
	}

	&--list-grid {
		@include mq(little-tablet) {
			display: none;

			* {
				display: none;
			}
		}

		// @include mq(mobile){
		// 	// display: inherit;
		// 	// flex-grow: 2;
		// }
	}

	&--title {
		display: none;

		@include mq (mobile) {
			display: block;
			position: relative;
			font-size: rem(20);
			line-height: rem(30);
			color: $greyDark;
			border-bottom: $border;
			padding: $gutterMidBig;
			margin-bottom: $gutter;
		}
	}
}

.input-filters {
	@extend %input;
	position: relative;
	z-index: 1;
	padding-left: rem(40);
}

// .select-filters{
// 	z-index: 2;
// }

.button-filters {
	@include button-default(medium, light);
	line-height: rem(20);
}

.container-datepicker-filters {
	position: relative;
	display: block;
	text-align: center;
	background: $white;

	>div:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: rem(2);
		background: $grey;
	}
}

.datepicker-filters {
	width: auto;
	border: $border;
	border-radius: $borderRadius;
	margin: $gutter;
}

.button-change-view-filters {
	outline: none;
	cursor: pointer;
	position: relative;
	width: 50px;
	height: 100%;
	border: $border;
	transition: all .2s ease;

	&:first-child {
		border-radius: rem(5) 0 0 rem(5);
		border-right: rem(1) solid $grey;
	}

	&:last-child {
		border-radius: 0 rem(5) rem(5) 0;
		border-left: rem(1) solid $grey;
	}

	&:hover {
		background: lighten($grey, 2%);
	}

	&.-active {
		background: lighten($grey, 2%);
	}
}

.button-change-view-filters__el {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	width: rem(17);
	height: rem(6);
	transform: translate3d(-50%, -50%, 0);
	border-radius: rem(2);
	background: darken($grey, 20%);

	&:first-child {
		transform: translate3d(-50%, -125%, 0);
	}

	&:last-child {
		transform: translate3d(-50%, 25%, 0);
	}

	&--for-4 {
		background: transparent;
		display: flex;
		justify-content: space-between;

		&:before,
		&:after {
			content: '';
			display: block;
			width: 42%;
			height: 100%;
			border-radius: rem(2);
			background: darken($grey, 20%);
		}
	}

}

.button-refresh-filters {
	@include button-default(medium, color);
	font-size: rem(14);
	line-height: rem(24);

	@include mq(mobile) {
		width: 100%;
		margin-bottom: rem(7.5);
	}
}

//
.container-leads-item {
	padding: rem(7.5) rem(7.5) rem(7.5) rem(7.5);
	min-height: 100vh;

	// background: lighten($grey, 2%);
	@include mq(little-mobile) {
		padding: 0;
		padding-top: $gutterMidSmall;
	}

	>div>.infinite-scroll-component {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	&--list {
		>div>div>.link-leads-item {
			width: calc(100% - #{rem(15)});
		}
	}

	&--grid {
		>div>div>.link-leads-item {
			width: calc(33.333% - #{rem(15)});

			@include mq(tablet) {
				width: calc(50% - #{rem(15)});
			}

			@include mq(little-tablet) {
				width: calc(100% - #{rem(15)});
			}
		}
	}
}

@keyframes animLinkLeadsItem {
	from {
		opacity: 0;
		transform: translate3d(0, rem(20), 0)
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0)
	}
}

/*
	#RESPONSIVE ADD
	#RESPONSIVE ADD
	#RESPONSIVE ADD
*/
.floating-action-filters {
	display: none;

	@include mq(mobile) {
		display: block;
	}

	position: fixed;
	z-index: 20;
	bottom: rem(90);
	right: $gutter;

	@include mq(little-mobile) {
		bottom: rem(75);
		right: $gutterMidSmall;
	}

	width: rem(60);
	height: rem(60);
	border: 0;
	border-radius: 50%;
	background: $contrastColor;
	color: $white;
	font-size: rem(20);
	box-shadow: 0 0 20px rgba($contrastColor, .3);
	transition: all .3s ease;

	.icon,
	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate3d(-50%, -40%, 0);
	}

	&.-active {
		transform: rotate(180deg);
		opacity: 0;
	}
}

.close-action-filters {
	position: absolute;
	top: 50%;
	right: $gutterMidBig;
	width: rem(20);
	height: rem(20);
	border: none;
	transform: translate3d(0, -50%, 0);
	background: none;

	&:before,
	&:after {
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		width: 100%;
		height: rem(2);
		background: darken($grey, 20%);
	}

	&:before {
		transform: translate3d(-50%, -50%, 0) rotate(45deg);
	}

	&:after {
		transform: translate3d(-50%, -50%, 0) rotate(-45deg);
	}
}

/*
    #FILTER 02
    #FILTER 02
    #FILTER 02
*/
/* 
	#LEADS-SCORES-USER
	#LEADS-SCORES-USER
	#LEADS-SCORES-USER
*/
.leads-scores-user {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: $gutterMidSmall;

	// @include mq(little-mobile){
	// 	padding: cacl(#{$gutterMidBig} / 2 );
	// }
	&.-display {
		padding: rem(7.5);
		border-bottom: $border;
	}

	&--config {
		border-bottom: 0;
	}
}

.leads-scores-user__el {
	display: flex;
	margin: $gutterMidSmall;
	padding: calc(#{$gutterSmall} * 2);
	background: $grey;
	border-radius: $borderRadius;
	border: none;
	font-size: rem(12);
	font-weight: 500;
	line-height: rem(14);

	&--button {
		outline: none;
		cursor: pointer;
		text-transform: uppercase;
		background: $contrastColor;
		color: $white;
		font-weight: 700;

		&:hover {
			background: darken($contrastColor, 2%);
		}
	}
}

.leads-scores-user__color {
	width: rem(15);
	height: rem(15);
	margin-right: rem(7.5);
	border-radius: 50%;
}

.leads-scores-user__name {
	margin-right: rem(7.5);
}

.leads-scores-user__score {
	font-weight: 400;
}

//
.container-leads-filters-user {
	display: flex;
	flex-wrap: wrap;
	border-left: $border;
	border-right: $border;
	border-bottom: $border;
	background: $white;
	margin: 0 $gutter;
}

.leads-filters-user {
	position: relative;
	display: flex;
	flex-grow: 1;
	padding: rem(7.5);
	border-left: $border;
	margin-left: rem(-2);

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: rem(2);
		transform: translate3d(0, #{rem(-2),0});
		background: $grey;
	}
}

/* 
	#LEADS-SEGMENTS-USER
	#LEADS-SEGMENTS-USER
	#LEADS-SEGMENTS-USER
*/
.leads-segments-user {
	position: relative;
	display: flex;

	padding: $gutterMidSmall $gutterMidSmall;
	border-left: $border;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 200%;
		height: rem(2);
		transform: translate3d(0, #{rem(-2),0});
		background: $grey;
	}
}

.leads-segments-user__el {
	position: relative;

	&:after {
		content: ',';
		position: absolute;
		top: 50%;
		right: 0;
		color: $greyDark;
		transform: translate3d(-50%, -50%, 0);
	}

	&:last-child {
		&:after {
			display: none;
		}
	}
}

.leads-segments-user__link {
	display: inline-block;
	margin: $gutterMidSmall 0;
	// padding: calc( #{$gutterSmall} * 2);
	padding: calc(#{$gutterSmall} * 2) $gutter;
	font-size: rem(14);
	font-weight: 500;
	line-height: rem(14);
	color: darken($contrastColor, 10%);
	font-weight: 400;
	transition: all .2s ease;
	text-decoration: none;

	// color: rgb(0, 82, 204);
	&:hover {
		color: $contrastColor;
		text-decoration: underline;
	}
}

/*
	#LABEL-CHEBOX
	#LABEL-CHEBOX
	#LABEL-CHEBOX
*/
.label-checkbox {
	display: inline-block;
	font-size: rem(14);
	cursor: pointer;

	input {
		display: none;
	}

	span {
		position: relative;
		display: inline-block;
		width: rem(14);
		height: rem(14);
		margin-right: rem(7.5);


		border-radius: 3px;
		border: rem(2) solid darken($grey, 15%);
		transform: translate3d(0, #{rem(3)}, 0);

		&:after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: rem(12);
			height: rem(12);
			// border-radius: rem(1.5);
			border-radius: 50%;
			background: darken($grey, 20%);
			transform: translate3d(-50%, -50%, 0);
			opacity: 0;
			// transition: all .2s ease;
			transition: all .2s ease-in;
		}
	}

	span .svg {
		position: absolute;
		z-index: 1;
		left: 50%;
		top: 50%;
		height: 120%;
		width: 120%;
		transform: translate3d(-50%, -50%, 0);

		.check {
			stroke: white;
			fill: none;
			stroke-width: 12;
			stroke-dasharray: 400;
			stroke-dashoffset: 400;

		}
	}

	&:hover input~span {
		&:after {
			opacity: 0;
			transform: translate3d(-50%, -50%, 0) scale(2.5);
		}
	}

	input:checked~span {
		&:after {

			background: $contrastColor;
			opacity: 1;
			transform: translate3d(-50%, -50%, 0) scale(1);

		}

		background: $contrastColor;
		border-color: $contrastColor;

		>svg>path {
			animation: check .4s ease forwards;
		}
	}

}

//
.checkbox-filters-user {
	padding: rem(5.5);
	padding-left: rem(9);
	margin: $gutterMidSmall;
	border-radius: $borderRadius;
	text-align: center;
	border: $border;
	transition: all .2s ease;

	&:hover {
		background: lighten($grey, 3%);
	}
}



.lead-details__container {
	display: flex;
	//flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
	height: 100%;

	@include mq(tablet) {
		flex-direction: column;
	}

	.container-list-filters {
		// width: 840px;
		// height: 100%;

		// @include mq(desktop) {
		// 	width: 700px;
		// }

		height: 100%;
		width: 50%;


		background: $white;

		.list-filters__box {
			padding: $gutter;
			margin: 0;
		}

		padding:0;
		border-left: $border;
		margin: 0;
		z-index: 9;
		order: 2;

		@include mq(tablet) {
			height: auto;
			border-bottom: $border;

			order: 1;
			border-left: none;
		}

		.leads-scores-user {
			padding: 0;
			width: 100%;
		}

		.leads-item__container {
			padding: 0;
			margin: 0;

			&--last-seen {
				margin-left: $gutter;
				padding-left: $gutter;
			}

		}

		.leads-scores-user__el {
			margin: 0;
			margin-top: $gutter/2;
			margin-right: $gutter/2;
		}

		.leads-item__container--last-seen {
			&:before {
				display: none;
			}
		}
	}

	.vertical-timeline {

		//width: 50%;

		order: 1;

		@include mq(tablet) {
			order: 2;
		}

		margin: 0;
		margin-left: $gutter !important;
		margin-top: $gutter*3 !important;
		margin-right: $gutter*3 !important;

		@include mq(tablet) {
			margin: 0 !important;
		}
	}

	.leads-item--user {
		border: none;
		margin: 0;
		margin-bottom: $gutter/2;
	}

	.container-leads-filters-user {
		border: none;
		margin: 0;
	}

	.leads-segments-user {
		border: none;
		margin: 0;
		padding: 0;
		flex-wrap: wrap;
		margin-top: $gutter;
		width: 100%;

		&:before,
		&:after {
			display: none;
		}
	}

	.leads-segments-user__link,
	.leads-segments-user__el {
		margin: 0;
		padding: 0;

		&:before,
		&:after {
			display: none;
		}
	}

	.leads-segments-user__link {
		background-color: $grey;
		padding: $gutter/2;
		color: darken($greyDark, 20%);
		margin-right: $gutter/2;
		margin-bottom: $gutter/2;
		font-size: rem(12);
		text-decoration: none;
		display: flex;
		align-items: center;

		svg {
			fill: $greyDark;
			margin-right: $gutter/2;
		}
	}
}



.link-leads-item {
	display: block;
	background: $white;
	margin: $gutterMidSmall;
	border: $border;
	border-radius: $borderRadius;
	text-decoration: none;
	color: $black;
	opacity: 1;
	animation: .5s ease animLinkLeadsItem forwards;
	// @include mq(little-mobile){
	// 	margin: $gutterSmall $gutterMidSmall;
	// }

	&:hover {
		color: $black;
	}
}

// @for $i from 1 through 36 {
// 	.link-leads-item{
// 		opacity: 0;
// 		// :nth-child(1-40)
// 		&:nth-child(#{$i}) {
// 			// Delay the animation. Delay increases as items loop.
// 			animation-delay: $i * (1s / 18);
// 		}
// 	}
// }
.leads-item {
	background: $white;
	display: flex;
	justify-content: space-between;
	border-radius: $borderRadius;

	&--user {
		// border-top: $border;
		// border-bottom: $border;
		// border-radius: 0;
		border: $border;
		margin-top: rem(31);
	}
}

.leads-item__container {
	display: flex;
	align-items: center;
	padding: $gutter;
	width: 60%;

	&--last-seen {
		width: 40%;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		border-left: 2px solid $grey;
		overflow: hidden;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			z-index: 2;
			right: 0;
			top: 0;
			width: 50%;
			height: 100%;
			border-radius: 0 rem(5) rem(5) 0;

			@include bg-gradient(-90deg, $white, rgba, 0, no-repeat);
		}

		// shame css
		>.leads-item__subcontainer {
			overflow: visible;
		}
	}
}

.leads-item__subcontainer {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	// * { text-overflow: ellipsis;}
	&--infos {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 100%;
		margin-left: rem(15);
	}

	//shame
	>svg {
		margin-right: rem(10);
	}
}

.leads-item__img {
	min-width: rem(48);
	min-height: rem(48);
	width: rem(48);
	height: rem(48);
	display: block;
	border-radius: 50%;

	&--no-img {
		background-color: darken($grey, 5%);
		background-image: url("../images/pictos/user.svg");
		background-size: 84%;
		background-repeat: no-repeat;
		background-position: center bottom;

		&.-colored {
			background-color: desaturate(lighten($contrastColor, 50%), 40%); //darken($red, 5%);
		}
	}
}

.leads-item__name {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	&.-unknown {
		color: $greyDark;
	}
}

.leads-item__place {
	white-space: nowrap;
	margin-top: rem(10);

	//shame
	>span>span {
		width: rem(16) !important;
		height: rem(15) !important;
		border-radius: 50%;
		background-size: cover !important;
		background-position: center center !important;
		margin-right: rem(10) !important;
	}
}



.container-leads-lookalikes-user {
	@include mq(tablet) {
		display: none;
	}

	margin-top:$gutter*2;

	h3 {
		color: $greyDark;
		font-weight: 500;
		font-size: rem(15);
		//text-transform: uppercase;
		letter-spacing: rem(.5);
	}
}

.lead-lookalikes-list {
	display: flex;
	flex-wrap: wrap;
	margin: -$gutter/3*2/2 -$gutter*2/4/2;
	margin-top: 0;

	.link-leads-item {
		width: calc(50% - #{$gutter});

		.leads-item__container {
			padding: $gutter/3*2 $gutter*2/4;
		}

		.lookalike-score {
			min-width: rem(48);
			min-height: rem(48);
			width: rem(48);
			height: rem(48);
			margin-left: rem(-48);
			display: flex;
			align-content: center;
			justify-content: space-around;
			flex-wrap: wrap;
			border-radius: 50%;
			background-color: rgba($white, .3);

			.inner {
				font-size: rem(16);
				color: lighten($black, 28%);
				font-weight: bold;

			}
		}
	}
}