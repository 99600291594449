/*
	#INTEGRATION TRACKERS
	#INTEGRATION TRACKERS
	#INTEGRATION TRACKERS
*/
.trackers-subcontainer{
	background: $white;
	border-radius: $borderRadius;
	overflow: hidden;
	border: $border;
	display: flex;
	flex-wrap: wrap;
	position: relative;
}
.trackers-step{
	position: relative;
	padding: rem(60) rem(90) rem(60) rem(150);	
	@include mq(mobile) {
		padding: rem(30) rem(60) rem(30) rem(90);	
	}
	@include mq(little-mobile) {
		padding: rem(30) rem(30) rem(30) rem(90);	
	}
	&:before{
		content:'';
		position: absolute;
		left: rem(74); top: rem(165); //top: 50%;
		// transform: translate3d(0,#{rem(60)}, 0);
		width: rem(2); height: calc( 100% - #{rem(120)});
		background: $grey;
		@include mq(mobile) {
			left: rem(44); top: rem(120); //top: 50%;
		}
	}
	&--last-step:before{display: none;}
}
	
	.trackers-step__title{
		font-size: rem(16);
		line-height: rem(30);
		margin-bottom: rem(15);
		font-weight: 700;
		text-transform: uppercase;
		color: $greyDark;
	}
	.trackers-step__text{
		font-size: rem(14);
		line-height: rem(20);
		margin: 0 0 rem(20) 0;
		
	}
	.trackers-step__link{
		@extend %button;
		font-size: rem(14);
		line-height: rem(20);
		display: inline-block;
		margin: 0;
		margin-right: rem(15);
		padding: $gutter;
		background: $contrastColor;
		&:hover{ background: darken($contrastColor, 4%); }		
		&.-disable{ background: darken($grey, 10%); &:hover{ background: darken($grey, 20%); } }
		&.active{
			background: $contrastColor;
			&:hover{ background: darken($contrastColor, 4%); }		
		}
	}
	.trackers-step__copied{ 
		color: $contrastColor; 
		opacity: 0; 
		display: inline-block; 
		margin: $gutter 0;
		animation: animCopied .3s ease-out forwards;
	}
	@keyframes animCopied {
		0% { opacity: 0; transform: translate3d(0,5px,0); }
		100% { opacity: 1; transform: translate3d(0,0,0); }
	}

.trackers-script, .trackers-pixel{
	font-size: rem(14);
	line-height: rem(20);
	padding: $gutter;
	border-radius: $borderRadius;
	// background: lighten($grey, 2%);
	margin: $gutterMidBig 0;
	word-break: break-all;
}
/*
	#CHECK CIRCLE
	#CHECK CIRCLE
	#CHECK CIRCLE
*/
.trackers-step-circle{
	position: absolute;
	top: rem(60); left: rem(30);
	// transform: translate3d(0,-50%,0);
	width: rem(90); height: rem(90);
	line-height: rem(90);
	text-align: center;
	border: $border;
	border-radius: 50%;

	font-size: rem(30);
	font-weight: 700;
	color: darken($grey, 10%); 
	transition: border 0s ease .3s;

	@include mq(mobile) {
		top: rem(30); left: $gutter;
		width: rem(60); height: rem(60);
		line-height: rem(60);
	}

	&:before{
		content: '';
		position: absolute; z-index: 0;
		width: 100%; height: 100%;
		left: 50%; top: 50%;
		border-radius: 50%;
		transform: translate3d(-50%,-50%, 0) scale(0.5);
		background: $contrastColor;
		opacity: 0;
		transition: all .3s ease;
	}
	&.-active{
		color: $white;
		border-color: $contrastColor; 
		&:before{ opacity: 1;  transform: translate3d(-50%,-50%, 0) scale(1); }

		&:before{transform: translate3d(-50%,-50%,0) scale(1); }
		.trackers-step-circle__number{ opacity: 0; }
		.trackers-step-circle__container{  transform: rotate(360deg); }
		.trackers-step-circle__container .svg .check{animation: check .4s ease forwards;}
	}
}
.trackers-step-circle__container{
	position: relative; z-index: 1;
	display: inline-block;
	transition: all .3s ease;
	.svg{
		position: absolute; z-index: 1;
		width: rem(24); 
		left: 50%; top: 50%;
		transform: translate3d(-50%, -50%,0);
		// background: blue;
		.check{
			stroke: $white;
			fill: none;
			stroke-width: 8;
			stroke-dasharray: 400;
			stroke-dashoffset: 400;
		}
	}
}
.trackers-step-circle__number{ transition: all .4s ease ; }