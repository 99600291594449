/*
	#DOMAINS
	#DOMAINS
	#DOMAINS
*/
.domains-list{
	background: $white;
	border-radius: $borderRadius;
	border: $border;
	margin-bottom: $gutterBig;

	padding: $gutterMidSmall;
	@include mq(little-mobile){
		padding: cacl(#{$gutterMidBig} / 2 );
	}
}
	.domains-list__el{
		display: inline-block;
		margin: $gutterMidSmall;
		max-width: 100%;
	}
	.domains-list__link{
		border: 0;
		display: inline-block;
		font-size: rem(14);
		// line-height: rem(15);
		// line-height: rem(20);
		background: $grey;
		padding: calc(#{$gutterSmall} * 2)  $gutter; padding-left: rem(10);
		border-radius: $borderRadius;
		text-align: left;
		word-break: break-all;
		overflow: hidden;

		@include mq(little-mobile){
			max-width: 96%;
		}
		&:hover{
			background: darken($grey, 2%);
		}
	}
	.domains-list__link-tag{
		background: darken($grey, 6%);
		border-radius: rem(3);
		padding: rem(1) rem(5);
		margin-right: $gutterMidSmall;
	}
	.domains-list__button{
		font-size: rem(14);
		line-height: rem(15);
		display: inline-block;
		padding: calc( #{$gutterSmall} * 2);
		border-radius: $borderRadius;
		border: 0;
		background: $contrastColor;
		color: $white;
		font-weight: 700;
		text-transform: uppercase;
		&:hover{ background: darken($contrastColor, 4%);}
	}

	.dns-info__value-check{    align-items: center;
		svg{
			margin-right:rem(4);
		}
		&.dns-info__value-ok{
			color:$green;
		}
			&.dns-info__value-notok{
				color:$red;
			}
	}


.dns-table{
	background:$grey;
	margin-top:rem(8);
	margin-bottom:rem(8);
	font-family: monospace;
	font-size:rem(12);
	tr{
	td{
		padding:rem(8);
	}}
}

.dns-help__container{
	margin-top:rem(24);
//	display:flex;

	p, .dns-help-registrar{
		display:inline-block;
	}

	.dns-help-registrar{
		margin-left:rem(24);
		width:rem(124);
	}
}
