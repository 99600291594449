//@import 'node_modules/react-tooltip/standalone/react-tooltip.min.js';

/*
	#TIMELINE
	#TIMELINE
	#TIMELINE
*/
.vertical-timeline {
	max-width: 100%;
	//shame
	padding: 0 !important;
	margin: 0 auto !important;
	//width: 100% !important;

	&:before {
		width: rem(3);
		left: rem(34);
		background: darken($grey, 5%);
	}

	>div {
		background: lighten($grey, 2%);
		padding-top: rem(15);
		min-height: 80vh;
	}
}

.vertical-timeline-element--work {
	@include mq(tablet) {
		padding: $gutter;
		margin: $gutter 0 $gutter $gutter;
	}

	@include mq(little-mobile) {
		margin-right: 0;
	}
}

.vertical-timeline-element-content {
	@include mq(tablet) {
		margin-left: rem(40);
	}
}

.vertical-timeline-element-icon {
	// position: absolute;
	// box-shadow: none;
	box-shadow: 0 0 0 rem(5) lighten($grey, 2%);
	// // line-height: rem(40);
	// // text-align: center;
	// svg{
	// 	// position: absolute;
	// 	// left: 50%;  top: 50%;
	// 	// width: rem(20);  height: auto;
	// 	// transform: translate3d(-50%, -50%, 0);
	// }

	top: auto;
	left: 16px;

	@include mq(tablet) {
		left: 0;
	}
}

.vertical-timeline-element-content .vertical-timeline-element-date,
.vertical-timeline-element-content p {
	font-size: rem(14);
}

//Shame css
@media only screen and (min-width: 1170px) {
	.vertical-timeline-element-content {
		//width: 48% !important;
	}
}

.vertical-timeline-element-content {
	background: transparent;
	box-shadow: none;
	padding: 0;

	&:before {
		display: none;
	}

	margin-left: 78px;




	@include mq(tablet) {
		margin-left: 56px;
	}


	@include mq(mobile) {
		margin-left: 48px;
	}



	//margin-top: 36px;
}

//
// #leads-event-user
//

.leads-event-user__container-head {
	display: flex;
	justify-content: space-between;
	background: darken($grey, 4%);
	padding: rem(7.5);
	border-radius: $borderRadius;
}

.leads-event-user__vue-type {
	font-size: rem(16);
	line-height: rem(20);
	font-weight: 700;
	color: $contrastColor;
	padding-left: rem(3);
	transform: translate3d(0, #{rem(2)}, 0);
	display: flex;
	justify-content: space-between;
	width: 100%;

	&::first-letter {
		text-transform: uppercase;
	}
}

.leads-event-user__consecutive-time {
	font-size: rem(16);
	line-height: rem(20);
	padding: 0 rem(7.5);
	border-radius: $borderRadius;
	background: $contrastColor;
	//shame css
	font-weight: 700 !important;
	margin: 0 !important;
	color: $white !important;

	//shame css
	>svg {
		font-size: rem(10);
		margin-right: rem(-1);
	}
}

.leads-event-user__container {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	padding: rem(7.5);
	font-weight: 500;
	color: $greyDark;
	border-bottom: rem(2) solid darken($grey, 5%);

	&:last-child {
		border-bottom: 0;
	}

	&--ip-tag,
	&--moment {
		padding: rem(7.5);
		justify-content: space-between;
		border-bottom: 0;
	}

	&--mail,
	&--url {
		background: darken($grey, 4%);
		border-radius: $borderRadius;
		border-bottom: 0;
		padding: rem(1);
	}

	//shame css
	>div>svg {
		margin-bottom: rem(-2);
		margin-right: rem(5);
	}
}

.leads-event-user__subtitle {
	font-weight: 700;
}

.leads-event-user__date {}

.leads-event-user__time {}

.urlurl {
	//Shame css
	color: $greyDark !important;
	margin: 0 !important;
	padding: $gutterSmall $gutterMidSmall;
	background: darken($grey, 4%);
	border-radius: $borderRadius;

	>svg {
		margin-bottom: rem(-2);
		margin-right: rem(5);
	}
}

.leads-event-user__city,
.leads-event-user__country,
.leads-event-user__data-table,
.leads-event-user__device-browser,
.leads-event-user__device-system,
.leads-event-user__device-type,
.leads-event-user__ip,
.leads-event-user__score-tag,
.leads-event-user__url {
	//Shame css
	color: $greyDark !important;
	margin: 0 !important;
	padding: rem(0) rem(7.5);

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;
	}

	>svg {
		margin-bottom: rem(-2);
		margin-right: rem(5);
	}

	>.icon {
		text-transform: uppercase;
		font-weight: 700;
		color: $greyDark;
		margin-right: rem(5);
	}

	>.leads-event-user__score-color {
		display: inline-block;
		width: rem(10);
		height: rem(10);
		border-radius: 50%;
		margin-right: rem(5);
	}
}

.leads-event-user__url {
	position: relative;
	overflow: hidden;
	overflow-wrap: break-word;
	//shame css
	padding: rem(4) rem(6.5) !important;

	&:first-letter {
		padding-left: rem(20);
	}

	>svg {
		position: absolute;
		left: rem(6.5);
		top: rem(8);
	}
}

.leads-event-user__container-data-table {
	width: 100%;
}

.leads-event-user__data-table {
	//shame css
	padding: rem(4) rem(6.5) !important;
	display: inline-block;

	>svg {
		margin-bottom: rem(-2);
		margin-right: rem(5);
	}
}

/*
	#NO-ICON
*/
.tag-name-no-icon {
	background: darken($grey, 10%);
	padding: rem(1) rem(3);
	border-radius: rem(3);
}