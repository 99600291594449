// .plugin-container{ @extend .scoring-container;}
.plugin-list{ @extend .scoring-list;}
.plugin-el{ 
	@extend .scoring-el;
	align-items: center;
}
.plugin-el__img{ 
	max-width: rem(120);
	@include mq(tablet){ max-width: rem(100); }
	@include mq(mobile){ max-width: rem(80); }
	@include mq(mobile){ max-width: rem(40); }
}
	.plugin-el__container{ 
		
		@extend .scoring-el__container;
		overflow: initial;
		// flex-grow: 0;
		&--interaction{ 
			// @include box-sizing(initial);
			// min-width: rem(150);
			
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			// button{ }
		}
	}
	.plugin-el__title{ 
		@extend .scoring-el__title;
		@include mq(little-mobile){ max-width: 100px; }
	}
	.plugin-el__description{ 
		@extend .scoring-el__description;
		overflow: hidden;
		height: rem(50);
		text-overflow: ellipsis;
		overflow: hidden; 
 
		// white-space: nowrap;
	}
	.plugin-el__button{ 
		@extend .scoring-el__button;
		margin-right: rem(15);
	}
/*
	#CHECKBOX
	#CHECKBOX
	#CHECKBOX
*/
.checkbox-plugin{
	@extend .checkbox-filters-user;
	margin: 0;
	padding: 0;
	min-height: rem(50); min-width: rem(50);
	line-height: rem(45);
	// display: flex; align-items: center; justify-content: center;
	span{ margin: 0;}
} 
/*
	#CONFIGURATION
	#CONFIGURATION
	#CONFIGURATION
*/
.plugin-config{ @extend .scoring-list;}
.plugin-config-el{ 
	@extend .scoring-el;
	align-items: center;
}
.plugin-config-el__img{ 
	@extend .plugin-el__img; 
	@include mq(mobile){ max-width: rem(80);}
	// position: absolute;
	// opacity: 0.3;
	// left: 50%;
	// // transform: translate3d(-50%,0,0);
}
.plugin-config-el__container{ @extend .plugin-el__container; }
.plugin-config-el__title{ @extend .plugin-el__title; }
.plugin-config-el__description{ @extend .plugin-el__description; }
/*
	#FORM-PLUGIN
	#FORM-PLUGIN
	#FORM-PLUGIN
*/
.form-plugin{
	width: 100%;
}
	.form-plugin__el{
		@extend .form-config__el;
		margin: 0 0;
		padding: $gutterMidBig $gutterBig;
		padding-bottom: $gutterBig;
		border-bottom: $border;
		&:nth-child(2n+1){
			background: lighten($grey, 4%);	
		}
		&.-no-config{
			svg{ margin-bottom: -2px; margin-right: 7.5px;}
			color: $greyDark;
			padding-bottom: $gutterMidBig;
			border-bottom:0;
		}
	}
	.form-plugin__label{
		@extend .form-config__label;
	}
	.form-plugin__input{
		@extend .form-config__input;
		background: $white;
	}
	.form-plugin__button{
		@extend .form-config__button;
		margin-left: 0;
		margin: $gutterBig;
	}