.footer {
    text-align: center;
    color: lighten($greyDark, 20);
    font-weight: 500;
		padding: rem(64) 0;

		i{
	    font-style: italic;
		}
}
