/*
	#TEAM
	#TEAM
	#TEAM
*/
.team-container{
	text-align: center;
}
	
.team-list{
	background: $white;
	border: $border;
	border-radius: $borderRadius;
}
.team-el{
	display: flex;
	border-bottom: $border;
	padding: $gutterBig;
	overflow: hidden;
	text-align: left;
	@include mq(little-mobile){
		padding: $gutterMidBig;
	}
	&:last-child{  border-bottom: 0;  }
}
	.team-el__container{
		flex-grow: 1;
		position: relative; z-index: 2;
	}
	.team-el__name{
		font-size: rem(16);
		line-height: rem(30);
		// margin-bottom: rem(15);
		font-weight: 700;
		text-transform: uppercase;
		color: $greyDark;
	}
	.team-el__email{
		font-size: rem(16);
		line-height: rem(20);
		color: $greyDark;	
		margin: 0 0 rem(5) 0;
	}
	.team-el__since{
		font-size: 12;
		line-height: rem(20);
		color: darken($grey, 15%);
		&:first-letter{ text-transform: lowercase;}
	}
	.team-el__button{
		@include button-default(medium, light);
		width: auto;
		margin: auto;
	}
.button-team-add{
	@include button-default(big, color);
	display: inline-block;	
	margin: $gutterBig auto;
}
/*
	#CHECKBOX
	#CHECKBOX
	#CHECKBOX
*/
// Search the class ".label-checkbox" to find the real global style of this component
.checkbox-team-user{
	padding: rem(5.5); padding-left: rem(9);
	margin: $gutterMidSmall;
	border-radius: $borderRadius;
	text-align: center;
	border: $border;
	transition: all .2s ease;
	&:hover{ background: lighten($grey, 3%); }
}