$dashGutter:$gutter *2;

.dashboard-panel {
    display: flex;
    flex-wrap: wrap;
    padding: $dashGutter/2;


    .widget {
        padding: $dashGutter/2;

        &.widget-datepicker {
            width: 30%;
        }

        &.widget-counters {
            width: 70%;
        }

        &.widget-livefeed {
            width: 25%;
            height: 400px;
        }
    }

    .dashboard-el__widget--livefeed-widget {
        display: flex;
        overflow: hidden;
        flex-direction: column;

        .livefeed-event {
            padding-top: $gutter;
            padding-bottom: $gutter;
            border-bottom: 1px solid $grey;
            display: flex;
            align-items: center;
            //justify-content: space-between;
            width: 100%;

            .moment {
                font-size: rem(12);
                width: 52px;
            }

            .name {
                margin-right: $gutter;
                margin-left: $gutter;
            }

            .data {
                font-weight: bold;
                align-self: right;
            }
        }
    }

    .dashboard-el__widget,
    .rdrDateRangePickerWrapper,
    .rdrDateRangePickerWrapper .rdrCalendarWrapper,
    .rdrDateRangePickerWrapper .rdrCalendarWrapper .rdrMonth {
        width: 100%;
        height: 100%;
    }

    .dashboard-el__widget {
        border: 0.14286rem solid #f0f0f0;
        border-radius: 0.35714rem;
        background: $white;
        padding: $dashGutter;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .datepicker-filters {
        margin: 0;
    }

    .container-list-filters {
        width: 400px;
    }

    .rdrDefinedRangesWrapper {
        display: none;
    }
}