@import url(https://fonts.googleapis.com/css?family=Roboto:100,400,500,700);
@import url(/node_modules/react-date-range/dist/styles.css);
@import url(/node_modules/react-date-range/dist/theme/default.css);
/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a, button {
  outline: none;
  cursor: pointer; }

/*
  #START MIXIN
  #START MIXIN
  #START MIXIN
*/
/*
  #VARIABLE
  #VARIABLE
  #VARIABLE
*/
/*
  #ERROR-MESSAGE
  #ERROR-MESSAGE
  #ERROR-MESSAGE
*/
.container-adjustement {
  margin-top: -3.28571rem; }

/*
    #BREAKPOINTS
    #BREAKPOINTS
    #BREAKPOINTS
*/
/*
  #MIXIN
  #MIXIN
  #MIXIN
*/
* {
  box-sizing: border-box; }

html {
  font-size: 14px; }
  @media all and (max-width: 440px) {
    html {
      font-size: 12px; } }

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  background: #ffffff;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: #f8f8f8; }

/*
  #%
  #%
  #%
*/
.form-el__label, .form-config__label, .panels-loader__label, .form-plugin__label,
.form-segments__label,
.form-triggers__label,
.form-user__label, .actions-container .action-panel .action-parameters .action-parameters-label {
  display: block;
  font-size: 1.14286rem;
  padding-bottom: 1.07143rem; }

.form-el__input, .form-config__input, .panels-loader__input, .form-plugin__input,
.form-segments__input,
.form-triggers__input,
.form-user__input, .input-filters {
  width: 100%;
  font-size: 1rem;
  line-height: 1.42857rem;
  outline: none;
  border: 0.14286rem solid #f3f3f3;
  background: transparent;
  border-radius: 0.21429rem;
  padding: 1.07143rem;
  padding-left: calc(calc(1.07143rem / 3) * 7); }
  .form-el__input:hover, .form-config__input:hover, .panels-loader__input:hover, .form-plugin__input:hover,
  .form-segments__input:hover,
  .form-triggers__input:hover,
  .form-user__input:hover, .input-filters:hover {
    border-color: #e9e9e9; }

.trackers-step__link {
  outline: none;
  cursor: pointer;
  font-size: 1.14286rem;
  line-height: 2.14286rem;
  padding: 1.07143rem calc(1.07143rem * 2);
  margin: calc((1.07143rem * 4) / 3) 0;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  background: #364357;
  border: none;
  border-radius: 0.21429rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease; }
  .trackers-step__link:hover {
    background: #2c3747; }
  .trackers-step__link svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.5rem;
    /*margin-left: rem(-4);*/ }

/*
  #MIXIN-BIG
  #MIXIN-BIG
  #MIXIN-BIG
*/
#nprogress .bar {
  background: #364357;
  box-shadow: none; }

#nprogress .spinner-icon {
  border-left-color: #364357;
  border-top-color: #364357; }

#nprogress .peg {
  box-shadow: none; }

/*
  #BREADCRUMBS
  #BREADCRUMBS
  #BREADCRUMBS
*/
.default-container svg {
  margin-top: 2.14286rem !important;
  color: #eeeeee; }

.breadcrumbs {
  position: fixed;
  z-index: 2;
  top: 0;
  width: 100%;
  background: #f8f8f8; }

.breadcrumb {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 1.07143rem 0; }
  .breadcrumb li {
    margin: 0 0.53571rem;
    color: #364357;
    font-size: 1rem; }
    .breadcrumb li:first-child {
      margin-left: 1.07143rem; }
    .breadcrumb li:after {
      content: '';
      display: inline-block;
      width: 0.42857rem;
      height: 0.42857rem;
      border-top: 0.14286rem solid silver;
      border-right: 0.14286rem solid silver;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      margin-left: 1.07143rem; }
    .breadcrumb li.active {
      font-weight: 500; }
      .breadcrumb li.active:after {
        display: none; }
  .breadcrumb li > a {
    text-decoration: none;
    color: #8f8f8f; }

/*
  #MESSAGEPOPUP
  #MESSAGEPOPUP
  #MESSAGEPOPUP
*/
.app-messages {
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: calc(241px - 1.07143rem);
  width: calc(100% - 241px); }
  .app-messages.-collapse {
    width: calc(100% - 105px);
    left: calc(105px - 1.07143rem); }
    @media all and (max-width: 660px) {
      .app-messages.-collapse {
        width: calc(100% - (1.07143rem * 2));
        left: 1.07143rem;
        bottom: 5.5rem; } }
    @media all and (max-width: 440px) {
      .app-messages.-collapse {
        width: calc(100% - (calc(1.07143rem / 2) * 2));
        left: calc(1.07143rem / 2);
        bottom: 3.85714rem; } }
  @media all and (max-width: 1170px) {
    .app-messages {
      width: calc(100% - 105px);
      left: calc(105px - 1.07143rem); } }
  @media all and (max-width: 660px) {
    .app-messages {
      width: calc(100% - (1.07143rem * 2));
      left: 1.07143rem;
      bottom: 5.5rem; } }
  @media all and (max-width: 440px) {
    .app-messages {
      width: calc(100% - (calc(1.07143rem / 2) * 2));
      left: calc(1.07143rem / 2);
      bottom: 3.85714rem; } }

@-webkit-keyframes massagePopupAnim {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0); }
  40% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0%, 0);
            transform: translate3d(0, 0%, 0); }
  100% {
    opacity: 1; } }

@keyframes massagePopupAnim {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0); }
  40% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0%, 0);
            transform: translate3d(0, 0%, 0); }
  100% {
    opacity: 1; } }

@-webkit-keyframes bubblePopupAnim {
  0% {
    -webkit-transform: translate3d(-50%, -50%, 0) scale(0);
            transform: translate3d(-50%, -50%, 0) scale(0);
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
            transform: translate3d(-50%, -50%, 0) scale(1);
    opacity: 0; } }

@keyframes bubblePopupAnim {
  0% {
    -webkit-transform: translate3d(-50%, -50%, 0) scale(0);
            transform: translate3d(-50%, -50%, 0) scale(0);
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
            transform: translate3d(-50%, -50%, 0) scale(1);
    opacity: 0; } }

@-webkit-keyframes copyPopupAnim {
  0% {
    -webkit-transform: translate3d(0, -7.5px, 0);
            transform: translate3d(0, -7.5px, 0);
    opacity: 0; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes copyPopupAnim {
  0% {
    -webkit-transform: translate3d(0, -7.5px, 0);
            transform: translate3d(0, -7.5px, 0);
    opacity: 0; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; } }

@-webkit-keyframes crossPopupAnim {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes crossPopupAnim {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.message-popup {
  position: relative;
  width: 54px;
  height: 4.28571rem;
  background: #ffffff;
  border-radius: 0.21429rem;
  margin: 1.07143rem 0;
  display: none;
  align-items: center;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }
  .message-popup.-active {
    display: flex;
    -webkit-animation: massagePopupAnim 1s ease-out forwards;
            animation: massagePopupAnim 1s ease-out forwards;
    width: 100%; }
    @media all and (max-width: 440px) {
      .message-popup.-active {
        height: 7rem; } }
    .message-popup.-active:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 45.71429rem;
      height: 45.71429rem;
      border-radius: 50%;
      background: rgba(34, 119, 0, 0.2);
      -webkit-transform: translate3d(-50%, -50%, 0) scale(0);
              transform: translate3d(-50%, -50%, 0) scale(0);
      -webkit-animation: bubblePopupAnim 2s ease forwards;
              animation: bubblePopupAnim 2s ease forwards; }
  .message-popup--infos {
    color: #059;
    background-color: rgba(187, 238, 255, 0.8); }
    .message-popup--infos:before {
      background: rgba(0, 85, 153, 0.2); }
    .message-popup--infos .message-popup__cross:before,
    .message-popup--infos .message-popup__cross:after {
      background: #059; }
  .message-popup--success {
    color: #270;
    background-color: rgba(223, 242, 191, 0.8); }
    .message-popup--success:before {
      background: rgba(34, 119, 0, 0.2); }
    .message-popup--success .message-popup__cross:before,
    .message-popup--success .message-popup__cross:after {
      background: #270; }
  .message-popup--warning {
    color: #9F6000;
    background-color: rgba(254, 239, 179, 0.8); }
    .message-popup--warning:before {
      background: rgba(159, 96, 0, 0.2); }
    .message-popup--warning .message-popup__cross:before,
    .message-popup--warning .message-popup__cross:after {
      background: #9F6000; }
  .message-popup--error {
    color: #D8000C;
    background-color: rgba(255, 186, 186, 0.8); }
    .message-popup--error:before {
      background: rgba(216, 0, 12, 0.2); }
    .message-popup--error .message-popup__cross:before,
    .message-popup--error .message-popup__cross:after {
      background: #D8000C; }

.message-popup__icon {
  display: inline-block;
  margin: 0 1.07143rem 0 calc((1.07143rem * 4) / 3);
  margin-bottom: -0.14286rem; }

.message-popup__copy {
  display: inline-block;
  text-align: left;
  padding: 1.07143rem 3.57143rem;
  font-size: 1rem;
  opacity: 0;
  position: absolute;
  -webkit-animation: copyPopupAnim .5s ease-out .5s forwards;
          animation: copyPopupAnim .5s ease-out .5s forwards; }

.message-popup__cross {
  position: absolute;
  right: 1.07143rem;
  top: 50%;
  width: 1.78571rem;
  height: 1.78571rem;
  border: none;
  background: transparent;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
  opacity: 0;
  -webkit-animation: crossPopupAnim .5s ease-out .5s forwards;
          animation: crossPopupAnim .5s ease-out .5s forwards; }
  .message-popup__cross:before, .message-popup__cross:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 0.07143rem;
    background: #000000; }
  .message-popup__cross:before {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
            transform: translate3d(-50%, -50%, 0) rotate(45deg); }
  .message-popup__cross:after {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
            transform: translate3d(-50%, -50%, 0) rotate(-45deg); }

.actions-container {
  display: flex; }

.actions-container .actions-sidebar {
  width: 26.42857rem;
  height: 100%;
  z-index: 3; }

.actions-container .actions-edit {
  width: 100%;
  max-width: 64.28571rem;
  display: flex;
  z-index: 2;
  margin-left: 1.71429rem; }

.actions-container .actions-item {
  color: black;
  padding: 1.28571rem 1.28571rem;
  text-decoration: none;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  margin-bottom: 0.07143rem; }
  .actions-container .actions-item span.description {
    color: #8f8f8f;
    margin-top: 0.85714rem; }
  .actions-container .-active.actions-item {
    color: #ffffff;
    background-color: #2c3747;
    position: relative; }
    .actions-container .-active.actions-item:after {
      content: '';
      width: 0.85714rem;
      height: 0.85714rem;
      background-color: #2c3747;
      position: absolute;
      right: -0.28571rem;
      top: 0.64286rem;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .actions-container .-active.actions-item span.description {
      color: #ffffff; }
  .actions-container .-item-new.actions-item {
    font-style: italic; }

.color-indicator {
  width: 1.07143rem;
  height: 1.07143rem;
  border-radius: 0.53571rem;
  display: inline-block;
  margin-right: 0.53571rem;
  vertical-align: middle; }

@keyframes circleLoader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes circleLoader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.circleLoader {
  position: relative;
  margin: 2.57143rem auto; }

.circleLoader div {
  position: absolute;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  border: 12px solid #000;
  border-color: #4a69bd transparent #4a69bd transparent;
  -webkit-animation: circleLoader 1s linear infinite;
  animation: circleLoader 1s linear infinite; }

.circleLoader {
  width: 84px !important;
  height: 84px !important;
  -webkit-transform: translate(-42px, -42px) scale(0.42) translate(42px, 42px);
  transform: translate(-42px, -42px) scale(0.42) translate(42px, 42px); }

.form-config .input-suggestions, .panels-loader .input-suggestions {
  width: 100%;
  overflow: hidden;
  padding-bottom: 0.53571rem;
  padding-top: 0.53571rem;
  position: relative;
  display: flex; }
  .form-config .input-suggestions:after, .panels-loader .input-suggestions:after {
    content: '';
    display: block;
    position: absolute;
    width: 80px;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    background: -webkit-gradient(linear, right top, left top, from(white), to(rgba(255, 255, 255, 0))) scroll;
    background: -webkit-linear-gradient(right, white, rgba(255, 255, 255, 0)) scroll;
    background: linear-gradient(-90deg, white, rgba(255, 255, 255, 0)) scroll; }
  .form-config .input-suggestions a, .panels-loader .input-suggestions a,
  .form-config .input-suggestions button,
  .panels-loader .input-suggestions button {
    color: #a1a1a1;
    margin-right: 0.53571rem;
    border: 1px solid #f3f3f3;
    border-radius: 4px;
    padding: 0.26786rem 0.53571rem; }
    .form-config .input-suggestions a:hover, .panels-loader .input-suggestions a:hover,
    .form-config .input-suggestions button:hover,
    .panels-loader .input-suggestions button:hover {
      color: #8f8f8f; }

@-webkit-keyframes blink {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  5% {
    -webkit-transform: rotate(22.5deg);
            transform: rotate(22.5deg); }
  15% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg); }
  25% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg); }
  35% {
    -webkit-transform: rotate(-7.5deg);
            transform: rotate(-7.5deg); }
  45% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  55% {
    -webkit-transform: rotate(-2.5deg);
            transform: rotate(-2.5deg); }
  60% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes blink {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  5% {
    -webkit-transform: rotate(22.5deg);
            transform: rotate(22.5deg); }
  15% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg); }
  25% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg); }
  35% {
    -webkit-transform: rotate(-7.5deg);
            transform: rotate(-7.5deg); }
  45% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  55% {
    -webkit-transform: rotate(-2.5deg);
            transform: rotate(-2.5deg); }
  60% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

/*
  #GRID
  #GRID
  #GRID
*/
.app-inner {
  display: flex; }
  .app-inner.-collapse .nav-aside-right {
    width: 6rem; }
  .app-inner.-collapse .nav-button-collapse:after, .app-inner.-collapse .nav-button-collapse:before {
    -webkit-transform-origin: right center;
            transform-origin: right center; }
  .app-inner main.main-container {
    flex-grow: 1;
    padding-top: 3.28571rem; }
    @media all and (max-width: 1170px) {
      .app-inner main.main-container {
        max-width: calc(100vw - 12rem); } }
    @media all and (max-width: 660px) {
      .app-inner main.main-container {
        max-width: 100vw;
        padding-top: 7.14286rem; } }

/*
	#SIDEBAR
	#SIDEBAR
	#SIDEBAR
*/
.aside {
  position: relative;
  z-index: 10; }

.nav-aside-container {
  padding: 0;
  display: flex;
  flex-direction: row; }

.nav-aside-left {
  height: 100vh;
  max-width: 6rem;
  background: #1d232e;
  background: -webkit-gradient(linear, left top, left bottom, from(#3a3c3e), to(#1d232e));
  background: -webkit-linear-gradient(#3a3c3e, #1d232e);
  background: linear-gradient(#3a3c3e, #1d232e);
  padding: calc(1.07143rem / 2) 1.07143rem;
  display: flex;
  flex-direction: column; }
  @media all and (max-width: 660px) {
    .nav-aside-left {
      flex-direction: row;
      position: fixed;
      z-index: 999;
      left: 0;
      top: 0;
      max-width: 100%;
      width: 100%;
      height: auto; } }

.nav-aside-left__el {
  position: relative; }
  .nav-aside-left__el--flex-grow {
    flex-grow: 1; }

.nav-aside-right {
  position: relative;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 1.07143rem 0;
  background: #eeeeee;
  width: 15.71429rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  @media all and (max-width: 1170px) {
    .nav-aside-right {
      width: 6rem; }
      .nav-aside-right .link-aside-right__copy {
        display: none; } }
  @media all and (max-width: 660px) {
    .nav-aside-right {
      position: fixed;
      z-index: 999;
      left: 0;
      bottom: 0;
      width: 100% !important;
      height: auto;
      background: #ffffff;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: calc(1.07143rem / 2) 0; } }
  .nav-aside-right.-hide {
    display: none; }

.nav-aside-right__el-collapse {
  padding: 0;
  margin: 0;
  cursor: pointer;
  position: absolute;
  z-index: 9;
  right: calc(-1.07143rem + 0.14286rem);
  top: 0;
  width: 1.07143rem;
  height: 100vh;
  -webkit-transform: translate(-100%, 0, 0);
          transform: translate(-100%, 0, 0);
  border-left: 0.14286rem solid #f3f3f3;
  border-color: #364357;
  display: flex;
  align-items: center;
  opacity: 0;
  -webkit-transition: all 1s ease;
  transition: all 1s ease; }
  .nav-aside-right__el-collapse:hover {
    opacity: 1; }
    .nav-aside-right__el-collapse:hover .nav-button-collapse:before {
      -webkit-transform: translate3d(0, -50%, 0) rotate(45deg);
              transform: translate3d(0, -50%, 0) rotate(45deg); }
    .nav-aside-right__el-collapse:hover .nav-button-collapse:after {
      -webkit-transform: translate3d(0, -50%, 0) rotate(-45deg);
              transform: translate3d(0, -50%, 0) rotate(-45deg); }
  @media all and (max-width: 1170px) {
    .nav-aside-right__el-collapse {
      display: none; } }

.nav-button-collapse {
  position: relative;
  display: block;
  border: none;
  padding: 0;
  min-width: 1rem;
  min-height: 2.14286rem;
  color: #000000;
  text-align: right;
  background: none; }
  .nav-button-collapse:after, .nav-button-collapse:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: calc(1.07143rem / 2);
    height: 0.14286rem;
    background: #364357;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; }
  .nav-button-collapse:before {
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-transform: translate3d(0, -50%, 0) rotate(90deg);
            transform: translate3d(0, -50%, 0) rotate(90deg); }
  .nav-button-collapse:after {
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-transform: translate3d(0, -50%, 0) rotate(-90deg);
            transform: translate3d(0, -50%, 0) rotate(-90deg); }

/*
	#NAV-LINK-ASIDE
	#NAV-LINK-ASIDE
	#NAV-LINK-ASIDE
*/
.link-aside-right {
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 1rem;
  line-height: 1.42857rem;
  padding: 1.07143rem;
  margin: 0 1.07143rem 0.28571rem;
  font-weight: 400;
  text-decoration: none;
  color: #000000;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 0;
  background: transparent;
  border-radius: 0.21429rem;
  font-weight: 500;
  color: #434343; }
  @media all and (max-width: 660px) {
    .link-aside-right {
      margin: 0; } }
  .link-aside-right .icon,
  .link-aside-right svg {
    font-size: 1.71429rem;
    color: #8f8f8f;
    margin-bottom: -0.57143rem;
    margin-right: calc((1.07143rem * 4) / 3); }
  .link-aside-right:hover, .link-aside-right:focus {
    background: #e4e4e4;
    color: inherit;
    text-decoration: none; }
  .link-aside-right.active {
    background: #e4e4e4;
    color: #283241; }
    .link-aside-right.active .icon,
    .link-aside-right.active svg {
      color: #364357; }
  @media all and (max-width: 660px) {
    .link-aside-right {
      overflow: inherit; }
      .link-aside-right:hover {
        background: none; }
      .link-aside-right .icon,
      .link-aside-right svg {
        margin-right: 0; }
      .link-aside-right .link-aside-right__copy {
        display: none; }
      .link-aside-right.active {
        margin-left: 0rem;
        background: none; } }
  @media all and (max-width: 440px) {
    .link-aside-right {
      padding: 1.28571rem; } }
  .link-aside-right.-back {
    color: #8f8f8f;
    font-weight: bold;
    /*
		line-height:rem(16);
		padding-top: rem(8);
		padding-bottom: rem(8);
		.icon, svg{
			line-height:inherit;
			font-size:rem(16);
			color:$greyDark;
			margin-left:rem(4);
			margin-right:rem(24);
			margin-top:rem(-4);
		}*/ }
    .link-aside-right.-back .icon,
    .link-aside-right.-back svg {
      -webkit-transform: scale(0.6);
              transform: scale(0.6); }
    .link-aside-right.-back:hover {
      background: inherit; }

/*
	#LINK-ASIDE-LEFT
	#LINK-ASIDE-LEFT
	#LINK-ASIDE-LEFT
*/
.link-aside-left {
  cursor: pointer;
  display: block;
  font-size: 1rem;
  line-height: 1.42857rem;
  padding: 1.07143rem;
  font-weight: 700;
  background: #0f1318;
  text-decoration: none;
  color: #000000;
  margin: calc(1.07143rem / 2) 0;
  border: 0;
  border-radius: 50%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }
  @media all and (max-width: 660px) {
    .link-aside-left {
      margin: calc(1.07143rem / 2); } }
  .link-aside-left:hover {
    background: #050608; }
  .link-aside-left .icon,
  .link-aside-left svg {
    font-size: 1.71429rem;
    color: #a7a7a7;
    margin-bottom: -0.42857rem;
    color: #ffffff;
    margin-right: 0rem;
    margin-left: 0rem; }
  .link-aside-left--logo {
    position: relative;
    background: #ffffff;
    min-width: 3.42857rem; }
    .link-aside-left--logo:before {
      content: '';
      display: block;
      padding-bottom: 90%; }
    .link-aside-left--logo:hover {
      background: none; }
    .link-aside-left--logo img {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate3d(-50%, -50%, 0);
              transform: translate3d(-50%, -50%, 0);
      width: 70%; }
  .link-aside-left--notif {
    position: relative; }
    .link-aside-left--notif:before {
      display: none; }
  .link-aside-left--notif.-blink svg {
    -webkit-transform-origin: 70% 0;
            transform-origin: 70% 0;
    -webkit-animation-name: blink;
            animation-name: blink;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite; }
  .link-aside-left.-active {
    color: #0f1318;
    background: #ffffff; }
    .link-aside-left.-active svg {
      color: #0f1318; }

.link-aside-left__number-notif {
  position: absolute;
  z-index: 1;
  font-size: 10px;
  font-weight: 700;
  right: 0.42857rem;
  top: 0.78571rem;
  width: 1.42857rem;
  height: 1.42857rem;
  border-radius: 50%;
  line-height: 1.14286rem;
  color: #ffffff;
  background: #e55039;
  text-align: center;
  border: 0.14286rem solid #0f1318; }

.container-profile {
  position: absolute;
  z-index: 99;
  right: calc(1.07143rem / -2);
  bottom: calc(1.07143rem / 2);
  padding: calc(1.07143rem / 2) 0;
  background: #ffffff;
  -webkit-transform: translate3d(100%, 0%, 0);
          transform: translate3d(100%, 0%, 0);
  border-radius: calc(1.07143rem / 3);
  border-color: #e6e6e6;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.03);
  display: none;
  overflow: hidden; }
  .container-profile.-display {
    display: block; }
  @media all and (max-width: 660px) {
    .container-profile {
      right: 12.78571rem;
      bottom: -10.5rem; } }

.container-profile__el {
  font-size: 1rem;
  font-weight: 500;
  display: block;
  text-decoration: none;
  color: #000000;
  padding: 1.07143rem calc((1.07143rem * 4) / 3);
  white-space: nowrap; }
  .container-profile__el:hover {
    background: #f3f3f3; }
  .container-profile__el--title {
    color: #8f8f8f;
    text-transform: uppercase; }
    .container-profile__el--title:hover {
      background: none; }

/*
	#NOTIF
	#NOTIF
	#NOTIF
*/
@-webkit-keyframes animNotif {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }
@keyframes animNotif {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.container-notif {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 47.14286rem;
  height: 100vh;
  background: #ffffff;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
  display: flex;
  flex-direction: row;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .container-notif.-display {
    -webkit-transform: translate3d(5.85714rem, 0, 0);
            transform: translate3d(5.85714rem, 0, 0); }
    @media all and (max-width: 660px) {
      .container-notif.-display {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0); } }
  @media all and (max-width: 660px) {
    .container-notif {
      width: 100vw;
      left: auto;
      left: initial;
      right: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0); } }

.container-notif__container {
  padding: calc(1.07143rem / 2) 1.07143rem;
  background: #ffffff; }
  .container-notif__container--aside {
    min-width: 6rem;
    background: #f3f3f3; }

.container-notif__btn-tt {
  display: block;
  padding: 1.07143rem;
  background: #ffffff;
  width: 100%;
  border-radius: 0.21429rem;
  text-align: center;
  font-weight: 700;
  text-decoration: none;
  color: #000000;
  margin: calc(1.07143rem / 2) 0; }
  .container-notif__btn-tt:before {
    content: '';
    display: inline-block;
    padding-bottom: 80%; }

.container-notif__btn-back {
  font-size: 1rem;
  padding: 1.07143rem;
  background: transparent;
  border: none;
  border-radius: 0.21429rem;
  width: 100%;
  margin: calc(1.07143rem / 2) 0; }
  .container-notif__btn-back:hover {
    background: #e9e9e9; }

.container-notif__title {
  font-size: 1.42857rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #8f8f8f;
  padding-top: 5.71429rem;
  padding-bottom: 1.07143rem; }
  @media all and (max-width: 660px) {
    .container-notif__title {
      padding-top: 1.07143rem; } }

.overlay-notification {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }
  .overlay-notification.-display {
    opacity: 0.7;
    -webkit-transform: translate3d(5.85714rem, 0, 0);
            transform: translate3d(5.85714rem, 0, 0); }
    @media all and (max-width: 660px) {
      .overlay-notification.-display {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0); } }

.notif-list {
  height: 100%;
  background: #ffffff;
  overflow: scroll; }

.notif-el {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 1.07143rem;
  margin: 1.07143rem 0;
  line-height: 1.42857rem;
  border-radius: 0.21429rem; }
  .notif-el--read {
    background: #f3f3f3; }
    .notif-el--read * {
      opacity: 0.7; }
  @media all and (max-width: 660px) {
    .notif-el {
      padding: 1.07143rem; } }

.notif-el__container {
  display: flex;
  flex-direction: column;
  width: 68%; }
  .notif-el__container:nth-child(2) {
    justify-content: center;
    width: 28%; }

.notif-el__title {
  font-size: 1.14286rem; }

.notif-el__description {
  font-size: 1.14286rem;
  color: #8f8f8f; }

.notif-el__moment {
  font-size: 0.85714rem;
  color: #8f8f8f; }

.notif-el__button {
  cursor: pointer;
  outline: none;
  display: inline-block;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  background: #364357;
  border: none;
  border-radius: 0.21429rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-size: 1rem;
  line-height: 1.42857rem;
  padding: calc(1.07143rem / 2);
  color: #a7a7a7;
  background: #ffffff;
  border: 0.14286rem solid #f3f3f3;
  line-height: 1.14286rem; }
  .notif-el__button:hover {
    background: #2a3444; }
  .notif-el__button .icon,
  .notif-el__button svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.5rem;
    /*margin-left: rem(-4);*/ }
  .notif-el__button:hover {
    background: #fbfbfb; }
  .notif-el__button.-active {
    background: #f8f8f8; }
  .notif-el__button:nth-child(2) {
    margin-top: calc(calc((1.07143rem * 4) / 3) / 2); }
  .notif-el__button--reindex {
    color: #339919;
    border-color: rgba(51, 153, 25, 0.3); }
    .notif-el__button--reindex:hover {
      background: rgba(51, 153, 25, 0.1); }
  .notif-el__button--ok {
    color: #364357;
    border-color: rgba(54, 67, 87, 0.3); }
    .notif-el__button--ok:hover {
      background: rgba(54, 67, 87, 0.1); }

/*
	#SUBNAV
	#SUBNAV
	#SUBNAV
*/
.subnav-container {
  background: #ffffff;
  border: 0.14286rem solid #f3f3f3;
  text-align: center;
  margin: 0 1.07143rem;
  border-radius: 0.21429rem; }

.subnav-list {
  width: auto;
  flex-wrap: nowrap;
  position: relative;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 0 1.07143rem;
  /* Shadow covers */
  /* https://codepen.io/jordanmoore/pen/pnlAi */
  background: -webkit-gradient(linear, left top, right top, color-stop(30%, white), to(rgba(255, 255, 255, 0))), -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(70%, white)) 100% 0, radial-gradient(0 50%, farthest-side, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)), radial-gradient(100% 50%, farthest-side, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)) 100% 0;
  background: -webkit-linear-gradient(left, white 30%, rgba(255, 255, 255, 0)), -webkit-linear-gradient(left, rgba(255, 255, 255, 0), white 70%) 100% 0, -webkit-radial-gradient(0 50%, farthest-side, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)), -webkit-radial-gradient(100% 50%, farthest-side, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)) 100% 0;
  background: linear-gradient(90deg, white 30%, rgba(255, 255, 255, 0)), linear-gradient(90deg, rgba(255, 255, 255, 0), white 70%) 100% 0, radial-gradient(0 50%, farthest-side, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)), radial-gradient(100% 50%, farthest-side, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)) 100% 0;
  /* Shadow covers */
  background: -webkit-gradient(linear, left top, right top, color-stop(30%, white), to(rgba(255, 255, 255, 0))), -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(70%, white)) 100% 0, radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)) 100% 0;
  background: linear-gradient(90deg, white 30%, rgba(255, 255, 255, 0)), linear-gradient(90deg, rgba(255, 255, 255, 0), white 70%) 100% 0, radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)) 100% 0;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll; }

.subnav-list__el {
  display: inline-block;
  white-space: nowrap; }

.subnav-list__el-target {
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: -0.07143rem;
  width: 25%;
  height: 0.28571rem;
  background: #364357;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.subnav-list__link {
  display: block;
  text-decoration: none;
  color: #8f8f8f;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.14286rem;
  padding: calc(1.07143rem * 2); }
  .subnav-list__link.active {
    color: #364357; }

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-animation: autofill 0s forwards;
  animation: autofill 0s forwards; }

@-webkit-keyframes autofill {
  100% {
    background: transparent;
    color: inherit; } }

@keyframes autofill {
  100% {
    background: transparent;
    color: inherit; } }

.form-default__radio {
  margin: 16px 0;
  display: block;
  cursor: pointer; }
  .form-default__radio input {
    display: none; }
    .form-default__radio input + span {
      line-height: 18px;
      height: 18px;
      padding-left: 18px;
      display: block;
      position: relative; }
      .form-default__radio input + span:not(:empty) {
        padding-left: 26px; }
      .form-default__radio input + span:before, .form-default__radio input + span:after {
        content: '';
        width: 18px;
        height: 18px;
        display: block;
        border-radius: 50%;
        left: 0;
        top: 0;
        position: absolute; }
      .form-default__radio input + span:before {
        background: #d4d4d4;
        -webkit-transition: background 0.2s ease, -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
        transition: background 0.2s ease, -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
        transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
        transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2), -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2); }
      .form-default__radio input + span:after {
        background: #fff;
        -webkit-transform: scale(0.78);
                transform: scale(0.78);
        -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
        transition: -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
        transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
        transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4), -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4); }
    .form-default__radio input:disabled + span {
      opacity: .67;
      cursor: default; }
    .form-default__radio input:checked + span:before {
      -webkit-transform: scale(1.04);
              transform: scale(1.04);
      background: #364357; }
    .form-default__radio input:checked + span:after {
      -webkit-transform: scale(0.4);
              transform: scale(0.4);
      -webkit-transition: -webkit-transform .3s ease;
      transition: -webkit-transform .3s ease;
      transition: transform .3s ease;
      transition: transform .3s ease, -webkit-transform .3s ease; }
  .form-default__radio:hover input + span:after {
    -webkit-transform: scale(0.74);
            transform: scale(0.74); }
  .form-default__radio:hover input:checked + span:after {
    -webkit-transform: scale(0.4);
            transform: scale(0.4); }

/*
    #FROM-LOGIN
    #FROM-LOGIN
    #FROM-LOGIN
*/
.app-login {
  width: 100vw;
  height: 100vh;
  background: #e6e6e6; }
  .app-login .background {
    height: 100vh;
    width: 100%;
    overflow: hidden; }
    .app-login .background div {
      height: 100vh;
      width: 100%;
      -webkit-filter: blur(0.71429rem);
              filter: blur(0.71429rem);
      opacity: .5;
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }

.form-login {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  font-size: 1rem;
  line-height: 2.14286rem;
  width: 500px;
  margin: 0 auto;
  padding: calc((1.07143rem * 4) / 3) calc(1.07143rem * 2);
  border-radius: 0.21429rem;
  background: #fcfcfc;
  text-align: center;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.03); }
  @media all and (max-width: 660px) {
    .form-login {
      padding: calc((1.07143rem * 4) / 3);
      width: 90%; } }
  .form-login .login-separator {
    color: #8f8f8f;
    display: flex;
    height: 4rem;
    position: relative;
    align-items: center;
    flex-direction: column; }
    .form-login .login-separator:before {
      border-bottom: 1px solid #f3f3f3;
      max-width: 100%;
      width: 21.42857rem;
      content: '';
      display: block;
      position: absolute;
      top: 2rem;
      z-index: 1; }
    .form-login .login-separator span {
      background-color: #fcfcfc;
      display: block;
      z-index: 2;
      padding: 0 1.71429rem;
      line-height: 4rem;
      height: 4rem; }

.form-login__title {
  font-size: 2.57143rem;
  font-weight: 300;
  color: #8f8f8f;
  line-height: 2.14286rem;
  margin: calc(1.07143rem * 2) 0;
  text-align: left; }

.form-el {
  margin: calc((1.07143rem * 4) / 3) calc(calc((1.07143rem * 4) / 3) / 2);
  position: relative;
  text-align: left; }
  .form-el svg {
    color: silver;
    position: absolute;
    z-index: 0;
    left: 1.07143rem;
    top: 50%;
    margin-bottom: -0.14286rem;
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0); }
  .form-el--button {
    margin: 0 0 calc(1.07143rem * 2); }
  .form-el--infos {
    margin: calc(1.07143rem * 2) calc(calc((1.07143rem * 4) / 3) / 2) calc(1.07143rem * 2) calc(calc((1.07143rem * 4) / 3) / 2); }

.form-el__infos {
  color: #8f8f8f; }

.button-login {
  cursor: pointer;
  outline: none;
  display: inline-block;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  background: #364357;
  border: none;
  border-radius: 0.21429rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-size: 1.14286rem;
  line-height: 2.14286rem;
  padding: 1.07143rem calc(1.07143rem * 2);
  color: #ffffff;
  background: #364357;
  display: inline;
  margin: calc(calc((1.07143rem * 4) / 3) / 2); }
  .button-login:hover {
    background: #2a3444; }
  .button-login .icon,
  .button-login svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.5rem;
    /*margin-left: rem(-4);*/ }

.button-login-type {
  cursor: pointer;
  outline: none;
  display: inline-block;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  background: #364357;
  border: none;
  border-radius: 0.21429rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-size: 1rem;
  line-height: 1.42857rem;
  padding: 1.07143rem;
  color: #ffffff;
  background: #364357;
  display: block;
  width: 100%;
  margin: 1.07143rem 0;
  border: none;
  box-shadow: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3); }
  .button-login-type:hover {
    background: #2a3444; }
  .button-login-type .icon,
  .button-login-type svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.5rem;
    /*margin-left: rem(-4);*/ }
  .button-login-type.button-login-type__social {
    background-size: 1.85714rem;
    background-position: 0.85714rem;
    background-repeat: no-repeat; }
  .button-login-type.button-login-type__google {
    background-color: white;
    color: #747474;
    background-image: url(/static/media/google.686f8efa.svg); }

.button-cancel {
  cursor: pointer;
  outline: none;
  display: inline-block;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  background: #364357;
  border: none;
  border-radius: 0.21429rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-size: 1.14286rem;
  line-height: 2.14286rem;
  padding: 1.07143rem calc(1.07143rem * 2);
  color: #a7a7a7;
  background: #ffffff;
  border: 0.14286rem solid #f3f3f3;
  line-height: 1.85714rem;
  margin: calc(calc((1.07143rem * 4) / 3) / 2); }
  .button-cancel:hover {
    background: #2a3444; }
  .button-cancel .icon,
  .button-cancel svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.5rem;
    /*margin-left: rem(-4);*/ }
  .button-cancel:hover {
    background: #fbfbfb; }
  .button-cancel.-active {
    background: #f8f8f8; }

.button-forgot {
  cursor: pointer;
  outline: none;
  display: inline-block;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  background: #364357;
  border: none;
  border-radius: 0.21429rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-size: 1rem;
  line-height: 1.42857rem;
  padding: 1.07143rem;
  color: #a7a7a7;
  background: #ffffff;
  border: 0.14286rem solid #f3f3f3;
  line-height: 1.14286rem;
  line-height: 2.14286rem;
  text-transform: inherit;
  display: inline;
  margin: calc(calc((1.07143rem * 4) / 3) / 2);
  padding: 1.07143rem;
  border-color: transparent; }
  .button-forgot:hover {
    background: #2a3444; }
  .button-forgot .icon,
  .button-forgot svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.5rem;
    /*margin-left: rem(-4);*/ }
  .button-forgot:hover {
    background: #fbfbfb;
    color: #a7a7a7; }
  .button-forgot.-active {
    background: #f8f8f8; }
  .button-forgot:first-letter {
    text-transform: uppercase; }
  @media all and (max-width: 660px) {
    .button-forgot {
      margin-left: calc(calc(1.07143rem / 3) * 2);
      margin-right: 0; } }

.error-message-login {
  display: block;
  color: #8f8f8f;
  color: #364357;
  margin: calc(calc((1.07143rem * 4) / 3) / 2);
  text-align: left;
  -webkit-animation: errorMessageLogin 0.6s ease-out forwards;
          animation: errorMessageLogin 0.6s ease-out forwards;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

@-webkit-keyframes errorMessageLogin {
  100% {
    opacity: 1;
    -webkit-transform: translate3d(1.07143rem, 0, 0);
            transform: translate3d(1.07143rem, 0, 0); } }

@keyframes errorMessageLogin {
  100% {
    opacity: 1;
    -webkit-transform: translate3d(1.07143rem, 0, 0);
            transform: translate3d(1.07143rem, 0, 0); } }

/*
    #FORM-CONFIG & USER
    #FORM-CONFIG & USER
    #FORM-CONFIG & USER
*/
.form-config, .panels-loader,
.form-segments,
.form-triggers,
.form-user {
  max-width: 57.14286rem;
  background: #ffffff;
  border-radius: 0.21429rem;
  margin: 0 auto calc(1.07143rem * 2);
  border: 0.14286rem solid #f3f3f3;
  text-align: left; }

.form-config__title, .panels-loader__title, .rules-list-container__title, .form-identification__title,
.form-segments__title,
.form-triggers__title,
.form-user__title {
  font-size: 1.42857rem;
  line-height: 2.14286rem;
  padding: calc(1.07143rem * 2);
  color: #8f8f8f;
  border-bottom: 0.14286rem solid #f3f3f3;
  margin-bottom: 1.07143rem; }
  @media all and (max-width: 440px) {
    .form-config__title, .panels-loader__title, .rules-list-container__title, .form-identification__title,
    .form-segments__title,
    .form-triggers__title,
    .form-user__title {
      padding: 1.07143rem; } }

.form-config__container-flex, .panels-loader__container, .form-identification__container,
.form-segments__container-flex,
.form-triggers__container-flex,
.form-user__container-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 calc(1.07143rem * 2); }
  @media all and (max-width: 440px) {
    .form-config__container-flex, .panels-loader__container, .form-identification__container,
    .form-segments__container-flex,
    .form-triggers__container-flex,
    .form-user__container-flex {
      padding: 0 calc((1.07143rem * 4) / 3); } }

.form-segments__container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 calc(1.07143rem * 2); }
  @media all and (max-width: 440px) {
    .form-segments__container {
      padding: 0 calc((1.07143rem * 4) / 3); } }

.form-user__container-span-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%; }

.form-config__el, .panels-loader__el, .form-plugin__el,
.form-segments__el,
.form-triggers__el,
.form-user__el {
  margin: 1.07143rem 0;
  position: relative;
  width: 100%; }
  @media all and (max-width: 440px) {
    .form-config__el, .panels-loader__el, .form-plugin__el,
    .form-segments__el,
    .form-triggers__el,
    .form-user__el {
      margin: 1.07143rem 0; } }
  .form-config__el--50,
  .form-segments__el--50,
  .form-triggers__el--50,
  .form-user__el--50 {
    width: calc(50% - 1.07143rem); }
    @media all and (max-width: 660px) {
      .form-config__el--50,
      .form-segments__el--50,
      .form-triggers__el--50,
      .form-user__el--50 {
        width: 100%; } }
  .form-config__el .password-meter, .panels-loader__el .password-meter, .form-plugin__el .password-meter,
  .form-segments__el .password-meter,
  .form-triggers__el .password-meter,
  .form-user__el .password-meter {
    width: 100%;
    background: #eeeeee;
    display: block;
    border-radius: 20px;
    margin: 5px 0 0;
    height: 10px;
    overflow: hidden; }
    .form-config__el .password-meter .score, .panels-loader__el .password-meter .score, .form-plugin__el .password-meter .score,
    .form-segments__el .password-meter .score,
    .form-triggers__el .password-meter .score,
    .form-user__el .password-meter .score {
      height: 100%;
      display: block;
      -webkit-transition: all 0.5s;
      transition: all 0.5s; }
      .form-config__el .password-meter .score--0, .panels-loader__el .password-meter .score--0, .form-plugin__el .password-meter .score--0,
      .form-segments__el .password-meter .score--0,
      .form-triggers__el .password-meter .score--0,
      .form-user__el .password-meter .score--0 {
        width: 0;
        background: none; }
      .form-config__el .password-meter .score--10, .panels-loader__el .password-meter .score--10, .form-plugin__el .password-meter .score--10,
      .form-segments__el .password-meter .score--10,
      .form-triggers__el .password-meter .score--10,
      .form-user__el .password-meter .score--10 {
        width: 20%;
        background: #ff5722; }
      .form-config__el .password-meter .score--20, .panels-loader__el .password-meter .score--20, .form-plugin__el .password-meter .score--20,
      .form-segments__el .password-meter .score--20,
      .form-triggers__el .password-meter .score--20,
      .form-user__el .password-meter .score--20 {
        width: 40%;
        background: #ff9800; }
      .form-config__el .password-meter .score--30, .panels-loader__el .password-meter .score--30, .form-plugin__el .password-meter .score--30,
      .form-segments__el .password-meter .score--30,
      .form-triggers__el .password-meter .score--30,
      .form-user__el .password-meter .score--30 {
        width: 60%;
        background: #ffc107; }
      .form-config__el .password-meter .score--40, .panels-loader__el .password-meter .score--40, .form-plugin__el .password-meter .score--40,
      .form-segments__el .password-meter .score--40,
      .form-triggers__el .password-meter .score--40,
      .form-user__el .password-meter .score--40 {
        width: 80%;
        background: #ffeb3b; }
      .form-config__el .password-meter .score--50, .panels-loader__el .password-meter .score--50, .form-plugin__el .password-meter .score--50,
      .form-segments__el .password-meter .score--50,
      .form-triggers__el .password-meter .score--50,
      .form-user__el .password-meter .score--50 {
        width: 100%;
        background: #8bc34a; }

.form-config__label, .panels-loader__label, .form-plugin__label,
.form-segments__label,
.form-triggers__label,
.form-user__label {
  margin-top: calc(1.07143rem / 3); }

.form-config__input, .panels-loader__input, .form-plugin__input,
.form-segments__input,
.form-triggers__input,
.form-user__input {
  padding: 1.07143rem; }

.form-config__container-button,
.form-segments__container-button,
.form-triggers__container-button,
.form-user__container-button {
  margin: 0 calc((1.07143rem * 4) / 3) calc(1.07143rem * 2); }
  @media all and (max-width: 440px) {
    .form-config__container-button,
    .form-segments__container-button,
    .form-triggers__container-button,
    .form-user__container-button {
      margin: 0 1.07143rem 1.07143rem calc(calc((1.07143rem * 4) / 3) / 2); } }

.form-config__button, .form-plugin__button, .form-identification__button,
.form-segments__button,
.form-triggers__button,
.form-user__button {
  cursor: pointer;
  outline: none;
  display: inline-block;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  background: #364357;
  border: none;
  border-radius: 0.21429rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-size: 1.14286rem;
  line-height: 2.14286rem;
  padding: 1.07143rem calc(1.07143rem * 2);
  color: #ffffff;
  background: #364357;
  margin: calc(1.07143rem / 2) calc(calc((1.07143rem * 4) / 3) / 2); }
  .form-config__button:hover, .form-plugin__button:hover, .form-identification__button:hover,
  .form-segments__button:hover,
  .form-triggers__button:hover,
  .form-user__button:hover {
    background: #2a3444; }
  .form-config__button .icon, .form-plugin__button .icon, .form-identification__button .icon,
  .form-config__button svg,
  .form-plugin__button svg,
  .form-identification__button svg,
  .form-segments__button .icon,
  .form-segments__button svg,
  .form-triggers__button .icon,
  .form-triggers__button svg,
  .form-user__button .icon,
  .form-user__button svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.5rem;
    /*margin-left: rem(-4);*/ }
  @media all and (max-width: 440px) {
    .form-config__button, .form-plugin__button, .form-identification__button,
    .form-segments__button,
    .form-triggers__button,
    .form-user__button {
      margin: calc(1.07143rem / 2) calc(calc((1.07143rem * 4) / 3) / 2); } }
  .form-config__button--delete,
  .form-segments__button--delete,
  .form-triggers__button--delete,
  .form-user__button--delete {
    cursor: pointer;
    outline: none;
    display: inline-block;
    font-weight: 700;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
    background: #364357;
    border: none;
    border-radius: 0.21429rem;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    font-size: 1.14286rem;
    line-height: 2.14286rem;
    padding: 1.07143rem calc(1.07143rem * 2);
    color: #a7a7a7;
    background: #ffffff;
    border: 0.14286rem solid #f3f3f3;
    line-height: 1.85714rem; }
    .form-config__button--delete:hover,
    .form-segments__button--delete:hover,
    .form-triggers__button--delete:hover,
    .form-user__button--delete:hover {
      background: #2a3444; }
    .form-config__button--delete .icon,
    .form-config__button--delete svg,
    .form-segments__button--delete .icon,
    .form-segments__button--delete svg,
    .form-triggers__button--delete .icon,
    .form-triggers__button--delete svg,
    .form-user__button--delete .icon,
    .form-user__button--delete svg {
      margin-bottom: -0.14286rem;
      margin-right: 0.5rem;
      /*margin-left: rem(-4);*/ }
    .form-config__button--delete:hover,
    .form-segments__button--delete:hover,
    .form-triggers__button--delete:hover,
    .form-user__button--delete:hover {
      background: #fbfbfb; }
    .form-config__button--delete.-active,
    .form-segments__button--delete.-active,
    .form-triggers__button--delete.-active,
    .form-user__button--delete.-active {
      background: #f8f8f8; }
  .form-config__button--cancel,
  .form-segments__button--cancel,
  .form-triggers__button--cancel,
  .form-user__button--cancel {
    cursor: pointer;
    outline: none;
    display: inline-block;
    font-weight: 700;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
    background: #364357;
    border: none;
    border-radius: 0.21429rem;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    font-size: 1.14286rem;
    line-height: 2.14286rem;
    padding: 1.07143rem calc(1.07143rem * 2);
    color: #a7a7a7;
    background: #ffffff;
    border: 0.14286rem solid #f3f3f3;
    line-height: 1.85714rem;
    border: 0; }
    .form-config__button--cancel:hover,
    .form-segments__button--cancel:hover,
    .form-triggers__button--cancel:hover,
    .form-user__button--cancel:hover {
      background: #2a3444; }
    .form-config__button--cancel .icon,
    .form-config__button--cancel svg,
    .form-segments__button--cancel .icon,
    .form-segments__button--cancel svg,
    .form-triggers__button--cancel .icon,
    .form-triggers__button--cancel svg,
    .form-user__button--cancel .icon,
    .form-user__button--cancel svg {
      margin-bottom: -0.14286rem;
      margin-right: 0.5rem;
      /*margin-left: rem(-4);*/ }
    .form-config__button--cancel:hover,
    .form-segments__button--cancel:hover,
    .form-triggers__button--cancel:hover,
    .form-user__button--cancel:hover {
      background: #fbfbfb; }
    .form-config__button--cancel.-active,
    .form-segments__button--cancel.-active,
    .form-triggers__button--cancel.-active,
    .form-user__button--cancel.-active {
      background: #f8f8f8; }
    .form-config__button--cancel:hover,
    .form-segments__button--cancel:hover,
    .form-triggers__button--cancel:hover,
    .form-user__button--cancel:hover {
      border: 0; }

/*
    #UPLOAD FILE
    #UPLOAD FILE
    #UPLOAD FILE
*/
.form-user__el-file {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .form-user__el-file .form-user__label {
    width: 100%; }

.upload-file-button {
  margin: calc(1.07143rem / 2) calc(1.07143rem / 2) calc(1.07143rem / 2) 0;
  cursor: pointer;
  outline: none;
  display: inline-block;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  background: #364357;
  border: none;
  border-radius: 0.21429rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-size: 1rem;
  line-height: 1.42857rem;
  padding: 1.07143rem;
  color: #ffffff;
  background: #364357; }
  .upload-file-button:hover {
    background: #2a3444; }
  .upload-file-button .icon,
  .upload-file-button svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.5rem;
    /*margin-left: rem(-4);*/ }
  .upload-file-button .icon {
    font-size: 1.42857rem;
    margin-bottom: -0.5rem;
    -webkit-transform: translate3d(0, -0.14286rem, 0);
            transform: translate3d(0, -0.14286rem, 0); }

.upload-file-button__input {
  display: none; }

.form-user__delete-file {
  cursor: pointer;
  outline: none;
  display: inline-block;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  background: #364357;
  border: none;
  border-radius: 0.21429rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-size: 1rem;
  line-height: 1.42857rem;
  padding: 1.07143rem;
  color: #a7a7a7;
  background: #ffffff;
  border: 0.14286rem solid #f3f3f3;
  line-height: 1.14286rem;
  margin-left: 1.07143rem; }
  .form-user__delete-file:hover {
    background: #2a3444; }
  .form-user__delete-file .icon,
  .form-user__delete-file svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.5rem;
    /*margin-left: rem(-4);*/ }
  .form-user__delete-file:hover {
    background: #fbfbfb;
    color: #a7a7a7; }
  .form-user__delete-file.-active {
    background: #f8f8f8; }

.upload-file-img {
  margin: calc(1.07143rem / 2);
  height: 3.14286rem; }

/*
    #CIRCLE PIKER
    #CIRCLE PIKER
    #CIRCLE PIKER
*/
.react-select-container {
  width: 100%; }
  .react-select-container > .react-select__control {
    height: 3.85714rem;
    border: 0.14286rem solid #f3f3f3;
    border-radius: 0.21429rem; }
    .react-select-container > .react-select__control:hover {
      border-color: #e9e9e9; }
  .react-select-container .is-open {
    z-index: 100 !important; }

/*
    #CIRCLE PIKER
    #CIRCLE PIKER
    #CIRCLE PIKER
*/
.circle-picker {
  width: 100% !important;
  margin-bottom: 0 !important; }
  .circle-picker > span > div {
    margin: calc(1.07143rem / 2) !important; }

/*
  #ERROR-MESSAGE
  #ERROR-MESSAGE
  #ERROR-MESSAGE
*/
.error-message {
  display: inline;
  color: #364357; }

.daterange-form__group {
  display: flex;
  align-items: center; }

.daterange-value__group {
  display: flex;
  align-items: center; }
  .daterange-value__group .form-config__input, .daterange-value__group .panels-loader__input, .daterange-value__group .form-plugin__input {
    width: 11.42857rem; }
  .daterange-value__group input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none; }
  .daterange-value__group .react-select-container {
    min-width: 10rem; }
    .daterange-value__group .react-select-container .react-select__control {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.daterange-operator {
  padding: 0 1.14286rem;
  color: #8f8f8f;
  font-size: 1.71429rem;
  font-weight: 500; }

.react-select-container {
  outline: none; }
  .react-select-container .react-select__control--is-focused {
    box-shadow: none; }

.rdrDateRangePickerWrapper .rdrDateDisplayItemActive input {
  outline: none; }

.rdrDateRangePickerWrapper .rdrDateDisplay {
  color: #364357 !important;
  background-color: #F9F9FC; }

.rdrDateRangePickerWrapper .rdrStaticRange:hover .rdrStaticRangeLabel {
  background-color: #f2f2f9; }

.rdrDateRangePickerWrapper .rdrStaticRange.rdrStaticRangeSelected {
  color: #364357 !important; }

.rdrDateRangePickerWrapper .rdrEndEdge,
.rdrDateRangePickerWrapper .rdrInRange,
.rdrDateRangePickerWrapper .rdrStartEdge {
  background-color: #364357; }

.rdrDateRangePickerWrapper .rdrDayHovered span {
  border-color: #364357; }

.rdrDateRangePickerWrapper .rdrDayInPreview, .rdrDateRangePickerWrapper .rdrDayEndPreview, .rdrDateRangePickerWrapper .rdrDayStartPreview {
  border-color: #364357; }

/*
	#FILTER
	#FILTER
	#FILTER
*/
.container-list-filters {
  z-index: 1;
  padding-top: 1.07143rem;
  margin: 0 1.07143rem; }

.mob-container-list-filters {
  border: 0.14286rem solid #f3f3f3;
  border-radius: 0.21429rem;
  margin-top: 2.21429rem; }
  @media all and (max-width: 660px) {
    .mob-container-list-filters {
      position: fixed;
      z-index: 20;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 100%;
      background: rgba(54, 67, 87, 0.8);
      padding: 1.07143rem;
      opacity: 0;
      -webkit-transform: translate3d(-50%, 100%, 0);
              transform: translate3d(-50%, 100%, 0); }
      .mob-container-list-filters.-display {
        opacity: 1;
        -webkit-transform: translate3d(-50%, -50%, 0);
                transform: translate3d(-50%, -50%, 0); }
        .mob-container-list-filters.-display > .list-filters {
          opacity: 1;
          -webkit-transform: translate3d(-50%, -50%, 0);
                  transform: translate3d(-50%, -50%, 0); } }

.list-filters {
  display: flex;
  background: #ffffff;
  padding: calc(1.07143rem / 2); }
  @media all and (max-width: 1170px) {
    .list-filters {
      flex-wrap: wrap; } }
  @media all and (max-width: 660px) {
    .list-filters {
      flex-direction: column;
      justify-content: flex-start;
      position: fixed;
      z-index: 20;
      left: 50%;
      top: 50%;
      width: calc(100% - 2.14286rem);
      height: auto;
      border: 0;
      border-radius: 0.21429rem;
      padding: 0;
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out;
      -webkit-transform: translate3d(-50%, -48%, 0);
              transform: translate3d(-50%, -48%, 0); } }

.list-filters__el {
  position: relative;
  flex-grow: 2;
  padding: calc(1.07143rem / 2);
  height: auto; }
  @media all and (max-width: 1170px) {
    .list-filters__el {
      width: calc(50% - 1.07143rem); } }
  @media all and (max-width: 1170px) and (max-width: 660px) {
    .list-filters__el {
      width: 100%; } }
  @media all and (max-width: 660px) {
    .list-filters__el {
      padding: calc(1.07143rem / 2) calc((1.07143rem * 4) / 3);
      flex-grow: 0; } }
  .list-filters__el:last-child {
    margin-right: 0; }
  .list-filters__el .svg-input-filters {
    color: silver;
    position: absolute;
    z-index: 0;
    left: 1.60714rem;
    top: 50%;
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0); }
    @media all and (max-width: 660px) {
      .list-filters__el .svg-input-filters {
        left: 2.5rem; } }
  @media all and (max-width: 1170px) {
    .list-filters__el--search {
      width: 100%; } }
  @media all and (max-width: 1170px) {
    .list-filters__el--date {
      display: none; } }
  .list-filters__el--button {
    flex-grow: 0; }
    @media all and (max-width: 1170px) {
      .list-filters__el--button {
        width: auto;
        text-align: center; } }
    @media all and (max-width: 660px) {
      .list-filters__el--button {
        padding: calc((1.07143rem * 4) / 3);
        padding-top: calc(1.07143rem / 2); } }
  @media all and (max-width: 800px) {
    .list-filters__el--list-grid {
      display: none; }
      .list-filters__el--list-grid * {
        display: none; } }
  .list-filters__el--title {
    display: none; }
    @media all and (max-width: 660px) {
      .list-filters__el--title {
        display: block;
        position: relative;
        font-size: 1.42857rem;
        line-height: 2.14286rem;
        color: #8f8f8f;
        border-bottom: 0.14286rem solid #f3f3f3;
        padding: calc((1.07143rem * 4) / 3);
        margin-bottom: 1.07143rem; } }

.input-filters {
  position: relative;
  z-index: 1;
  padding-left: 2.85714rem; }

.button-filters {
  cursor: pointer;
  outline: none;
  display: inline-block;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  background: #364357;
  border: none;
  border-radius: 0.21429rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-size: 1rem;
  line-height: 1.42857rem;
  padding: 1.07143rem;
  color: #a7a7a7;
  background: #ffffff;
  border: 0.14286rem solid #f3f3f3;
  line-height: 1.14286rem;
  line-height: 1.42857rem; }
  .button-filters:hover {
    background: #2a3444; }
  .button-filters .icon,
  .button-filters svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.5rem;
    /*margin-left: rem(-4);*/ }
  .button-filters:hover {
    background: #fbfbfb;
    color: #a7a7a7; }
  .button-filters.-active {
    background: #f8f8f8; }

.container-datepicker-filters {
  position: relative;
  display: block;
  text-align: center;
  background: #ffffff; }
  .container-datepicker-filters > div:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0.14286rem;
    background: #f3f3f3; }

.datepicker-filters {
  width: auto;
  border: 0.14286rem solid #f3f3f3;
  border-radius: 0.21429rem;
  margin: 1.07143rem; }

.button-change-view-filters {
  outline: none;
  cursor: pointer;
  position: relative;
  width: 50px;
  height: 100%;
  border: 0.14286rem solid #f3f3f3;
  -webkit-transition: all .2s ease;
  transition: all .2s ease; }
  .button-change-view-filters:first-child {
    border-radius: 0.35714rem 0 0 0.35714rem;
    border-right: 0.07143rem solid #f3f3f3; }
  .button-change-view-filters:last-child {
    border-radius: 0 0.35714rem 0.35714rem 0;
    border-left: 0.07143rem solid #f3f3f3; }
  .button-change-view-filters:hover {
    background: #f8f8f8; }
  .button-change-view-filters.-active {
    background: #f8f8f8; }

.button-change-view-filters__el {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1.21429rem;
  height: 0.42857rem;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  border-radius: 0.14286rem;
  background: silver; }
  .button-change-view-filters__el:first-child {
    -webkit-transform: translate3d(-50%, -125%, 0);
            transform: translate3d(-50%, -125%, 0); }
  .button-change-view-filters__el:last-child {
    -webkit-transform: translate3d(-50%, 25%, 0);
            transform: translate3d(-50%, 25%, 0); }
  .button-change-view-filters__el--for-4 {
    background: transparent;
    display: flex;
    justify-content: space-between; }
    .button-change-view-filters__el--for-4:before, .button-change-view-filters__el--for-4:after {
      content: '';
      display: block;
      width: 42%;
      height: 100%;
      border-radius: 0.14286rem;
      background: silver; }

.button-refresh-filters {
  cursor: pointer;
  outline: none;
  display: inline-block;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  background: #364357;
  border: none;
  border-radius: 0.21429rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-size: 1rem;
  line-height: 1.42857rem;
  padding: 1.07143rem;
  color: #ffffff;
  background: #364357;
  font-size: 1rem;
  line-height: 1.71429rem; }
  .button-refresh-filters:hover {
    background: #2a3444; }
  .button-refresh-filters .icon,
  .button-refresh-filters svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.5rem;
    /*margin-left: rem(-4);*/ }
  @media all and (max-width: 660px) {
    .button-refresh-filters {
      width: 100%;
      margin-bottom: 0.53571rem; } }

.container-leads-item {
  padding: 0.53571rem 0.53571rem 0.53571rem 0.53571rem;
  min-height: 100vh; }
  @media all and (max-width: 440px) {
    .container-leads-item {
      padding: 0;
      padding-top: calc(1.07143rem / 2); } }
  .container-leads-item > div > .infinite-scroll-component {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
  .container-leads-item--list > div > div > .link-leads-item {
    width: calc(100% - 1.07143rem); }
  .container-leads-item--grid > div > div > .link-leads-item {
    width: calc(33.333% - 1.07143rem); }
    @media all and (max-width: 1170px) {
      .container-leads-item--grid > div > div > .link-leads-item {
        width: calc(50% - 1.07143rem); } }
    @media all and (max-width: 800px) {
      .container-leads-item--grid > div > div > .link-leads-item {
        width: calc(100% - 1.07143rem); } }

@-webkit-keyframes animLinkLeadsItem {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 1.42857rem, 0);
            transform: translate3d(0, 1.42857rem, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes animLinkLeadsItem {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 1.42857rem, 0);
            transform: translate3d(0, 1.42857rem, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

/*
	#RESPONSIVE ADD
	#RESPONSIVE ADD
	#RESPONSIVE ADD
*/
.floating-action-filters {
  display: none;
  position: fixed;
  z-index: 20;
  bottom: 6.42857rem;
  right: 1.07143rem;
  width: 4.28571rem;
  height: 4.28571rem;
  border: 0;
  border-radius: 50%;
  background: #364357;
  color: #ffffff;
  font-size: 1.42857rem;
  box-shadow: 0 0 20px rgba(54, 67, 87, 0.3);
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }
  @media all and (max-width: 660px) {
    .floating-action-filters {
      display: block; } }
  @media all and (max-width: 440px) {
    .floating-action-filters {
      bottom: 5.35714rem;
      right: calc(1.07143rem / 2); } }
  .floating-action-filters .icon,
  .floating-action-filters svg {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate3d(-50%, -40%, 0);
            transform: translate3d(-50%, -40%, 0); }
  .floating-action-filters.-active {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    opacity: 0; }

.close-action-filters {
  position: absolute;
  top: 50%;
  right: calc((1.07143rem * 4) / 3);
  width: 1.42857rem;
  height: 1.42857rem;
  border: none;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
  background: none; }
  .close-action-filters:before, .close-action-filters:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 0.14286rem;
    background: silver; }
  .close-action-filters:before {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
            transform: translate3d(-50%, -50%, 0) rotate(45deg); }
  .close-action-filters:after {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
            transform: translate3d(-50%, -50%, 0) rotate(-45deg); }

/*
    #FILTER 02
    #FILTER 02
    #FILTER 02
*/
/* 
	#LEADS-SCORES-USER
	#LEADS-SCORES-USER
	#LEADS-SCORES-USER
*/
.leads-scores-user {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: calc(1.07143rem / 2); }
  .leads-scores-user.-display {
    padding: 0.53571rem;
    border-bottom: 0.14286rem solid #f3f3f3; }
  .leads-scores-user--config {
    border-bottom: 0; }

.leads-scores-user__el {
  display: flex;
  margin: calc(1.07143rem / 2);
  padding: calc(calc(1.07143rem / 3) * 2);
  background: #f3f3f3;
  border-radius: 0.21429rem;
  border: none;
  font-size: 0.85714rem;
  font-weight: 500;
  line-height: 1rem; }
  .leads-scores-user__el--button {
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
    background: #364357;
    color: #ffffff;
    font-weight: 700; }
    .leads-scores-user__el--button:hover {
      background: #323e51; }

.leads-scores-user__color {
  width: 1.07143rem;
  height: 1.07143rem;
  margin-right: 0.53571rem;
  border-radius: 50%; }

.leads-scores-user__name {
  margin-right: 0.53571rem; }

.leads-scores-user__score {
  font-weight: 400; }

.container-leads-filters-user {
  display: flex;
  flex-wrap: wrap;
  border-left: 0.14286rem solid #f3f3f3;
  border-right: 0.14286rem solid #f3f3f3;
  border-bottom: 0.14286rem solid #f3f3f3;
  background: #ffffff;
  margin: 0 1.07143rem; }

.leads-filters-user {
  position: relative;
  display: flex;
  flex-grow: 1;
  padding: 0.53571rem;
  border-left: 0.14286rem solid #f3f3f3;
  margin-left: -0.14286rem; }
  .leads-filters-user:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0.14286rem;
    -webkit-transform: translate3d(0, -0.14286rem, 0);
            transform: translate3d(0, -0.14286rem, 0);
    background: #f3f3f3; }

/* 
	#LEADS-SEGMENTS-USER
	#LEADS-SEGMENTS-USER
	#LEADS-SEGMENTS-USER
*/
.leads-segments-user {
  position: relative;
  display: flex;
  padding: calc(1.07143rem / 2) calc(1.07143rem / 2);
  border-left: 0.14286rem solid #f3f3f3; }
  .leads-segments-user:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 200%;
    height: 0.14286rem;
    -webkit-transform: translate3d(0, -0.14286rem, 0);
            transform: translate3d(0, -0.14286rem, 0);
    background: #f3f3f3; }

.leads-segments-user__el {
  position: relative; }
  .leads-segments-user__el:after {
    content: ',';
    position: absolute;
    top: 50%;
    right: 0;
    color: #8f8f8f;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0); }
  .leads-segments-user__el:last-child:after {
    display: none; }

.leads-segments-user__link {
  display: inline-block;
  margin: calc(1.07143rem / 2) 0;
  padding: calc(calc(1.07143rem / 3) * 2) 1.07143rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  color: #222b38;
  font-weight: 400;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  text-decoration: none; }
  .leads-segments-user__link:hover {
    color: #364357;
    text-decoration: underline; }

/*
	#LABEL-CHEBOX
	#LABEL-CHEBOX
	#LABEL-CHEBOX
*/
.label-checkbox {
  display: inline-block;
  font-size: 1rem;
  cursor: pointer; }
  .label-checkbox input {
    display: none; }
  .label-checkbox span {
    position: relative;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.53571rem;
    border-radius: 3px;
    border: 0.14286rem solid #cdcdcd;
    -webkit-transform: translate3d(0, 0.21429rem, 0);
            transform: translate3d(0, 0.21429rem, 0); }
    .label-checkbox span:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.85714rem;
      height: 0.85714rem;
      border-radius: 50%;
      background: silver;
      -webkit-transform: translate3d(-50%, -50%, 0);
              transform: translate3d(-50%, -50%, 0);
      opacity: 0;
      -webkit-transition: all .2s ease-in;
      transition: all .2s ease-in; }
  .label-checkbox span .svg {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    height: 120%;
    width: 120%;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0); }
    .label-checkbox span .svg .check {
      stroke: white;
      fill: none;
      stroke-width: 12;
      stroke-dasharray: 400;
      stroke-dashoffset: 400; }
  .label-checkbox:hover input ~ span:after {
    opacity: 0;
    -webkit-transform: translate3d(-50%, -50%, 0) scale(2.5);
            transform: translate3d(-50%, -50%, 0) scale(2.5); }
  .label-checkbox input:checked ~ span {
    background: #364357;
    border-color: #364357; }
    .label-checkbox input:checked ~ span:after {
      background: #364357;
      opacity: 1;
      -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
              transform: translate3d(-50%, -50%, 0) scale(1); }
    .label-checkbox input:checked ~ span > svg > path {
      -webkit-animation: check .4s ease forwards;
              animation: check .4s ease forwards; }

.checkbox-filters-user, .checkbox-plugin {
  padding: 0.39286rem;
  padding-left: 0.64286rem;
  margin: calc(1.07143rem / 2);
  border-radius: 0.21429rem;
  text-align: center;
  border: 0.14286rem solid #f3f3f3;
  -webkit-transition: all .2s ease;
  transition: all .2s ease; }
  .checkbox-filters-user:hover, .checkbox-plugin:hover {
    background: #fbfbfb; }

.lead-details__container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%; }
  @media all and (max-width: 1170px) {
    .lead-details__container {
      flex-direction: column; } }
  .lead-details__container .container-list-filters {
    height: 100%;
    width: 50%;
    background: #ffffff;
    padding: 0;
    border-left: 0.14286rem solid #f3f3f3;
    margin: 0;
    z-index: 9;
    order: 2; }
    .lead-details__container .container-list-filters .list-filters__box {
      padding: 1.07143rem;
      margin: 0; }
    @media all and (max-width: 1170px) {
      .lead-details__container .container-list-filters {
        height: auto;
        border-bottom: 0.14286rem solid #f3f3f3;
        order: 1;
        border-left: none; } }
    .lead-details__container .container-list-filters .leads-scores-user {
      padding: 0;
      width: 100%; }
    .lead-details__container .container-list-filters .leads-item__container {
      padding: 0;
      margin: 0; }
      .lead-details__container .container-list-filters .leads-item__container--last-seen {
        margin-left: 1.07143rem;
        padding-left: 1.07143rem; }
    .lead-details__container .container-list-filters .leads-scores-user__el {
      margin: 0;
      margin-top: 0.53571rem;
      margin-right: 0.53571rem; }
    .lead-details__container .container-list-filters .leads-item__container--last-seen:before {
      display: none; }
  .lead-details__container .vertical-timeline {
    order: 1;
    margin: 0;
    margin-left: 1.07143rem !important;
    margin-top: 3.21429rem !important;
    margin-right: 3.21429rem !important; }
    @media all and (max-width: 1170px) {
      .lead-details__container .vertical-timeline {
        order: 2; } }
    @media all and (max-width: 1170px) {
      .lead-details__container .vertical-timeline {
        margin: 0 !important; } }
  .lead-details__container .leads-item--user {
    border: none;
    margin: 0;
    margin-bottom: 0.53571rem; }
  .lead-details__container .container-leads-filters-user {
    border: none;
    margin: 0; }
  .lead-details__container .leads-segments-user {
    border: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    margin-top: 1.07143rem;
    width: 100%; }
    .lead-details__container .leads-segments-user:before, .lead-details__container .leads-segments-user:after {
      display: none; }
  .lead-details__container .leads-segments-user__link,
  .lead-details__container .leads-segments-user__el {
    margin: 0;
    padding: 0; }
    .lead-details__container .leads-segments-user__link:before, .lead-details__container .leads-segments-user__link:after,
    .lead-details__container .leads-segments-user__el:before,
    .lead-details__container .leads-segments-user__el:after {
      display: none; }
  .lead-details__container .leads-segments-user__link {
    background-color: #f3f3f3;
    padding: 0.53571rem;
    color: #5c5c5c;
    margin-right: 0.53571rem;
    margin-bottom: 0.53571rem;
    font-size: 0.85714rem;
    text-decoration: none;
    display: flex;
    align-items: center; }
    .lead-details__container .leads-segments-user__link svg {
      fill: #8f8f8f;
      margin-right: 0.53571rem; }

.link-leads-item {
  display: block;
  background: #ffffff;
  margin: calc(1.07143rem / 2);
  border: 0.14286rem solid #f3f3f3;
  border-radius: 0.21429rem;
  text-decoration: none;
  color: #000000;
  opacity: 1;
  -webkit-animation: .5s ease animLinkLeadsItem forwards;
          animation: .5s ease animLinkLeadsItem forwards; }
  .link-leads-item:hover {
    color: #000000; }

.leads-item {
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  border-radius: 0.21429rem; }
  .leads-item--user {
    border: 0.14286rem solid #f3f3f3;
    margin-top: 2.21429rem; }

.leads-item__container {
  display: flex;
  align-items: center;
  padding: 1.07143rem;
  width: 60%; }
  .leads-item__container--last-seen {
    width: 40%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-left: 2px solid #f3f3f3;
    overflow: hidden;
    position: relative; }
    .leads-item__container--last-seen:before {
      content: '';
      position: absolute;
      z-index: 2;
      right: 0;
      top: 0;
      width: 50%;
      height: 100%;
      border-radius: 0 0.35714rem 0.35714rem 0;
      background: -webkit-gradient(linear, right top, left top, from(#ffffff), to(rgba(255, 255, 255, 0))) no-repeat;
      background: -webkit-linear-gradient(right, #ffffff, rgba(255, 255, 255, 0)) no-repeat;
      background: linear-gradient(-90deg, #ffffff, rgba(255, 255, 255, 0)) no-repeat; }
    .leads-item__container--last-seen > .leads-item__subcontainer {
      overflow: visible; }

.leads-item__subcontainer {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }
  .leads-item__subcontainer--infos {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    margin-left: 1.07143rem; }
  .leads-item__subcontainer > svg {
    margin-right: 0.71429rem; }

.leads-item__img {
  min-width: 3.42857rem;
  min-height: 3.42857rem;
  width: 3.42857rem;
  height: 3.42857rem;
  display: block;
  border-radius: 50%; }
  .leads-item__img--no-img {
    background-color: #e6e6e6;
    background-image: url(/static/media/user.371a0302.svg);
    background-size: 84%;
    background-repeat: no-repeat;
    background-position: center bottom; }
    .leads-item__img--no-img.-colored {
      background-color: #c6c6c6; }

.leads-item__name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }
  .leads-item__name.-unknown {
    color: #8f8f8f; }

.leads-item__place {
  white-space: nowrap;
  margin-top: 0.71429rem; }
  .leads-item__place > span > span {
    width: 1.14286rem !important;
    height: 1.07143rem !important;
    border-radius: 50%;
    background-size: cover !important;
    background-position: center center !important;
    margin-right: 0.71429rem !important; }

.container-leads-lookalikes-user {
  margin-top: 2.14286rem; }
  @media all and (max-width: 1170px) {
    .container-leads-lookalikes-user {
      display: none; } }
  .container-leads-lookalikes-user h3 {
    color: #8f8f8f;
    font-weight: 500;
    font-size: 1.07143rem;
    letter-spacing: 0.03571rem; }

.lead-lookalikes-list {
  display: flex;
  flex-wrap: wrap;
  margin: -0.625rem;
  margin-top: 0; }
  .lead-lookalikes-list .link-leads-item {
    width: calc(50% - 1.07143rem); }
    .lead-lookalikes-list .link-leads-item .leads-item__container {
      padding: 0.71429rem 0.53571rem; }
    .lead-lookalikes-list .link-leads-item .lookalike-score {
      min-width: 3.42857rem;
      min-height: 3.42857rem;
      width: 3.42857rem;
      height: 3.42857rem;
      margin-left: -3.42857rem;
      display: flex;
      align-content: center;
      justify-content: space-around;
      flex-wrap: wrap;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.3); }
      .lead-lookalikes-list .link-leads-item .lookalike-score .inner {
        font-size: 1.14286rem;
        color: #474747;
        font-weight: bold; }

/*
	#TIMELINE
	#TIMELINE
	#TIMELINE
*/
.vertical-timeline {
  max-width: 100%;
  padding: 0 !important;
  margin: 0 auto !important; }
  .vertical-timeline:before {
    width: 0.21429rem;
    left: 2.42857rem;
    background: #e6e6e6; }
  .vertical-timeline > div {
    background: #f8f8f8;
    padding-top: 1.07143rem;
    min-height: 80vh; }

@media all and (max-width: 1170px) {
  .vertical-timeline-element--work {
    padding: 1.07143rem;
    margin: 1.07143rem 0 1.07143rem 1.07143rem; } }

@media all and (max-width: 440px) {
  .vertical-timeline-element--work {
    margin-right: 0; } }

@media all and (max-width: 1170px) {
  .vertical-timeline-element-content {
    margin-left: 2.85714rem; } }

.vertical-timeline-element-icon {
  box-shadow: 0 0 0 0.35714rem #f8f8f8;
  top: auto;
  left: 16px; }
  @media all and (max-width: 1170px) {
    .vertical-timeline-element-icon {
      left: 0; } }

.vertical-timeline-element-content .vertical-timeline-element-date,
.vertical-timeline-element-content p {
  font-size: 1rem; }

.vertical-timeline-element-content {
  background: transparent;
  box-shadow: none;
  padding: 0;
  margin-left: 78px; }
  .vertical-timeline-element-content:before {
    display: none; }
  @media all and (max-width: 1170px) {
    .vertical-timeline-element-content {
      margin-left: 56px; } }
  @media all and (max-width: 660px) {
    .vertical-timeline-element-content {
      margin-left: 48px; } }

.leads-event-user__container-head {
  display: flex;
  justify-content: space-between;
  background: #e9e9e9;
  padding: 0.53571rem;
  border-radius: 0.21429rem; }

.leads-event-user__vue-type {
  font-size: 1.14286rem;
  line-height: 1.42857rem;
  font-weight: 700;
  color: #364357;
  padding-left: 0.21429rem;
  -webkit-transform: translate3d(0, 0.14286rem, 0);
          transform: translate3d(0, 0.14286rem, 0);
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .leads-event-user__vue-type::first-letter {
    text-transform: uppercase; }

.leads-event-user__consecutive-time {
  font-size: 1.14286rem;
  line-height: 1.42857rem;
  padding: 0 0.53571rem;
  border-radius: 0.21429rem;
  background: #364357;
  font-weight: 700 !important;
  margin: 0 !important;
  color: #ffffff !important; }
  .leads-event-user__consecutive-time > svg {
    font-size: 0.71429rem;
    margin-right: -0.07143rem; }

.leads-event-user__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0.53571rem;
  font-weight: 500;
  color: #8f8f8f;
  border-bottom: 0.14286rem solid #e6e6e6; }
  .leads-event-user__container:last-child {
    border-bottom: 0; }
  .leads-event-user__container--ip-tag, .leads-event-user__container--moment {
    padding: 0.53571rem;
    justify-content: space-between;
    border-bottom: 0; }
  .leads-event-user__container--mail, .leads-event-user__container--url {
    background: #e9e9e9;
    border-radius: 0.21429rem;
    border-bottom: 0;
    padding: 0.07143rem; }
  .leads-event-user__container > div > svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.35714rem; }

.leads-event-user__subtitle {
  font-weight: 700; }

.urlurl {
  color: #8f8f8f !important;
  margin: 0 !important;
  padding: calc(1.07143rem / 3) calc(1.07143rem / 2);
  background: #e9e9e9;
  border-radius: 0.21429rem; }
  .urlurl > svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.35714rem; }

.leads-event-user__city,
.leads-event-user__country,
.leads-event-user__data-table,
.leads-event-user__device-browser,
.leads-event-user__device-system,
.leads-event-user__device-type,
.leads-event-user__ip,
.leads-event-user__score-tag,
.leads-event-user__url {
  color: #8f8f8f !important;
  margin: 0 !important;
  padding: 0rem 0.53571rem; }
  .leads-event-user__city:first-child,
  .leads-event-user__country:first-child,
  .leads-event-user__data-table:first-child,
  .leads-event-user__device-browser:first-child,
  .leads-event-user__device-system:first-child,
  .leads-event-user__device-type:first-child,
  .leads-event-user__ip:first-child,
  .leads-event-user__score-tag:first-child,
  .leads-event-user__url:first-child {
    padding-left: 0; }
  .leads-event-user__city:last-child,
  .leads-event-user__country:last-child,
  .leads-event-user__data-table:last-child,
  .leads-event-user__device-browser:last-child,
  .leads-event-user__device-system:last-child,
  .leads-event-user__device-type:last-child,
  .leads-event-user__ip:last-child,
  .leads-event-user__score-tag:last-child,
  .leads-event-user__url:last-child {
    padding-right: 0; }
  .leads-event-user__city > svg,
  .leads-event-user__country > svg,
  .leads-event-user__data-table > svg,
  .leads-event-user__device-browser > svg,
  .leads-event-user__device-system > svg,
  .leads-event-user__device-type > svg,
  .leads-event-user__ip > svg,
  .leads-event-user__score-tag > svg,
  .leads-event-user__url > svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.35714rem; }
  .leads-event-user__city > .icon,
  .leads-event-user__country > .icon,
  .leads-event-user__data-table > .icon,
  .leads-event-user__device-browser > .icon,
  .leads-event-user__device-system > .icon,
  .leads-event-user__device-type > .icon,
  .leads-event-user__ip > .icon,
  .leads-event-user__score-tag > .icon,
  .leads-event-user__url > .icon {
    text-transform: uppercase;
    font-weight: 700;
    color: #8f8f8f;
    margin-right: 0.35714rem; }
  .leads-event-user__city > .leads-event-user__score-color,
  .leads-event-user__country > .leads-event-user__score-color,
  .leads-event-user__data-table > .leads-event-user__score-color,
  .leads-event-user__device-browser > .leads-event-user__score-color,
  .leads-event-user__device-system > .leads-event-user__score-color,
  .leads-event-user__device-type > .leads-event-user__score-color,
  .leads-event-user__ip > .leads-event-user__score-color,
  .leads-event-user__score-tag > .leads-event-user__score-color,
  .leads-event-user__url > .leads-event-user__score-color {
    display: inline-block;
    width: 0.71429rem;
    height: 0.71429rem;
    border-radius: 50%;
    margin-right: 0.35714rem; }

.leads-event-user__url {
  position: relative;
  overflow: hidden;
  overflow-wrap: break-word;
  padding: 0.28571rem 0.46429rem !important; }
  .leads-event-user__url:first-letter {
    padding-left: 1.42857rem; }
  .leads-event-user__url > svg {
    position: absolute;
    left: 0.46429rem;
    top: 0.57143rem; }

.leads-event-user__container-data-table {
  width: 100%; }

.leads-event-user__data-table {
  padding: 0.28571rem 0.46429rem !important;
  display: inline-block; }
  .leads-event-user__data-table > svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.35714rem; }

/*
	#NO-ICON
*/
.tag-name-no-icon {
  background: #dadada;
  padding: 0.07143rem 0.21429rem;
  border-radius: 0.21429rem; }

/*
	#CONFIGURATION
	#CONFIGURATION
	#CONFIGURATION
*/
.configuration-container, .profile-container, .segments-container, .triggers-container, .dashboard-container {
  padding-bottom: 2.14286rem; }

.tags-container, .domains-container, .trackers-container, .identification-container, .scoring-container, .rules-container, .rule-container, .organization-container, .account-container, .team-container, .segments-subcontainer, .plugin-container {
  margin: 0 auto;
  max-width: 57.14286rem;
  border-radius: 0.21429rem;
  padding: 0 1.07143rem;
  padding-top: calc(1.07143rem * 2); }
  @media all and (max-width: 660px) {
    .tags-container, .domains-container, .trackers-container, .identification-container, .scoring-container, .rules-container, .rule-container, .organization-container, .account-container, .team-container, .segments-subcontainer, .plugin-container {
      margin-bottom: calc(calc(1.07143rem * 2) * 2); } }
  @media all and (max-width: 440px) {
    .tags-container, .domains-container, .trackers-container, .identification-container, .scoring-container, .rules-container, .rule-container, .organization-container, .account-container, .team-container, .segments-subcontainer, .plugin-container {
      padding: 0 calc(1.07143rem / 2);
      padding-top: calc(1.07143rem * 2); } }

/*
	#BREADCRUMB
	#BREADCRUMB
	#BREADCRUMB
*/
.breadcrumb {
  font-size: 1rem;
  color: #8f8f8f; }

.breadcrumb__el {
  display: inline-block;
  width: 0.71429rem;
  height: 0.71429rem;
  margin: 0 0.71429rem 0 0.42857rem;
  position: relative;
  border-top: 0.14286rem solid #f3f3f3;
  border-right: 0.14286rem solid #f3f3f3;
  border-color: #8f8f8f;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

/*
	#PANELS-LOADER
	#PANELS-LOADER
	#PANELS-LOADER
*/
@-webkit-keyframes animateGradient {
  0% {
    background-position: 100% 0%; }
  100% {
    background-position: 0% 0%; } }
@keyframes animateGradient {
  0% {
    background-position: 100% 0%; }
  100% {
    background-position: 0% 0%; } }

.panels-loader__title {
  display: flex;
  align-items: center; }
  .panels-loader__title:before {
    content: '';
    display: inline-block;
    width: 6.42857rem;
    height: 1.42857rem;
    border-radius: 0.21429rem;
    background: #f3f3f3;
    /* */
    background: -webkit-gradient(linear, left top, right top, from(#f3f3f3), color-stop(rgba(243, 243, 243, 0)), color-stop(#f3f3f3), to(rgba(243, 243, 243, 0)));
    background: -webkit-linear-gradient(left, #f3f3f3, rgba(243, 243, 243, 0), #f3f3f3, rgba(243, 243, 243, 0));
    background: linear-gradient(to right, #f3f3f3, rgba(243, 243, 243, 0), #f3f3f3, rgba(243, 243, 243, 0));
    background-size: 300% 100%;
    -webkit-animation: animateGradient 1.5s linear infinite;
            animation: animateGradient 1.5s linear infinite; }

.panels-loader__label {
  display: flex;
  align-items: center; }
  .panels-loader__label:before {
    content: '';
    display: inline-block;
    width: 4.28571rem;
    height: 1.14286rem;
    border-radius: 0.21429rem;
    background: #f3f3f3;
    /* */
    background: -webkit-gradient(linear, left top, right top, from(#f3f3f3), color-stop(rgba(243, 243, 243, 0.5)), color-stop(#f3f3f3), to(rgba(243, 243, 243, 0.5)));
    background: -webkit-linear-gradient(left, #f3f3f3, rgba(243, 243, 243, 0.5), #f3f3f3, rgba(243, 243, 243, 0.5));
    background: linear-gradient(to right, #f3f3f3, rgba(243, 243, 243, 0.5), #f3f3f3, rgba(243, 243, 243, 0.5));
    background-size: 300% 100%;
    -webkit-animation: animateGradient 1s linear infinite;
            animation: animateGradient 1s linear infinite; }

.panels-loader__input {
  display: flex;
  align-items: center; }
  .panels-loader__input:hover {
    border: 0.14286rem solid #f3f3f3; }
  .panels-loader__input:before {
    content: '';
    display: inline-block;
    width: 17.85714rem;
    height: 1.14286rem;
    border-radius: 0.21429rem;
    background: #f3f3f3;
    /* */
    background: -webkit-gradient(linear, left top, right top, from(#f3f3f3), color-stop(rgba(243, 243, 243, 0.5)), color-stop(#f3f3f3), to(rgba(243, 243, 243, 0.5)));
    background: -webkit-linear-gradient(left, #f3f3f3, rgba(243, 243, 243, 0.5), #f3f3f3, rgba(243, 243, 243, 0.5));
    background: linear-gradient(to right, #f3f3f3, rgba(243, 243, 243, 0.5), #f3f3f3, rgba(243, 243, 243, 0.5));
    background-size: 300% 100%;
    -webkit-animation: animateGradient 1s linear infinite;
            animation: animateGradient 1s linear infinite; }

.panels-loader__button {
  font-size: 1.14286rem;
  line-height: 2.14286rem;
  padding: 1.07143rem calc(1.07143rem * 2);
  margin: 20px 0;
  border: none;
  border-radius: 0.21429rem;
  display: inline-block;
  margin: 1.07143rem 0.71429rem 2.14286rem 2.14286rem;
  width: 8.57143rem;
  height: 4.28571rem;
  /* */
  background: -webkit-gradient(linear, left top, right top, from(#364357), color-stop(rgba(54, 67, 87, 0.7)), color-stop(#364357), to(rgba(54, 67, 87, 0.7)));
  background: -webkit-linear-gradient(left, #364357, rgba(54, 67, 87, 0.7), #364357, rgba(54, 67, 87, 0.7));
  background: linear-gradient(to right, #364357, rgba(54, 67, 87, 0.7), #364357, rgba(54, 67, 87, 0.7));
  background-size: 300% 100%;
  -webkit-animation: animateGradient 1.5s linear infinite;
          animation: animateGradient 1.5s linear infinite; }
  .panels-loader__button--delete {
    margin-left: 1.07143rem;
    /* */
    background: -webkit-gradient(linear, left top, right top, from(#f3f3f3), color-stop(rgba(243, 243, 243, 0.5)), color-stop(#f3f3f3), to(rgba(243, 243, 243, 0.5)));
    background: -webkit-linear-gradient(left, #f3f3f3, rgba(243, 243, 243, 0.5), #f3f3f3, rgba(243, 243, 243, 0.5));
    background: linear-gradient(to right, #f3f3f3, rgba(243, 243, 243, 0.5), #f3f3f3, rgba(243, 243, 243, 0.5));
    background-size: 300% 100%;
    -webkit-animation: animateGradient 1.5s linear infinite;
            animation: animateGradient 1.5s linear infinite; }
  .panels-loader__button--cancel {
    padding: 0;
    height: 1.28571rem;
    width: 4.28571rem;
    margin-left: 2.14286rem;
    margin-bottom: 3.57143rem;
    /* */
    background: -webkit-gradient(linear, left top, right top, from(#f3f3f3), color-stop(rgba(243, 243, 243, 0.5)), color-stop(#f3f3f3), to(rgba(243, 243, 243, 0.5)));
    background: -webkit-linear-gradient(left, #f3f3f3, rgba(243, 243, 243, 0.5), #f3f3f3, rgba(243, 243, 243, 0.5));
    background: linear-gradient(to right, #f3f3f3, rgba(243, 243, 243, 0.5), #f3f3f3, rgba(243, 243, 243, 0.5));
    background-size: 300% 100%;
    -webkit-animation: animateGradient 1.5s linear infinite;
            animation: animateGradient 1.5s linear infinite; }

/*
	#CATEGORIE
	#CATEGORIE
	#CATEGORIE
*/
.tags-tag-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: calc(1.07143rem / 2);
  background: #ffffff;
  border-radius: 0.21429rem;
  border-bottom: 0;
  margin: 0 auto;
  margin-bottom: calc(1.07143rem * 2);
  border-radius: 0.21429rem;
  border: 0.14286rem solid #f3f3f3;
  padding: calc(1.07143rem / 2); }
  @media all and (max-width: 440px) {
    .tags-tag-list {
      padding: cacl(calc((1.07143rem * 4) / 3) / 2); } }

.tags-tag-list__button, .tags-tag-list__item {
  display: flex;
  margin: calc(1.07143rem / 2);
  padding: calc( calc(1.07143rem / 3) * 2);
  background: #f3f3f3;
  border-radius: 0.21429rem;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all .2s ease;
  transition: all .2s ease; }
  .tags-tag-list__button:hover, .tags-tag-list__item:hover {
    background: #e6e6e6; }

.tags-tag-list__button {
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  background: #364357;
  color: #ffffff;
  font-weight: 700;
  line-height: 1.07143rem; }
  .tags-tag-list__button:hover {
    background: #2e394a; }

.tags-tag-list__color {
  width: 1.07143rem;
  height: 1.07143rem;
  margin-right: 0.53571rem;
  border-radius: 50%; }

.tags-tag-list__name {
  margin-right: 0.53571rem; }

.tags-tag-list__score {
  font-weight: 400; }

/*
	#FUCK
	#FUCK
	#FUCK
*/
/*
	#DOMAINS
	#DOMAINS
	#DOMAINS
*/
.domains-list {
  background: #ffffff;
  border-radius: 0.21429rem;
  border: 0.14286rem solid #f3f3f3;
  margin-bottom: calc(1.07143rem * 2);
  padding: calc(1.07143rem / 2); }
  @media all and (max-width: 440px) {
    .domains-list {
      padding: cacl(calc((1.07143rem * 4) / 3) / 2); } }

.domains-list__el {
  display: inline-block;
  margin: calc(1.07143rem / 2);
  max-width: 100%; }

.domains-list__link {
  border: 0;
  display: inline-block;
  font-size: 1rem;
  background: #f3f3f3;
  padding: calc(calc(1.07143rem / 3) * 2) 1.07143rem;
  padding-left: 0.71429rem;
  border-radius: 0.21429rem;
  text-align: left;
  word-break: break-all;
  overflow: hidden; }
  @media all and (max-width: 440px) {
    .domains-list__link {
      max-width: 96%; } }
  .domains-list__link:hover {
    background: #eeeeee; }

.domains-list__link-tag {
  background: #e4e4e4;
  border-radius: 0.21429rem;
  padding: 0.07143rem 0.35714rem;
  margin-right: calc(1.07143rem / 2); }

.domains-list__button {
  font-size: 1rem;
  line-height: 1.07143rem;
  display: inline-block;
  padding: calc( calc(1.07143rem / 3) * 2);
  border-radius: 0.21429rem;
  border: 0;
  background: #364357;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase; }
  .domains-list__button:hover {
    background: #2e394a; }

.dns-info__value-check {
  align-items: center; }
  .dns-info__value-check svg {
    margin-right: 0.28571rem; }
  .dns-info__value-check.dns-info__value-ok {
    color: #339919; }
  .dns-info__value-check.dns-info__value-notok {
    color: #e55039; }

.dns-table {
  background: #f3f3f3;
  margin-top: 0.57143rem;
  margin-bottom: 0.57143rem;
  font-family: monospace;
  font-size: 0.85714rem; }
  .dns-table tr td {
    padding: 0.57143rem; }

.dns-help__container {
  margin-top: 1.71429rem; }
  .dns-help__container p, .dns-help__container .dns-help-registrar {
    display: inline-block; }
  .dns-help__container .dns-help-registrar {
    margin-left: 1.71429rem;
    width: 8.85714rem; }

/*
	#INTEGRATION TRACKERS
	#INTEGRATION TRACKERS
	#INTEGRATION TRACKERS
*/
.trackers-subcontainer, .identification-subcontainer {
  background: #ffffff;
  border-radius: 0.21429rem;
  overflow: hidden;
  border: 0.14286rem solid #f3f3f3;
  display: flex;
  flex-wrap: wrap;
  position: relative; }

.trackers-step {
  position: relative;
  padding: 4.28571rem 6.42857rem 4.28571rem 10.71429rem; }
  @media all and (max-width: 660px) {
    .trackers-step {
      padding: 2.14286rem 4.28571rem 2.14286rem 6.42857rem; } }
  @media all and (max-width: 440px) {
    .trackers-step {
      padding: 2.14286rem 2.14286rem 2.14286rem 6.42857rem; } }
  .trackers-step:before {
    content: '';
    position: absolute;
    left: 5.28571rem;
    top: 11.78571rem;
    width: 0.14286rem;
    height: calc( 100% - 8.57143rem);
    background: #f3f3f3; }
    @media all and (max-width: 660px) {
      .trackers-step:before {
        left: 3.14286rem;
        top: 8.57143rem; } }
  .trackers-step--last-step:before {
    display: none; }

.trackers-step__title {
  font-size: 1.14286rem;
  line-height: 2.14286rem;
  margin-bottom: 1.07143rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #8f8f8f; }

.trackers-step__text {
  font-size: 1rem;
  line-height: 1.42857rem;
  margin: 0 0 1.42857rem 0; }

.trackers-step__link {
  font-size: 1rem;
  line-height: 1.42857rem;
  display: inline-block;
  margin: 0;
  margin-right: 1.07143rem;
  padding: 1.07143rem;
  background: #364357; }
  .trackers-step__link:hover {
    background: #2e394a; }
  .trackers-step__link.-disable {
    background: #dadada; }
    .trackers-step__link.-disable:hover {
      background: silver; }
  .trackers-step__link.active {
    background: #364357; }
    .trackers-step__link.active:hover {
      background: #2e394a; }

.trackers-step__copied {
  color: #364357;
  opacity: 0;
  display: inline-block;
  margin: 1.07143rem 0;
  -webkit-animation: animCopied .3s ease-out forwards;
          animation: animCopied .3s ease-out forwards; }

@-webkit-keyframes animCopied {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes animCopied {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.trackers-script, .trackers-pixel {
  font-size: 1rem;
  line-height: 1.42857rem;
  padding: 1.07143rem;
  border-radius: 0.21429rem;
  margin: calc((1.07143rem * 4) / 3) 0;
  word-break: break-all; }

/*
	#CHECK CIRCLE
	#CHECK CIRCLE
	#CHECK CIRCLE
*/
.trackers-step-circle {
  position: absolute;
  top: 4.28571rem;
  left: 2.14286rem;
  width: 6.42857rem;
  height: 6.42857rem;
  line-height: 6.42857rem;
  text-align: center;
  border: 0.14286rem solid #f3f3f3;
  border-radius: 50%;
  font-size: 2.14286rem;
  font-weight: 700;
  color: #dadada;
  -webkit-transition: border 0s ease .3s;
  transition: border 0s ease .3s; }
  @media all and (max-width: 660px) {
    .trackers-step-circle {
      top: 2.14286rem;
      left: 1.07143rem;
      width: 4.28571rem;
      height: 4.28571rem;
      line-height: 4.28571rem; } }
  .trackers-step-circle:before {
    content: '';
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0) scale(0.5);
            transform: translate3d(-50%, -50%, 0) scale(0.5);
    background: #364357;
    opacity: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease; }
  .trackers-step-circle.-active {
    color: #ffffff;
    border-color: #364357; }
    .trackers-step-circle.-active:before {
      opacity: 1;
      -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
              transform: translate3d(-50%, -50%, 0) scale(1); }
    .trackers-step-circle.-active:before {
      -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
              transform: translate3d(-50%, -50%, 0) scale(1); }
    .trackers-step-circle.-active .trackers-step-circle__number {
      opacity: 0; }
    .trackers-step-circle.-active .trackers-step-circle__container {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); }
    .trackers-step-circle.-active .trackers-step-circle__container .svg .check {
      -webkit-animation: check .4s ease forwards;
              animation: check .4s ease forwards; }

.trackers-step-circle__container {
  position: relative;
  z-index: 1;
  display: inline-block;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }
  .trackers-step-circle__container .svg {
    position: absolute;
    z-index: 1;
    width: 1.71429rem;
    left: 50%;
    top: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0); }
    .trackers-step-circle__container .svg .check {
      stroke: #ffffff;
      fill: none;
      stroke-width: 8;
      stroke-dasharray: 400;
      stroke-dashoffset: 400; }

.trackers-step-circle__number {
  -webkit-transition: all .4s ease;
  transition: all .4s ease; }

/*
	#RULES
	#RULES
	#RULES
*/
.scoring-container .scoring-container__title {
  display: flex;
  margin: 0 0 25px 0; }
  .scoring-container .scoring-container__title input {
    width: 40%;
    background: #ffffff;
    margin: 0 0 0 auto;
    padding: 15px; }

.scoring-container__button {
  cursor: pointer;
  outline: none;
  display: inline-block;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  background: #364357;
  border: none;
  border-radius: 0.21429rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-size: 1.14286rem;
  line-height: 2.14286rem;
  padding: 1.07143rem calc(1.07143rem * 2);
  color: #ffffff;
  background: #364357;
  display: block;
  margin: calc(1.07143rem * 2) auto; }
  .scoring-container__button:hover {
    background: #2a3444; }
  .scoring-container__button .icon,
  .scoring-container__button svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.5rem;
    /*margin-left: rem(-4);*/ }

.scoring-list, .plugin-list, .plugin-config {
  background: #ffffff;
  border: 0.14286rem solid #f3f3f3;
  border-radius: 0.21429rem;
  margin-bottom: calc(1.07143rem * 2); }

.scoring-el, .plugin-el, .plugin-config-el {
  display: flex;
  border-bottom: 0.14286rem solid #f3f3f3;
  padding: calc(1.07143rem * 2);
  overflow: hidden; }
  .scoring-el:last-child, .plugin-el:last-child, .plugin-config-el:last-child {
    border-bottom: 0; }
  @media all and (max-width: 660px) {
    .scoring-el, .plugin-el, .plugin-config-el {
      padding: 1.07143rem; } }

.scoring-el__button, .plugin-el__button, .scoring-el__button-edit {
  cursor: pointer;
  outline: none;
  display: inline-block;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  background: #364357;
  border: none;
  border-radius: 0.21429rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-size: 1rem;
  line-height: 1.42857rem;
  padding: 1.07143rem;
  color: #a7a7a7;
  background: #ffffff;
  border: 0.14286rem solid #f3f3f3;
  line-height: 1.14286rem;
  width: auto;
  margin: auto; }
  .scoring-el__button:hover, .plugin-el__button:hover, .scoring-el__button-edit:hover {
    background: #2a3444; }
  .scoring-el__button .icon, .plugin-el__button .icon,
  .scoring-el__button svg,
  .plugin-el__button svg, .scoring-el__button-edit .icon,
  .scoring-el__button-edit svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.5rem;
    /*margin-left: rem(-4);*/ }
  .scoring-el__button:hover, .plugin-el__button:hover, .scoring-el__button-edit:hover {
    background: #fbfbfb;
    color: #a7a7a7; }
  .scoring-el__button.-active, .-active.plugin-el__button, .scoring-el__button-edit.-active {
    background: #f8f8f8; }
  .scoring-el__button:nth-child(2n+2), .plugin-el__button:nth-child(2n+2), .scoring-el__button-edit:nth-child(2n+2) {
    margin-left: 1.07143rem; }

.scoring-el__container, .plugin-el__container, .plugin-config-el__container {
  padding-left: 2.14286rem;
  flex-grow: 1;
  position: relative;
  z-index: 2;
  overflow: hidden; }
  @media all and (max-width: 660px) {
    .scoring-el__container, .plugin-el__container, .plugin-config-el__container {
      padding-left: 1.07143rem; } }

.scoring-el__title, .plugin-el__title, .plugin-config-el__title {
  font-size: 1.14286rem;
  line-height: 2.14286rem;
  margin-bottom: 1.07143rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #8f8f8f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media all and (max-width: 660px) {
    .scoring-el__title, .plugin-el__title, .plugin-config-el__title {
      margin-bottom: calc(1.07143rem / 3); } }

.scoring-el__description, .plugin-el__description, .plugin-config-el__description {
  font-size: 1rem;
  line-height: 1.42857rem; }

/*
	#RULESETS BUTTON
	#RULESETS BUTTON
	#RULESETS BUTTON
*/
.scoring-button {
  width: 6.42857rem;
  height: 6.42857rem;
  line-height: 6.42857rem;
  text-align: center;
  border: 0;
  background: transparent;
  font-size: 2.14286rem;
  font-weight: 700;
  color: #dadada;
  position: relative;
  z-index: 1; }
  @media all and (max-width: 660px) {
    .scoring-button {
      width: 4.28571rem;
      height: 4.28571rem;
      line-height: 4.28571rem; } }
  .scoring-button:before, .scoring-button:after {
    content: '';
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    background: #fbfbfb;
    border-radius: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0) scale(0);
            transform: translate3d(-50%, -50%, 0) scale(0);
    -webkit-transition: all .3s ease;
    transition: all .3s ease; }
  .scoring-button:after {
    background: #364357; }
  .scoring-button:hover:before {
    -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
            transform: translate3d(-50%, -50%, 0) scale(1); }
  .scoring-button:hover .scoring-button__check {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }
    .scoring-button:hover .scoring-button__check:before, .scoring-button:hover .scoring-button__check:after {
      opacity: 0; }
  .scoring-button:hover .scoring-button__check .svg .check {
    -webkit-animation: check .4s ease forwards;
            animation: check .4s ease forwards; }
  .scoring-button--checked:hover:before {
    -webkit-transition: all .6s ease-out;
    transition: all .6s ease-out;
    opacity: 0;
    -webkit-transform: translate3d(-50%, -50%, 0) scale(8);
            transform: translate3d(-50%, -50%, 0) scale(8); }
  .scoring-button--checked:before {
    opacity: 0;
    -webkit-transform: translate3d(-50%, -50%, 0) scale(5);
            transform: translate3d(-50%, -50%, 0) scale(5); }
  .scoring-button--checked:after {
    -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
            transform: translate3d(-50%, -50%, 0) scale(1); }
  .scoring-button--checked .scoring-button__check {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }
    .scoring-button--checked .scoring-button__check:before, .scoring-button--checked .scoring-button__check:after {
      opacity: 0; }
  .scoring-button--checked .scoring-button__check {
    -webkit-transition: border 0s ease .3s;
    transition: border 0s ease .3s;
    border: 0.14286rem solid #364357; }
  .scoring-button--checked .scoring-button__check .svg .check {
    stroke: #ffffff;
    -webkit-animation: check .4s ease forwards;
            animation: check .4s ease forwards; }

.scoring-button__check {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: inline-block;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 100%;
  height: 100%;
  border: 0.14286rem solid #f3f3f3;
  border-radius: 50%; }
  .scoring-button__check:before, .scoring-button__check:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1.42857rem;
    height: 0.14286rem;
    background: #dadada;
    -webkit-transition: all .3s ease;
    transition: all .3s ease; }
  .scoring-button__check:before {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
            transform: translate3d(-50%, -50%, 0) rotate(-45deg); }
  .scoring-button__check:after {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
            transform: translate3d(-50%, -50%, 0) rotate(45deg); }
  .scoring-button__check .svg {
    position: absolute;
    z-index: 1;
    width: 1.71429rem;
    left: 50%;
    top: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0); }
    .scoring-button__check .svg .check {
      stroke: #8f8f8f;
      fill: none;
      stroke-width: 8;
      stroke-dasharray: 400;
      stroke-dashoffset: 400; }

@-webkit-keyframes check {
  0% {
    stroke-dashoffset: -400; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes check {
  0% {
    stroke-dashoffset: -400; }
  100% {
    stroke-dashoffset: 0; } }

/*
	#RULES LIST
	#RULES LIST
	#RULES LIST
*/
.rules-list-container {
  background: #ffffff;
  margin: calc(1.07143rem * 2) 0;
  border: 0.14286rem solid #f3f3f3;
  border-radius: 0.21429rem; }

.rules-list-container__title {
  display: flex;
  align-items: center; }
  .rules-list-container__title .input-filters {
    width: 40%;
    padding: 15px;
    margin: 0 0 0 auto; }

.rules-list {
  counter-reset: number; }

.rules-list__el {
  list-style-type: none;
  counter-increment: number;
  display: flex;
  background: #ffffff;
  margin: calc(1.07143rem * 2);
  border: 0.14286rem solid #f3f3f3;
  border-radius: 0.21429rem; }
  @media all and (max-width: 660px) {
    .rules-list__el {
      margin: calc((1.07143rem * 4) / 3); } }
  .rules-list__el:before {
    content: counter(number);
    display: inline-block;
    min-width: 4.28571rem;
    min-height: 4.28571rem;
    border: 0.14286rem solid #f3f3f3;
    border-radius: 50%;
    line-height: 4.28571rem;
    text-align: center;
    font-size: 1.78571rem;
    color: #8f8f8f;
    background: #fdfdfd;
    margin: calc(1.07143rem * 2); }
    @media all and (max-width: 660px) {
      .rules-list__el:before {
        margin: 1.07143rem; } }

.rules-list__name {
  flex-grow: 1;
  padding: calc(1.07143rem * 2);
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media all and (max-width: 660px) {
    .rules-list__name {
      padding: 1.07143rem; } }
  @media all and (max-width: 440px) {
    .rules-list__name {
      padding: 0; } }

.rules-list__button {
  cursor: pointer;
  outline: none;
  display: inline-block;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  background: #364357;
  border: none;
  border-radius: 0.21429rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-size: 1rem;
  line-height: 1.42857rem;
  padding: 1.07143rem;
  color: #a7a7a7;
  background: #ffffff;
  border: 0.14286rem solid #f3f3f3;
  line-height: 1.14286rem;
  width: auto;
  margin: auto 2.14286rem; }
  .rules-list__button:hover {
    background: #2a3444; }
  .rules-list__button .icon,
  .rules-list__button svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.5rem;
    /*margin-left: rem(-4);*/ }
  .rules-list__button:hover {
    background: #fbfbfb;
    color: #a7a7a7; }
  .rules-list__button.-active {
    background: #f8f8f8; }
  @media all and (max-width: 660px) {
    .rules-list__button {
      margin: auto 1.07143rem; } }

.rules-list-grab, .segments-list-grab {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -webkit-grab;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4.28571rem;
  border-left: 0.14286rem solid #f3f3f3;
  background: #fdfdfd; }
  .rules-list-grab:active, .segments-list-grab:active {
    cursor: grabbing !important;
    cursor: -webkit-grabbing !important; }

.rules-list-grab__el, .segments-list-grab__el {
  position: relative;
  width: 1.28571rem;
  height: 0.28571rem;
  margin: 0.17857rem;
  border-radius: 0.14286rem;
  background: #e6e6e6; }
  .rules-list-grab__el:before, .segments-list-grab__el:before, .rules-list-grab__el:after, .segments-list-grab__el:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0.14286rem;
    background: #e6e6e6; }
  .rules-list-grab__el:before, .segments-list-grab__el:before {
    left: 0;
    top: 0;
    -webkit-transform: translate3d(0, -220%, 0);
            transform: translate3d(0, -220%, 0); }
  .rules-list-grab__el:after, .segments-list-grab__el:after {
    left: 0;
    bottom: 0;
    -webkit-transform: translate3d(0, 220%, 0);
            transform: translate3d(0, 220%, 0); }

.plugin-el {
  align-items: center; }

.plugin-el__img, .plugin-config-el__img {
  max-width: 8.57143rem; }
  @media all and (max-width: 1170px) {
    .plugin-el__img, .plugin-config-el__img {
      max-width: 7.14286rem; } }
  @media all and (max-width: 660px) {
    .plugin-el__img, .plugin-config-el__img {
      max-width: 5.71429rem; } }
  @media all and (max-width: 660px) {
    .plugin-el__img, .plugin-config-el__img {
      max-width: 2.85714rem; } }

.plugin-el__container, .plugin-config-el__container {
  overflow: visible;
  overflow: initial; }
  .plugin-el__container--interaction {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }

@media all and (max-width: 440px) {
  .plugin-el__title, .plugin-config-el__title {
    max-width: 100px; } }

.plugin-el__description, .plugin-config-el__description {
  overflow: hidden;
  height: 3.57143rem;
  text-overflow: ellipsis;
  overflow: hidden; }

.plugin-el__button {
  margin-right: 1.07143rem; }

/*
	#CHECKBOX
	#CHECKBOX
	#CHECKBOX
*/
.checkbox-plugin {
  margin: 0;
  padding: 0;
  min-height: 3.57143rem;
  min-width: 3.57143rem;
  line-height: 3.21429rem; }
  .checkbox-plugin span {
    margin: 0; }

/*
	#CONFIGURATION
	#CONFIGURATION
	#CONFIGURATION
*/
.plugin-config-el {
  align-items: center; }

@media all and (max-width: 660px) {
  .plugin-config-el__img {
    max-width: 5.71429rem; } }

/*
	#FORM-PLUGIN
	#FORM-PLUGIN
	#FORM-PLUGIN
*/
.form-plugin {
  width: 100%; }

.form-plugin__el {
  margin: 0 0;
  padding: calc((1.07143rem * 4) / 3) calc(1.07143rem * 2);
  padding-bottom: calc(1.07143rem * 2);
  border-bottom: 0.14286rem solid #f3f3f3; }
  .form-plugin__el:nth-child(2n+1) {
    background: #fdfdfd; }
  .form-plugin__el.-no-config {
    color: #8f8f8f;
    padding-bottom: calc((1.07143rem * 4) / 3);
    border-bottom: 0; }
    .form-plugin__el.-no-config svg {
      margin-bottom: -2px;
      margin-right: 7.5px; }

.form-plugin__input {
  background: #ffffff; }

.form-plugin__button {
  margin-left: 0;
  margin: calc(1.07143rem * 2); }

.identification-item-subcontainer {
  width: 100%; }

.form-identification__container {
  flex-direction: column;
  margin-bottom: 1.71429rem; }

.form-identification__description {
  margin: 1.71429rem 0; }

/*
	#TEAM
	#TEAM
	#TEAM
*/
.team-container, .segments-subcontainer {
  text-align: center; }

.team-list, .segments-list {
  background: #ffffff;
  border: 0.14286rem solid #f3f3f3;
  border-radius: 0.21429rem; }

.team-el, .segments-el {
  display: flex;
  border-bottom: 0.14286rem solid #f3f3f3;
  padding: calc(1.07143rem * 2);
  overflow: hidden;
  text-align: left; }
  @media all and (max-width: 440px) {
    .team-el, .segments-el {
      padding: calc((1.07143rem * 4) / 3); } }
  .team-el:last-child, .segments-el:last-child {
    border-bottom: 0; }

.team-el__container, .segments-el__container {
  flex-grow: 1;
  position: relative;
  z-index: 2; }

.team-el__name, .segments-el__name {
  font-size: 1.14286rem;
  line-height: 2.14286rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #8f8f8f; }

.team-el__email, .segments-el__description {
  font-size: 1.14286rem;
  line-height: 1.42857rem;
  color: #8f8f8f;
  margin: 0 0 0.35714rem 0; }

.team-el__since {
  font-size: 12;
  line-height: 1.42857rem;
  color: #cdcdcd; }
  .team-el__since:first-letter {
    text-transform: lowercase; }

.team-el__button, .segments-el__button {
  cursor: pointer;
  outline: none;
  display: inline-block;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  background: #364357;
  border: none;
  border-radius: 0.21429rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-size: 1rem;
  line-height: 1.42857rem;
  padding: 1.07143rem;
  color: #a7a7a7;
  background: #ffffff;
  border: 0.14286rem solid #f3f3f3;
  line-height: 1.14286rem;
  width: auto;
  margin: auto; }
  .team-el__button:hover, .segments-el__button:hover {
    background: #2a3444; }
  .team-el__button .icon, .segments-el__button .icon,
  .team-el__button svg,
  .segments-el__button svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.5rem;
    /*margin-left: rem(-4);*/ }
  .team-el__button:hover, .segments-el__button:hover {
    background: #fbfbfb;
    color: #a7a7a7; }
  .team-el__button.-active, .-active.segments-el__button {
    background: #f8f8f8; }

.button-team-add, .button-segments-add {
  cursor: pointer;
  outline: none;
  display: inline-block;
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  background: #364357;
  border: none;
  border-radius: 0.21429rem;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-size: 1.14286rem;
  line-height: 2.14286rem;
  padding: 1.07143rem calc(1.07143rem * 2);
  color: #ffffff;
  background: #364357;
  display: inline-block;
  margin: calc(1.07143rem * 2) auto; }
  .button-team-add:hover, .button-segments-add:hover {
    background: #2a3444; }
  .button-team-add .icon, .button-segments-add .icon,
  .button-team-add svg,
  .button-segments-add svg {
    margin-bottom: -0.14286rem;
    margin-right: 0.5rem;
    /*margin-left: rem(-4);*/ }

/*
	#CHECKBOX
	#CHECKBOX
	#CHECKBOX
*/
.checkbox-team-user {
  padding: 0.39286rem;
  padding-left: 0.64286rem;
  margin: calc(1.07143rem / 2);
  border-radius: 0.21429rem;
  text-align: center;
  border: 0.14286rem solid #f3f3f3;
  -webkit-transition: all .2s ease;
  transition: all .2s ease; }
  .checkbox-team-user:hover {
    background: #fbfbfb; }

.segments-el {
  /*border-left-width: rem(4);    border-left-style: solid;*/ }

.segments-el__container {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.segments-list-grab {
  border-left: none;
  margin-left: -2.14286rem;
  padding-left: 1.07143rem;
  padding-right: 1.07143rem;
  width: 6.85714rem; }

.segments-list-grab__el {
  width: 1.85714rem; }
  .segments-list-grab__el:before, .segments-list-grab__el:after {
    width: 1.85714rem; }

.actions-container .form-action {
  display: flex;
  width: 100%;
  flex-direction: column; }

.actions-container .action-panel {
  background-color: #ffffff;
  margin-bottom: 1.71429rem;
  width: 100%; }
  .actions-container .action-panel .action-inner-edit,
  .actions-container .action-panel .action-population-footer {
    padding: 1.14286rem 1.71429rem; }
  .actions-container .action-panel .action-parameters {
    padding-top: 2.14286rem; }

.query-builder .qb-drag-handler {
  display: none; }

.query-builder .ant-btn {
  border-color: #f3f3f3;
  padding: 0.28571rem 0.57143rem;
  font-size: 0.92857rem;
  height: auto;
  outline: none;
  box-shadow: none; }

.query-builder .ant-switch-checked {
  background-color: #364357; }

.query-builder .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #f3f3f3; }

.query-builder .ant-btn:focus:not([disabled]),
.query-builder .ant-btn:hover:not([disabled]) {
  color: #364357;
  border-color: #d4d4d4; }

.query-builder .ant-select-selection:hover {
  border-color: #d4d4d4; }

.query-builder .ant-input-number:hover {
  border-color: #d4d4d4; }

.query-builder .ant-btn-danger {
  color: #e55039; }
  .query-builder .ant-btn-danger:hover {
    background-color: inherit;
    color: #dd371d !important;
    border-color: #e55039 !important; }

.query-builder .ant-btn-primary:not([disabled]) {
  background-color: #364357 !important;
  color: #ffffff !important; }
  .query-builder .ant-btn-primary:not([disabled]):focus, .query-builder .ant-btn-primary:not([disabled]):hover {
    background-color: #364357 !important;
    color: #ffffff !important; }

.query-builder .ant-input-number-focused,
.query-builder .ant-select-focused .ant-select-selection {
  box-shadow: none; }

.query-builder .group--conjunctions .ant-btn-group .ant-btn:first-child {
  display: none; }

.query-builder .group--conjunctions .ant-btn-group .ant-btn:nth-child(2) {
  border-bottom-left-radius: 0.28571rem;
  border-top-left-radius: 0.28571rem; }

.query-builder .ant-btn,
.query-builder .ant-select-selection {
  border-radius: 0.28571rem; }

.query-builder .group--header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.85714rem; }

.query-builder .group-or-rule-container,
.query-builder .rule-container {
  border-left: 0.28571rem solid #f3f3f3;
  padding: 0.85714rem 1.14286rem;
  max-width: 100%;
  border-radius: 0.21429rem;
  margin-bottom: 0.85714rem;
  padding-right: 0; }
  .query-builder .group-or-rule-container .group-container,
  .query-builder .rule-container .group-container {
    border-color: #e4e4e4;
    background-color: white; }
  .query-builder .group-or-rule-container .rule-container,
  .query-builder .rule-container .rule-container {
    background-color: #fdfdfd;
    border-color: #364357; }

.query-builder .widget--sep {
  text-align: center;
  padding: 0.42857rem; }
  .query-builder .widget--sep span {
    font-size: 0.78571rem;
    font-weight: bold;
    color: #a3a3a3;
    text-transform: uppercase; }

.query-builder .group-or-rule.rule {
  display: flex;
  flex-direction: row;
  width: 100%; }
  .query-builder .group-or-rule.rule .rule--field,
  .query-builder .group-or-rule.rule .rule--operator,
  .query-builder .group-or-rule.rule .rule--value {
    padding: 0.28571rem; }

.query-builder .rule--header {
  order: 999999;
  align-self: center;
  justify-self: right;
  margin-left: auto;
  padding: 0 0.57143rem; }
  .query-builder .rule--header button {
    border: none;
    background: none;
    padding: 0;
    margin: 0 !important !important;
    padding: 0.57143rem !important !important; }

.querybuilder-statistics .inner-statistics {
  background-color: darkgray;
  color: #ffffff;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .querybuilder-statistics .inner-statistics .query {
    font-size: 0.78571rem; }
  .querybuilder-statistics .inner-statistics span {
    font-weight: bold;
    -webkit-transition: opacity 0.75s;
    transition: opacity 0.75s; }
  .querybuilder-statistics .inner-statistics.segment-population-stats {
    background-color: #fbfbfb;
    color: #8f8f8f;
    padding: 0.57143rem 1.14286rem;
    border-radius: 0.14286rem; }

.dashboard-panel {
  display: flex;
  flex-wrap: wrap;
  padding: 1.07143rem; }
  .dashboard-panel .widget {
    padding: 1.07143rem; }
    .dashboard-panel .widget.widget-datepicker {
      width: 30%; }
    .dashboard-panel .widget.widget-counters {
      width: 70%; }
    .dashboard-panel .widget.widget-livefeed {
      width: 25%;
      height: 400px; }
  .dashboard-panel .dashboard-el__widget--livefeed-widget {
    display: flex;
    overflow: hidden;
    flex-direction: column; }
    .dashboard-panel .dashboard-el__widget--livefeed-widget .livefeed-event {
      padding-top: 1.07143rem;
      padding-bottom: 1.07143rem;
      border-bottom: 1px solid #f3f3f3;
      display: flex;
      align-items: center;
      width: 100%; }
      .dashboard-panel .dashboard-el__widget--livefeed-widget .livefeed-event .moment {
        font-size: 0.85714rem;
        width: 52px; }
      .dashboard-panel .dashboard-el__widget--livefeed-widget .livefeed-event .name {
        margin-right: 1.07143rem;
        margin-left: 1.07143rem; }
      .dashboard-panel .dashboard-el__widget--livefeed-widget .livefeed-event .data {
        font-weight: bold;
        align-self: right; }
  .dashboard-panel .dashboard-el__widget,
  .dashboard-panel .rdrDateRangePickerWrapper,
  .dashboard-panel .rdrDateRangePickerWrapper .rdrCalendarWrapper,
  .dashboard-panel .rdrDateRangePickerWrapper .rdrCalendarWrapper .rdrMonth {
    width: 100%;
    height: 100%; }
  .dashboard-panel .dashboard-el__widget {
    border: 0.14286rem solid #f0f0f0;
    border-radius: 0.35714rem;
    background: #ffffff;
    padding: 2.14286rem;
    height: 100%;
    display: flex;
    align-items: center; }
  .dashboard-panel .datepicker-filters {
    margin: 0; }
  .dashboard-panel .container-list-filters {
    width: 400px; }
  .dashboard-panel .rdrDefinedRangesWrapper {
    display: none; }

.footer {
  text-align: center;
  color: #c2c2c2;
  font-weight: 500;
  padding: 4.57143rem 0; }
  .footer i {
    font-style: italic; }

