
/*
	#CATEGORIE
	#CATEGORIE
	#CATEGORIE
*/
.tags-tag-list{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: $gutterMidSmall;

	background: $white;
	border-radius: $borderRadius;
	border-bottom: 0;	
	margin: 0 auto; margin-bottom: $gutterBig;
	border-radius: $borderRadius;
	border: $border;

	padding: $gutterMidSmall;
	@include mq(little-mobile){
		padding: cacl(#{$gutterMidBig} / 2 );
	}
}
	.tags-tag-list__button, .tags-tag-list__item{
		display: flex;
		margin: $gutterMidSmall;
		padding: calc( #{$gutterSmall} * 2);
		background: $grey;
		border-radius: $borderRadius;
		border: none;
		font-size: rem(14);
		font-weight: 500;
		
		cursor: pointer;
		transition: all .2s ease;
		&:hover{
			background: darken($grey, 5%);
		}
	}
	.tags-tag-list__button{
		outline: none;
		cursor: pointer;
		text-transform: uppercase;
		background: $contrastColor;
		color: $white;
		font-weight: 700;
		line-height: rem(15);
		&:hover{ background: darken($contrastColor, 4%);}
	}
	.tags-tag-list__color{
		width: rem(15); height: rem(15);
		margin-right: rem(7.5);
		border-radius: 50%;
	}
	.tags-tag-list__name{
		margin-right: rem(7.5);
	}
	.tags-tag-list__score{
		font-weight: 400;
	}
/*
	#FUCK
	#FUCK
	#FUCK
*/
// .leads-scores-user--config{
// 	background: $white;
// 	border-radius: $borderRadius;
// 	border-bottom: 0;	
// 	margin: $gutterBig auto;
// 	border-radius: $borderRadius;
// 	border: $border;

// 	padding: $gutterMidSmall;
// 	@include mq(little-mobile){
// 		padding: cacl(#{$gutterMidBig} / 2 );
// 	}
// }
	// .leads-scores-user__el{
	// 	cursor: pointer;

	// 	transition: all .2s ease;
	// 	&:hover{
	// 		background: darken($grey, 5%);
	// 		.leads-scores-user__color-el{transform: scale(1.5);}
			
	// 	}
	// }
