// https://fonts.google.com/
// @import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@import url('https://fonts.googleapis.com/css?family=Roboto:100,400,500,700');




@import '~react-toastify/dist/ReactToastify.css';

/*
  #START MIXIN
  #START MIXIN
  #START MIXIN
*/
@function em($pixels, $context: $browser-context) {
  @return ($pixels / $context) * 1em;
}

@function rem($pixels, $context: $browser-context) {
  @return ($pixels / $context) * 1rem;
}

/*
  #VARIABLE
  #VARIABLE
  #VARIABLE
*/
$browser-context: 14;
$black: #000000;
$greyInitial: #F3F3F3;
$grey:darken($greyInitial, 0%);
$greyLight: #F9F9FC; //F9F9FB
$greyDark: #8f8f8f;
$white: #ffffff;

$green: #339919;
$red: #D8000C;
$red: #e55039;
$red2:#b71540;
// $yellow:;

$yellow:#f6b93b;


$loaderColor:#4a69bd;

$contrastColor: #30415d;
$contrastColor: #364357;
//$contrastColor:#3F51B5;

//$contrastColor:#2b3d86;

$contractColor2: #e55039;


$contrastColorDark:darken($contrastColor, 6%);

$contrastColorDeclined:darken(desaturate($contrastColor, 20%), 4%);

//@at-root$greenDark:#270;
$greenLight:#DFF2BF;

/*
  #ERROR-MESSAGE
  #ERROR-MESSAGE
  #ERROR-MESSAGE
*/

//ErrorColor
$contrastColorLight:#BEF;
//OrangeColor
$orangeDark:#9F6000;
$orangeLight:#FEEFB3;
//RedColor
$redDark:#D8000C;
$redLight:#FFBABA;


$twitter: #55acee !default;
$facebook: #3b5998 !default;
$youtube: #cd201f !default;
$linkedin: #3b5998 !default;
$instagram: #ed4956 !default;

//GUTTER
$gutter: rem(15); //1.0714285714rem; //rem(15);
//BIG
$gutterMidBig: calc((#{$gutter} * 4) / 3); //1.4285714286rem; //rem(20);
$gutterMidBigNeg: calc((#{$gutter} * -4) / 3);
$gutterBig: calc(#{$gutter} * 2); //2.1428571429rem; //rem(30);
//SMALL
$gutterMidSmall: calc(#{$gutter} / 2); //0.5357142857rem; //rem(7.5);
$gutterMidSmallNeg: calc(#{$gutter} / -2); //0.5357142857rem; //rem(7.5);
$gutterSmall: calc(#{$gutter} / 3); //0.3571428571rem; //rem(5);

$border: rem(2) solid $grey;
$borderRadius: rem(3); //0.3571428571rem;//rem(5)

$paddingStickyBox: rem(31);

.container-adjustement {
  margin-top: rem(-46);
}

$shadow : 0 5px 5px 0 rgba(0, 0, 0, 0.03);
$shadowFar : 0 10px 20px 0 rgba(0, 0, 0, 0.05);
$shadowStrong : 0 5px 10px 0 rgba(0, 0, 0, 0.20);

$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
/*
    #BREAKPOINTS
    #BREAKPOINTS
    #BREAKPOINTS
*/
$breakpoints: (big-screen:"(min-width:1600px)",
  desktop: "(max-width:1300px)",
  tablet: "(max-width:1170px)",
  little-tablet: "(max-width:800px)",
  mobile: "(max-width:660px)",
  little-mobile: "(max-width:440px)"
) !default;

/*
  #MIXIN
  #MIXIN
  #MIXIN
*/
@mixin box-sizing($property: border-box) {
  -webkit-box-sizing: $property;
  -moz-box-sizing: $property;
  box-sizing: $property;
}

@mixin mq($breakpoint-name) {
  $breakpoint-name: unquote($breakpoint-name);

  @if map-has-key($breakpoints, $breakpoint-name) {
    $query: map-get($breakpoints, $breakpoint-name);

    @media all and #{$query} {
      @content;
    }
  }

  @else {
    @warn "#{$mq-name} is not a value defined in the 'breakpoints' map.";
  }
}

@mixin background-2x($path, $ext: "png", $w: auto, $pos: left top, $repeat: no-repeat) {

  $at1x_path: "#{$path}.#{$ext}";
  $at2x_path: "#{$path}@2x.#{$ext}";

  background-image: url("#{$at1x_path}");
  background-size: $w;
  background-position: $pos;
  background-repeat: $repeat;

  @media all and (-webkit-min-device-pixel-ratio : 1.5),
  all and (-o-min-device-pixel-ratio: 3/2),
  all and (min--moz-device-pixel-ratio: 1.5),
  all and (min-device-pixel-ratio: 1.5) {
    background-image: url("#{$at2x_path}");
    background-size: $w;
    background-position: $pos;
    background-repeat: $repeat;
  }
}

@mixin triangle($dir, $color, $width, $height) {
  width: 0;
  height: 0;
  border-style: solid;

  @if $dir==top {
    border-width: 0 $width $height $width;
    border-color: transparent transparent $color transparent
  }

  @else if $dir==bottom {
    border-width: $height $width 0 $width;
    border-color: $color transparent transparent transparent
  }

  @else if $dir==left {
    border-width: $height $width $height 0;
    border-color: transparent $color transparent transparent
  }

  @else if $dir==right {
    border-width: $height 0 $height $width;
    border-color: transparent transparent transparent $color
  }
}

@mixin bg-gradient($angle: 180deg, $color: #0bd, $calibration:adjust-hue, $amount: 20%, $fixed: fixed) {
  @if $calibration==lighten {
    background: linear-gradient($angle, $color, lighten($color, $amount)) $fixed;
  }

  @else if $calibration==darken {
    background: linear-gradient($angle, $color, darken($color, $amount)) $fixed;
  }

  @else if $calibration==saturate {
    background: linear-gradient($angle, $color, saturate($color, $amount)) $fixed;
  }

  @else if $calibration==desaturate {
    background: linear-gradient($angle, $color, desaturate($color, $amount)) $fixed;
  }

  @else if $calibration==adjust-hue {
    background: linear-gradient($angle, $color, adjust-hue($color, $amount)) $fixed;
  }

  @else if $calibration==rgba {
    background: linear-gradient($angle, $color, rgba($color, $amount)) $fixed;
  }
}

// https://robots.thoughtbot.com/controlling-color-with-sass-color-functions
// https://glennmccomb.com/articles/creating-smooth-sequential-animations-with-sass/
* {
  @include box-sizing;
}

html {
  // @include mq(big-screen){ font-size: 16px; }
  font-size: 14px;

  @include mq(little-mobile) {
    font-size: 12px;
  }
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;

  font-family: 'Roboto',
    sans-serif;


  font-size: rem(14);
  // font-size: 14px;
  // @include mq(big-screen){font-size: 16px;}
  background: $white;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: lighten($grey, 2%);
}

/*
  #%
  #%
  #%
*/
%label {
  display: block;
  font-size: rem(16);
  padding-bottom: $gutter;
}

%input {
  // position: relative; z-index: 1;
  width: 100%;
  font-size: rem(14);
  line-height: rem(20);
  outline: none;
  border: $border;
  background: transparent;
  border-radius: $borderRadius;
  padding: $gutter;
  padding-left: calc(#{$gutterSmall} * 7);

  &:hover {
    border-color: darken($grey, 4%);
  }

}

%button {
  outline: none;
  cursor: pointer;
  font-size: rem(16);
  line-height: rem(30);
  padding: $gutter $gutterBig;
  margin: $gutterMidBig 0;
  font-weight: 700;
  color: $white;
  text-decoration: none;
  text-transform: uppercase;
  background: $contrastColor;
  border: none;
  border-radius: $borderRadius;
  transition: all .2s ease;

  &:hover {
    background: darken($contrastColor, 5%);
  }

  svg {
    margin-bottom: rem(-2);
    margin-right: rem(7);
    /*margin-left: rem(-4);*/
  }

  &--light {
    display: block;
    width: 100%;
    font-size: rem(14);
    line-height: rem(20);
    padding: $gutter;
    margin: 0;
    background: $white;
    color: darken($grey, 30%);
    border: $border;

    &:hover {
      background: lighten($grey, 3%);
    }

    svg {
      margin-bottom: rem(-1);
      margin-right: rem(7);
    }

    &.-active {
      background: lighten($grey, 2%);
    }
  }

}

/*
  #MIXIN-BIG
  #MIXIN-BIG
  #MIXIN-BIG
*/
@mixin button-default($size: big, $theme: color) {
  cursor: pointer;
  outline: none;
  display: inline-block;
  font-weight: 700;
  color: $white;
  text-decoration: none;
  text-transform: uppercase;
  background: $contrastColor;
  border: none;
  border-radius: $borderRadius;
  transition: all .2s ease;

  // .icon, svg{  margin-bottom: rem(-1); margin-right: rem(7);  }
  &:hover {
    background: darken($contrastColor, 6%);
  }

  .icon,
  svg {
    margin-bottom: rem(-2);
    margin-right: rem(7);
    /*margin-left: rem(-4);*/
  }

  @if $size==big {
    font-size: rem(16);
    line-height: rem(30);
    padding: $gutter $gutterBig;

    @if $theme==color {
      color: $white;
      background: $contrastColor;

    }

    @else if $theme==light {
      color: darken($grey, 30%);
      background: $white;
      border: $border;
      line-height: rem(26);

      &:hover {
        background: lighten($grey, 3%);
      }

      &.-active {
        background: lighten($grey, 2%);
      }
    }

  }

  @else if $size==medium {
    font-size: rem(14);
    line-height: rem(20);
    padding: $gutter;

    @if $theme==color {
      color: $white;
      background: $contrastColor;
    }

    @else if $theme==light {
      color: darken($grey, 30%);
      background: $white;
      border: $border;
      line-height: rem(16);

      &:hover {
        background: lighten($grey, 3%);
        color: darken($grey, 30%);
      }

      &.-active {
        background: lighten($grey, 2%);
      }
    }

  }

  @else if $size==small {
    font-size: rem(14);
    line-height: rem(20);
    padding: $gutterMidSmall;

    @if $theme==color {
      color: $white;
      background: $contrastColor;
    }

    @else if $theme==light {
      color: darken($grey, 30%);
      background: $white;
      border: $border;
      line-height: rem(16);

      &:hover {
        background: lighten($grey, 3%);
      }

      &.-active {
        background: lighten($grey, 2%);
      }
    }

  }
}


@mixin separator-default($background: white, $size: medium) {
  $separatorHeight: 24;
  $separatorSpacing: 24;

  @if $size==big {
    $separatorHeight: 56;
  }

  @if $size==medium {
    $separatorHeight: 36;
  }

  color:$greyDark;
  display:flex;
  height:rem($separatorHeight);
  position:relative;
  align-items:center;
  flex-direction: column;

  &:before {
    border-bottom: 1px solid $grey;
    max-width: 100%;
    width: rem(300);
    content: '';
    display: block;
    position: absolute;
    top: rem($separatorHeight/2);
    z-index: 1;
  }

  span {
    background-color: $background;
    display: block;
    z-index: 2;
    padding: 0 rem($separatorSpacing);
    line-height: rem($separatorHeight);
    height: rem($separatorHeight);
  }
}

#nprogress {
  .bar {
    background: $contrastColor;
    box-shadow: none;
  }

  .spinner-icon {
    border-left-color: $contrastColor;
    border-top-color: $contrastColor;
  }

  .peg {
    box-shadow: none;
  }
}

/*
  #BREADCRUMBS
  #BREADCRUMBS
  #BREADCRUMBS
*/
.default-container {
  svg {
    //shame scss
    margin-top: rem(30) !important;
    color: darken($grey, 2%);
  }
}

.breadcrumbs {
  position: fixed;
  z-index: 2;
  top: 0;
  width: 100%;
  background: lighten($grey, 2%);
  //background: linear-gradient(180deg, rgba(lighten($grey, 2%), 1) 40%, rgba(lighten($grey, 2%), 0)) 100%;
}

.breadcrumb {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: $gutter 0;
  // border: $border;

  li {
    margin: 0 ($gutter / 2);
    color: $contrastColor;

    &:first-child {
      margin-left: $gutter;
    }

    &:after {
      content: '';
      display: inline-block;
      width: rem(6);
      height: rem(6);
      border-top: rem(2) solid darken($grey, 20%);
      border-right: rem(2) solid darken($grey, 20%);
      transform: rotate(45deg);
      margin-left: $gutter;
    }

    font-size: rem(14);

    &.active {
      font-weight: 500;

      &:after {
        display: none;
      }
    }

  }

  li>a {
    text-decoration: none;
    color: $greyDark;
  }
}

/*
  #MESSAGEPOPUP
  #MESSAGEPOPUP
  #MESSAGEPOPUP
*/
.app-messages {
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: calc(241px - #{$gutter});
  width: calc(100% - 241px);

  &.-collapse {
    width: calc(100% - 105px);
    left:calc(105px - #{$gutter});

    @include mq(mobile) {
      width: calc(100% - (#{$gutter} * 2));
      left: $gutter;
      bottom: rem(77);
    }

    @include mq(little-mobile) {
      width: calc(100% - (#{$gutterMidSmall} * 2));
      left: $gutterMidSmall;
      bottom: rem(54);
    }
  }

  @include mq(tablet) {
    width: calc(100% - 105px);
    left:calc(105px - #{$gutter});
  }

  @include mq(mobile) {
    width: calc(100% - (#{$gutter} * 2));
    left: $gutter;
    bottom: rem(77);
  }

  @include mq(little-mobile) {
    width: calc(100% - (#{$gutterMidSmall} * 2));
    left: $gutterMidSmall;
    bottom: rem(54);
  }
}

@keyframes massagePopupAnim {
  0% {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }

  40% {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }

  100% {
    opacity: 1;
  }
}

@keyframes bubblePopupAnim {
  0% {
    transform: translate3d(-50%, -50%, 0) scale(0);
    opacity: 1;
  }

  100% {
    transform: translate3d(-50%, -50%, 0) scale(1);
    opacity: 0;
  }
}

@keyframes copyPopupAnim {
  0% {
    transform: translate3d(0, -7.5px, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes crossPopupAnim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.message-popup {
  position: relative;
  width: 54px;
  height: rem(60);
  background: $white;
  border-radius: $borderRadius;
  margin: $gutter 0;
  display: none;
  align-items: center;
  overflow: hidden;
  opacity: 0;
  transition: all .3s ease;

  // &:nth-child(1){ bottom: calc( #{$gutter} / 1.2);};
  &.-active {
    display: flex;
    animation: massagePopupAnim 1s ease-out forwards;
    // width: calc(100% - 241px);
    width: 100%;

    // @include mq(tablet){ width: calc(100% - 105px); left:calc(105px - #{$gutter});}
    // @include mq(mobile){ width: calc(100% - (#{$gutter} * 2)); left: $gutter; bottom: rem(92);}
    @include mq(little-mobile) {
      height: rem(98);
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: rem(640);
      height: rem(640);
      border-radius: 50%;
      background: rgba(#270, 0.2);

      transform: translate3d(-50%, -50%, 0) scale(0);
      animation: bubblePopupAnim 2s ease forwards;
    }
  }

  // @include mq(tablet){ left:calc(105px - #{$gutter}); animation: massagePopupAnimForSmallTab 1s ease-out 1s forwards; }
  // @include mq(mobile){ left: $gutter; bottom: rem(90); animation: massagePopupAnimForMobile 1s ease-out 1s forwards; }
  // @include mq(little-mobile){ height: rem(98); left: $gutter; bottom: rem(75); animation: massagePopupAnimForMobile 1s ease-out 1s forwards; }

  &--infos {
    color: #059;
    background-color: rgba(#BEF, 0.8);

    &:before {
      background: rgba(#059, 0.2)
    }

    .message-popup__cross:before,
    .message-popup__cross:after {
      background: #059;
    }
  }

  &--success {
    color: #270;
    background-color: rgba(#DFF2BF, 0.8);

    &:before {
      background: rgba(#270, 0.2)
    }

    .message-popup__cross:before,
    .message-popup__cross:after {
      background: #270;
    }
  }

  &--warning {
    color: #9F6000;
    background-color: rgba(#FEEFB3, 0.8);

    &:before {
      background: rgba(#9F6000, 0.2)
    }

    .message-popup__cross:before,
    .message-popup__cross:after {
      background: #9F6000;
    }
  }

  &--error {
    color: #D8000C;
    background-color: rgba(#FFBABA, 0.8);

    &:before {
      background: rgba(#D8000C, 0.2)
    }

    .message-popup__cross:before,
    .message-popup__cross:after {
      background: #D8000C;
    }
  }
}

.message-popup__icon {
  display: inline-block;
  margin: 0 $gutter 0 $gutterMidBig;
  margin-bottom: rem(-2);
}

.message-popup__copy {
  display: inline-block;
  text-align: left;
  padding: $gutter rem(50);
  font-size: rem(14);
  // color: $greyDark;
  // display: none;
  opacity: 0;
  position: absolute;

  animation: copyPopupAnim .5s ease-out .5s forwards;
}

// .message-popup__cross{
//   padding: $gutter;
// }
.message-popup__cross {
  position: absolute;
  right: $gutter;
  top: 50%;
  width: rem(25);
  height: rem(25);
  border: none;
  background: transparent;
  transform: translate3d(0, -50%, 0);
  opacity: 0;

  animation: crossPopupAnim .5s ease-out .5s forwards;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: rem(1);
    background: $black;
  }

  &:before {
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
  }

  &:after {
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  }
}




$sidebarMenuActiveColor: darken($contrastColor, 5);
$sidebarMenuArrowSize:12;

%extended-panels-container {
  display: flex;
}

%extended-panels-sidebar {
  width: rem(370);
  height: 100%;
  z-index: 3;
}

%extended-panels-edit {
  width: 100%;
  max-width: rem(900);
  display: flex;
  z-index: 2;
  margin-left: rem(24);
}

%extended-panels-item {
  color: black;
  padding: rem(18) rem(18);
  text-decoration: none;
  background-color: $white;
  display: flex;
  flex-direction: column;
  font-size: rem(14);
  margin-bottom: rem(1);

  span.description {
    color: $greyDark;
    margin-top: rem(12);
  }

  &.-active {
    color: $white;
    background-color: $sidebarMenuActiveColor;
    position: relative;

    &:after {
      content: '';
      width: rem($sidebarMenuArrowSize);
      height: rem($sidebarMenuArrowSize);
      background-color: $sidebarMenuActiveColor;
      position: absolute;
      right: rem(-($sidebarMenuArrowSize/3));
      top: rem($sidebarMenuArrowSize/4*3);
      transform: rotate(45deg);
    }

    span.description {
      color: $white;
    }
  }

  &.-item-new {
    font-style: italic;
  }
}













.color-indicator {
  width: $gutter;
  height: $gutter;
  border-radius: $gutter/2;
  display: inline-block;
  margin-right: $gutter/2;
  vertical-align: middle;
}













@keyframes circleLoader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes circleLoader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.circleLoader {
  position: relative;
  margin: rem(36) auto;
}

.circleLoader div {
  position: absolute;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  border: 12px solid #000;
  border-color: $loaderColor transparent $loaderColor transparent;
  -webkit-animation: circleLoader 1s linear infinite;
  animation: circleLoader 1s linear infinite;
}

.circleLoader {
  width: 84px !important;
  height: 84px !important;
  -webkit-transform: translate(-42px, -42px) scale(0.42) translate(42px, 42px);
  transform: translate(-42px, -42px) scale(0.42) translate(42px, 42px);
}













.form-config {
  .input-suggestions {
    width: 100%;
    overflow: hidden;
    padding-bottom: $gutter/2;
    padding-top: $gutter/2;
    position: relative;
    display: flex;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 80px;
      height: 100%;
      top: 0;
      bottom: 0;
      right: 0;

      @include bg-gradient(-90deg, white, rgba, 0, scroll);

    }

    a,
    button {
      color: lighten($greyDark, 7%);

      &:hover {
        color: $greyDark;
      }

      margin-right:$gutter/2;
      border:1px solid $grey;
      border-radius:4px;
      padding:$gutter/4 $gutter/2;
    }
  }
}