/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
//   border: 2px solid $green;
//   -webkit-text-fill-color: $green;
//   background: transparent !important;
//   -webkit-box-shadow: 0 0 0px 1000px $red inset;
//   transition: background-color 5000s ease-in-out 0s;
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
}
@keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}






$radioSize: 18px;
$radioBorder: darken($grey,12);
$radioActive: $contrastColor;

.form-default__radio {
    margin: 16px 0;
    display: block;
    cursor: pointer;
    input {
        display: none;
        & + span {
            line-height: $radioSize;
            height: $radioSize;
            padding-left: $radioSize;
            display: block;
            position: relative;
            &:not(:empty) {
                padding-left: $radioSize + 8;
            }
            &:before,
            &:after {
                content: '';
                width: $radioSize;
                height: $radioSize;
                display: block;
                border-radius: 50%;
                left: 0;
                top: 0;
                position: absolute;
            }
            &:before {
                background: $radioBorder;
                transition: background .2s ease, transform .4s cubic-bezier(.175, .885, .32, 2);
            }
            &:after {
                background: #fff;
                transform: scale(.78);
                transition: transform .6s cubic-bezier(.175, .885, .32, 1.4);
            }
        }
        &:disabled + span{
          opacity:.67;
          cursor:default;
        }
        &:checked + span {
            &:before {
                transform: scale(1.04);
                background: $radioActive;
            }
            &:after {
                transform: scale(.4);
                transition: transform .3s ease;
            }
        }
    }
    &:hover {
        input {
            & + span {
                &:before {
                    //transform: scale(.92);
                }
                &:after {
                    transform: scale(.74);
                }
            }
            &:checked + span {
                &:after {
                    transform: scale(.4);
                }
            }
        }
    }
}
