@keyframes blink {
	0% {
		transform: rotate(0deg);
	}

	5% {
		transform: rotate(22.5deg);
	}

	15% {
		transform: rotate(-20deg);
	}

	25% {
		transform: rotate(10deg);
	}

	35% {
		transform: rotate(-7.5deg);
	}

	45% {
		transform: rotate(5deg);
	}

	55% {
		transform: rotate(-2.5deg);
	}

	60% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

/*
  #GRID
  #GRID
  #GRID
*/
.app-inner {
	display: flex;

	&.-collapse {
		.nav-aside-right {
			width: rem(84);
		}

		.nav-button-collapse {

			&:after,
			&:before {
				transform-origin: right center;
			}
		}
	}

	main.main-container {
		flex-grow: 1;
		padding-top: rem(46);

		@include mq(tablet) {
			max-width: calc(100vw - #{rem(168)});
		}

		@include mq(mobile) {
			max-width: 100vw;
			padding-top: rem(100);
		}
	}
}

/*
	#SIDEBAR
	#SIDEBAR
	#SIDEBAR
*/
// .app-inner{
// }
// .sidebar-inner, .main-container{
// 	//shame sticky
// 	// margin-left: rem(305);
// 	// @include mq(tablet){
// 	// 	margin-left: rem(84);
// 	// }
// }
// .sidebar-inner{
// 	// background: darken($grey, 2%);
// 	@include mq (mobile){
// 		// background: $white;
// 		//shame css
// 		padding: 0 !important;
// 		// border-top: $border;
// 	}
// }

.aside {
	position: relative;
	z-index: 10;
}

// .aside{
// 	// min-width: rem(168);
// 	height: 100vh;
// 	position: fixed; z-index: 99;
// 	left: 0; top: 0;
// 	// border-right: $border;
// 	// transition: all .3s ease;
// 	// @include mq(tablet){ min-width: rem(75); }
// 	// @include mq(mobile){
// 	// 	position: fixed; 	z-index: 20;
// 	// 	left: 0; bottom: 0;
// 	// 	width: 100%;
// 	// 	border-right: 0;
// 	// }
// }

.nav-aside-container {
	padding: 0;
	// margin-top: rem(-3);
	display: flex;
	flex-direction: row;
	// flex-wrap: wrap;
}

.nav-aside-left {
	height: 100vh;
	max-width: rem(84);
	background: darken($contrastColor, 13%);

	background: linear-gradient($contrastColorDeclined, darken($contrastColor, 13%));

	padding: $gutterMidSmall $gutter;
	display: flex;
	flex-direction: column;

	// @include mq(tablet){
	// 	//COLLAPSED
	// 	// .nav-aside-left{
	// 	background: darken($grey, 2%);
	// 	.link-aside-left{
	// 		background: darken($grey, 10%);
	// 		svg, .icon{ color: $greyDark;}
	// 	}
	// }
	@include mq(mobile) {
		flex-direction: row;
		position: fixed;
		z-index: 999;
		left: 0;
		top: 0;
		max-width: 100%;
		width: 100%;
		height: auto;
	}
}

.nav-aside-left__el {
	// padding: $gutterSmall $gutter;
	position: relative;

	&--flex-grow {
		flex-grow: 1;
	}
}

.nav-aside-right {
	position: relative;
	display: flex;
	flex-direction: column;
	list-style: none;
	margin: 0;
	padding: $gutter 0;
	background: darken($grey, 2%);
	width: rem(220);
	transition: all 0.3s ease;

	@include mq(tablet) {
		// 	//COLLAPSED
		// 	// .nav-aside-right{
		width: rem(84);
		// position: fixed;
		// left: 0; top: 64px;

		.link-aside-right__copy {
			display: none;

		}
	}

	@include mq(mobile) {
		position: fixed;
		z-index: 999;
		left: 0;
		bottom: 0;
		width: 100% !important;
		height: auto;
		background: $white;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		padding: $gutterMidSmall 0;
	}

	&.-hide {
		display: none;
	}
}















// .nav-aside-right__el{}
.nav-aside-right__el-collapse {
	padding: 0;
	margin: 0;
	cursor: pointer;
	position: absolute;
	z-index: 9;
	right: calc(-#{$gutter} + #{rem(2)});
	top: 0;
	width: $gutter;
	height: 100vh;
	transform: translate(-100%, 0, 0);
	border-left: $border;
	border-color: $contrastColor;
	display: flex;
	align-items: center;
	opacity: 0;
	transition: all 1s ease;

	&:hover {
		opacity: 1;

		.nav-button-collapse {
			&:before {
				transform: translate3d(0, -50%, 0) rotate(45deg);
			}

			&:after {
				transform: translate3d(0, -50%, 0) rotate(-45deg);
			}
		}
	}

	@include mq(tablet) {
		display: none;

	}
}

.nav-button-collapse {
	position: relative;
	display: block;
	border: none;
	padding: 0;
	min-width: rem(14);
	min-height: rem(30);
	color: $black;
	text-align: right;
	background: none;

	&:after,
	&:before {
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		width: $gutterMidSmall;
		height: rem(2);
		background: $contrastColor;
		transition: all 0.2s ease;
	}

	&:before {
		transform-origin: left center;
		transform: translate3d(0, -50%, 0) rotate(90deg);
	}

	&:after {
		transform-origin: left center;
		transform: translate3d(0, -50%, 0) rotate(-90deg);
	}
}

/*
	#NAV-LINK-ASIDE
	#NAV-LINK-ASIDE
	#NAV-LINK-ASIDE
*/
// .nav-link-aside{

.link-aside-right {
	cursor: pointer;
	display: block;
	position: relative;
	font-size: rem(14);
	line-height: rem(20);
	// padding: calc( #{$gutterBig} - #{$gutterSmall}) ;
	padding: $gutter;
	margin: 0 $gutter rem(4);
	font-weight: 400;
	text-decoration: none;
	color: $black;
	text-decoration: none;
	white-space: nowrap;
	overflow: hidden;
	transition: all 0.2s ease;
	border: 0;
	background: transparent;
	border-radius: $borderRadius;

	font-weight: 500;
	color: darken($greyDark, 30%);

	@include mq(mobile) {
		margin: 0;
	}

	.icon,
	svg {
		font-size: rem(24);
		color: $greyDark;
		margin-bottom: rem(-8);
		margin-right: $gutterMidBig;
	}

	&:hover,
	&:focus {
		background: darken($grey, 6%);
		color: inherit;
		text-decoration: none;
	}

	&.active {
		background: darken($grey, 6%);
		color: darken($contrastColor, 7%);

		.icon,
		svg {
			color: $contrastColor;
		}
	}

	@include mq(mobile) {
		overflow: inherit;

		&:hover {
			background: none;
		}

		.icon,
		svg {
			margin-right: 0;
		}

		.link-aside-right__copy {
			display: none;
		}

		// .link-aside-right__copy{
		// 	font-size: rem(10);
		// 	display: block; position: absolute;
		// 	bottom: rem(-10); left: 50%;
		// 	transform: translate3d(-50%,0,0);
		// };
		&.active {
			margin-left: rem(0);
			background: none;
		}
	}

	@include mq(little-mobile) {
		padding: rem(18);
	}

	&.-back {
		color: $greyDark;
		font-weight: bold;

		//padding-top: rem(8);
		//padding-bottom: rem(8);

		.icon,
		svg {
			transform: scale(.6);
		}

		/*
		line-height:rem(16);
		padding-top: rem(8);
		padding-bottom: rem(8);
		.icon, svg{
			line-height:inherit;
			font-size:rem(16);
			color:$greyDark;
			margin-left:rem(4);
			margin-right:rem(24);
			margin-top:rem(-4);
		}*/
		&:hover {
			background: inherit;
		}
	}
}

/*
	#LINK-ASIDE-LEFT
	#LINK-ASIDE-LEFT
	#LINK-ASIDE-LEFT
*/
.link-aside-left {
	cursor: pointer;
	display: block;
	font-size: rem(14);
	line-height: rem(20);
	padding: $gutter;
	font-weight: 700;
	background: darken($contrastColor, 20%);
	text-decoration: none;
	color: $black;
	// text-transform: uppercase;
	margin: $gutterMidSmall 0;

	@include mq(mobile) {
		margin: $gutterMidSmall;
	}

	// width: 54px;
	border: 0;
	// border-radius: $borderRadius;
	border-radius: 50%;
	transition: all 0.2s ease;

	&:hover {
		background: darken($contrastColor, 25%);
	}

	.icon,
	svg {
		font-size: rem(24);
		color: darken($grey, 30%);
		margin-bottom: rem(-6);
		// margin-right: rem(7);
		color: $white;
		margin-right: rem(0);
		margin-left: rem(0);
	}

	// &.active, &.-active{ background: $contrastColor; color: $white; }
	&--logo {
		position: relative;
		// display: flex;
		// align-items: center;
		// padding: 0 !important;
		background: $white;
		//shame
		// margin: 0 !important;
		min-width: rem(48);

		&:before {
			content: '';
			display: block;
			padding-bottom: 90%;
		}

		&:hover {
			background: none;
		}

		img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate3d(-50%, -50%, 0);
			width: 70%;
		}
	}

	&--notif {
		position: relative;

		//shame
		// margin-left: 0 !important;
		&:before {
			display: none;
		}

		// &.active{
		// 	// background: $grey;
		// 	// background: darken($contrastColor, 20%);
		// }
		// &.-open{
		// 	// background: $contrastColor;
		// 	svg{ color: rgba($white, 0.8); }
		// 	.nav-link__number-notif{
		// 		border-color: $contrastColor;
		// 		background: $white;
		// 		color: $contrastColor;
		// 	}
		// }
	}

	&--notif.-blink {
		svg {
			transform-origin: 70% 0;
			animation-name: blink;
			animation-duration: 2s;
			animation-iteration-count: infinite;
		}
	}

	&--profile {
		// white-space: nowrap;
		// line-height: rem(16);
		// border: $border; border-color:darken($grey, 5%);
		// &:after{
		// 	content:'';
		// 	display: inline-block;
		// 	width: $gutterMidSmall;  height: $gutterMidSmall;
		// 	border-bottom: $border; border-right: $border;
		// 	border-color: $greyDark;
		// 	margin-left: calc( #{$gutterMidBig} / 2);
		// 	transform: rotate(45deg); margin-bottom: rem(4); margin-top: rem(-2);
		// }

	}

	&.-active {
		color: darken($contrastColor, 20%);
		;
		background: $white;

		svg {
			color: darken($contrastColor, 20%);
			;
		}
	}

}

.link-aside-left__number-notif {
	position: absolute;
	z-index: 1;
	font-size: 10px;
	font-weight: 700;
	right: rem(6);
	top: rem(11);
	width: rem(20);
	height: rem(20);
	border-radius: 50%;
	line-height: rem(16);
	color: $white;
	background: $red;
	text-align: center;
	border: rem(2) solid darken($contrastColor, 20%);
}

.container-profile {
	position: absolute;
	z-index: 99;
	right: $gutterMidSmallNeg;
	bottom: $gutterMidSmall;
	padding: $gutterMidSmall 0;
	background: $white;
	// width: 200px;
	transform: translate3d(100%, 0%, 0);
	border-radius: $gutterSmall;
	// border: $border;
	border-color: darken($grey, 5%);
	box-shadow: $shadow;
	display: none;
	overflow: hidden;

	&.-display {
		display: block;
	}

	@include mq(mobile) {
		right: rem(179);
		bottom: rem(-147);
	}
}

.container-profile__el {
	font-size: rem(14);
	font-weight: 500;
	display: block;
	text-decoration: none;
	color: $black;
	padding: $gutter $gutterMidBig;
	white-space: nowrap;

	&:hover {
		background: $grey;
	}

	&--title {
		color: $greyDark;
		text-transform: uppercase;

		&:hover {
			background: none;
		}
	}
}

/*
	#NOTIF
	#NOTIF
	#NOTIF
*/
@keyframes animNotif {
	from {
		transform: translate3d(-100%, 0, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

.container-notif {
	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	width: rem(660);
	height: 100vh;
	// padding: $gutter; padding-left: 0;
	background: $white;
	transform: translate3d(-100%, 0, 0);
	display: flex;
	flex-direction: row;
	transition: all 0.3s ease;

	&.-display {
		transform: translate3d(rem(82), 0, 0);

		@include mq(mobile) {
			transform: translate3d(0, 0, 0);
		}
	}

	@include mq(mobile) {
		width: 100vw;
		left: initial;
		right: 0;
		transform: translate3d(100%, 0, 0);
	}
}

.container-notif__container {
	// display: flex;
	padding: $gutterMidSmall $gutter;
	background: $white;

	&--aside {
		min-width: rem(84);
		background: $grey;
		//@include mq(mobile){ display: none;}
	}
}

.container-notif__btn-tt {
	display: block;
	padding: $gutter;
	background: $white;
	width: 100%;
	border-radius: $borderRadius;
	text-align: center;
	font-weight: 700;
	text-decoration: none;
	color: $black;
	margin: $gutterMidSmall 0;

	&:before {
		content: '';
		display: inline-block;
		padding-bottom: 80%;
	}
}

.container-notif__btn-back {
	font-size: rem(14);
	padding: $gutter;
	background: transparent;
	border: none;
	border-radius: $borderRadius;
	width: 100%;
	margin: $gutterMidSmall 0;

	&:hover {
		background: darken($grey, 4%);
	}
}

.container-notif__title {
	font-size: rem(20);
	text-transform: uppercase;
	font-weight: 700;
	color: $greyDark;
	padding-top: rem(80);
	padding-bottom: $gutter;

	@include mq(mobile) {
		padding-top: $gutter;
	}
}

.overlay-notification {
	position: fixed;
	z-index: 99;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: darken($contrastColor, 30%);
	opacity: 0;
	transform: translate3d(-100%, 0, 0);
	transition: opacity 0.3s ease;

	&.-display {
		opacity: 0.7;
		transform: translate3d(rem(82), 0, 0);

		@include mq(mobile) {
			transform: translate3d(0, 0, 0);
		}
	}
}

.notif-list {
	height: 100%;
	background: $white;
	overflow: scroll;
}

.notif-el {
	display: flex;
	justify-content: space-between;
	margin: 0;
	padding: $gutter;
	margin: $gutter 0;
	line-height: rem(20);
	border-radius: $borderRadius;

	// &:hover{
	// 	background: rgba($grey, .3);
	// }
	&--read {
		background: $grey;

		* {
			opacity: 0.7;
		}
	}

	@include mq(mobile) {
		padding: $gutter;
	}
}

.notif-el__container {
	display: flex;
	flex-direction: column;
	width: 68%;

	&:nth-child(2) {
		justify-content: center;
		width: 28%;
	}
}

.notif-el__title {
	font-size: rem(16);
}

.notif-el__description {
	font-size: rem(16);
	color: $greyDark;
}

.notif-el__moment {
	font-size: rem(12);
	color: $greyDark;
}

.notif-el__button {
	// @extend button;
	// @extend button--light;
	// padding: rem(7.5);
	@include button-default(small, light);

	&:nth-child(2) {
		margin-top: calc(#{$gutterMidBig} / 2);
	}

	&--reindex {
		color: $green;
		border-color: rgba($green, .3);

		&:hover {
			background: rgba($green, .1);
		}
	}

	&--ok {
		color: $contrastColor;
		border-color: rgba($contrastColor, .3);

		&:hover {
			background: rgba($contrastColor, .1);
		}
	}
}

/*
	#SUBNAV
	#SUBNAV
	#SUBNAV
*/
//
// .title-main{
// 	position: relative;  z-index: 2;
// 	font-size: rem(30);
// 	text-align: center;
// 	padding: rem(30);
// 	color: $contrastColor;
// 	background: $white;
// }
//

.subnav-container {
	background: $white;
	border: $border;
	text-align: center;
	margin: 0 $gutter;
	border-radius: $borderRadius;
}

.subnav-list {
	width: auto;
	flex-wrap: nowrap;
	position: relative;
	overflow-x: auto;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
	padding: 0 rem(15);
	/* Shadow covers */
	/* https://codepen.io/jordanmoore/pen/pnlAi */
	background: linear-gradient(90deg, white 30%, rgba(255, 255, 255, 0)), linear-gradient(90deg, rgba(255, 255, 255, 0), white 70%) 100% 0,
		/* Shadows */
		radial-gradient(0 50%, farthest-side, rgba(0, 0, 0, .05), rgba(0, 0, 0, 0)), radial-gradient(100% 50%, farthest-side, rgba(0, 0, 0, .05), rgba(0, 0, 0, 0)) 100% 0;
	/* Shadow covers */
	background: linear-gradient(90deg, white 30%, rgba(255, 255, 255, 0)), linear-gradient(90deg, rgba(255, 255, 255, 0), white 70%) 100% 0,
		/* Shadows */
		radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, .05), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, .05), rgba(0, 0, 0, 0)) 100% 0;
	background-repeat: no-repeat;
	background-color: white;
	background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
	/* Opera doesn't support this in the shorthand */
	background-attachment: local, local, scroll, scroll;
}

.subnav-list__el {
	display: inline-block;
	white-space: nowrap;
}

.subnav-list__el-target {
	position: absolute;
	z-index: 2;
	left: 0;
	bottom: rem(-1);
	width: 25%;
	height: rem(4);
	background: $contrastColor;
	transition: all 0.3s ease;
}

.subnav-list__link {
	display: block;
	text-decoration: none;
	color: $greyDark;
	text-transform: uppercase;
	font-weight: 500;
	font-size: rem(16);
	padding: $gutterBig;

	&.active {
		color: $contrastColor;
	}
}