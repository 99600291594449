.actions-container {
	@extend %extended-panels-container;

	.actions-sidebar {
		@extend %extended-panels-sidebar;
	}

	.actions-item {
		@extend %extended-panels-item;
	}

	.actions-edit {
		@extend %extended-panels-edit;
	}

	.form-action {
		display: flex;
		width: 100%;
		flex-direction: column;
	}

	.action-panel {
		background-color: $white;
		margin-bottom: rem(24);
		width: 100%;

		.action-inner-edit,
		.action-population-footer {
			padding: rem(16) rem(24);
		}

		.action-parameters {
			padding-top: $gutter*2;

			.action-parameters-label {
				// font-weight: bold;
				// color: $greyDark;
				// padding-bottom:$gutter;


					@extend %label;
			}
		}
	}
}
