
/*
	#RULES
	#RULES
	#RULES
*/
.scoring-container{
	.scoring-container__title{
		display: flex;
		margin: 0 0 25px 0;

		input {
			width: 40%;
			background: $white;
			margin: 0 0 0 auto;
			padding: 15px;
		}
	}
}
.scoring-container__button{
	@include button-default(big, color);
	display: block;
	margin: $gutterBig auto;
}
.scoring-list{
	background: $white;
	border: $border;
	border-radius: $borderRadius;
	margin-bottom: $gutterBig;
}
.scoring-el{
	display: flex;
	border-bottom: $border;
	padding: $gutterBig;
	overflow: hidden;
	&:last-child{  border-bottom: 0;  }
	@include mq(mobile){ padding: $gutter; }
}
	.scoring-el__button, .scoring-el__button-edit{
		@include button-default(medium, light);
		width: auto;
		margin: auto;
			&:nth-child(2n+2){
				margin-left:$gutter;
			}
	}
	.scoring-el__container{
		// display: flex;
		// flex-direction: column;
		// align-items: flex-start;
		// justify-content: space-around;
		padding-left: rem(30);
		flex-grow: 1;
		position: relative; z-index: 2;
		overflow: hidden;
		// text-overflow: ellipsis;
		@include mq(mobile){ padding-left: $gutter; }
	}
	.scoring-el__title{
		font-size: rem(16);
		line-height: rem(30);
		margin-bottom: rem(15);
		font-weight: 700;
		text-transform: uppercase;
		color: $greyDark;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		@include mq(mobile){ margin-bottom: $gutterSmall; }
	}
	.scoring-el__description{
		font-size: rem(14);
		line-height: rem(20);


	}
/*
	#RULESETS BUTTON
	#RULESETS BUTTON
	#RULESETS BUTTON
*/
.scoring-button{
	width: rem(90); height: rem(90);
	line-height: rem(90);
	text-align: center;
	border: 0;
	background: transparent;

	font-size: rem(30);
	font-weight: 700;
	color: darken($grey, 10%);
	position: relative; z-index: 1;

	@include mq(mobile) {

		width: rem(60); height: rem(60);
		line-height: rem(60);
	}

	&:before, &:after{
		content:'';
		position: absolute; z-index: 0;
		left: 50%; top: 50%;
		width: 100%; height: 100%;
		background: lighten($grey, 3%);
		border-radius: 50%;
		transform: translate3d(-50%,-50%,0) scale(0);
		transition: all .3s ease;
	}
	&:after{
		background: $contrastColor;
	}
	&:hover{
		&:before{transform: translate3d(-50%,-50%,0) scale(1); }
		.scoring-button__check{
			transform: rotate(360deg);
			&:before, &:after{ opacity: 0;}
		}
		.scoring-button__check .svg .check{animation: check .4s ease forwards;}
	}

	&--checked{
		&:hover{&:before{ transition: all .6s ease-out; opacity:0; transform: translate3d(-50%,-50%,0) scale(8); }}
		&:before{opacity:0; transform: translate3d(-50%,-50%,0) scale(5); }
		&:after{ transform: translate3d(-50%,-50%,0) scale(1); }
		.scoring-button__check{
			transform: rotate(360deg);
			&:before, &:after{ opacity: 0;}
		}
		.scoring-button__check{ transition: border 0s ease .3s; border: rem(2) solid $contrastColor;}
		.scoring-button__check .svg .check{ stroke: $white; animation: check .4s ease forwards;}

	}

}
	.scoring-button__check{
		position: absolute;	z-index: 1;
		top:0; left: 0;
		display: inline-block;

		transition: all .3s ease;
		width: 100%; height: 100%;
		border: $border;
		border-radius: 50%;
		&:before, &:after{
			content: '';
			position: absolute;
			left: 50%; top: 50%;
			width: rem(20); height: rem(2);
			background: darken($grey, 10%);
			transition: all .3s ease;
		}
		&:before{
			transform: translate3d(-50%, -50%,0) rotate(-45deg);
		}
		&:after{
			transform: translate3d(-50%, -50%,0) rotate(45deg);
		}
		.svg{
			position: absolute; z-index: 1;
			width: rem(24);
			left: 50%; top: 50%;
			transform: translate3d(-50%, -50%,0);
			// background: blue;
			.check{
				stroke: $greyDark;
				fill: none;
				stroke-width: 8;
				stroke-dasharray: 400;
				stroke-dashoffset: 400;
			}
		}
	}
@keyframes check {
	0% { stroke-dashoffset: -400; }
	100% { stroke-dashoffset: 0; }
}
/*
	#RULES LIST
	#RULES LIST
	#RULES LIST
*/
.rules-list-container{
	background: $white;
	margin: $gutterBig 0;
	border: $border;
	border-radius: $borderRadius;
}
	.rules-list-container__title{
		@extend .form-config__title;
		display: flex;
		align-items: center;

		.input-filters {
			width: 40%;
			padding: 15px;
			margin: 0 0 0 auto;
		}
	}
.rules-list{
	counter-reset: number;
}
	.rules-list__el{
		list-style-type: none;
		counter-increment: number;
		display: flex;
		background: $white;
		margin: $gutterBig;
		@include mq(mobile){ margin: $gutterMidBig;}
		border: $border;
		border-radius: $borderRadius;
		&:before{
			content: counter(number);
			display: inline-block;
			min-width: rem(60); min-height: rem(60);
			border: $border;
			border-radius: 50%;
			line-height: rem(60);
			text-align: center;
			// font-weight: 700;
			font-size: rem(25);
			color: $greyDark;
			background: lighten($grey, 4%);
			margin: $gutterBig; @include mq(mobile){ margin: $gutter;}
		}
	}
	.rules-list__name{
		flex-grow: 1;
		padding: $gutterBig; @include mq(mobile){ padding: $gutter;} @include mq(little-mobile){ padding: 0;}
		display: flex;
		align-items: center;
		overflow: hidden;
  		text-overflow: ellipsis;
	}
	.rules-list__button{
		@include button-default(medium, light);
		width: auto;
		margin: auto rem(30);
			@include mq(mobile){ margin: auto $gutter;}
	}
.rules-list-grab{
	cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
	cursor: -webkit-grab;
	&:active{
		cursor: grabbing !important;
		cursor: -moz-grabbing !important;
		cursor: -webkit-grabbing !important;
	}

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: rem(60);
	border-left: $border;
	background: lighten($grey, 4%);
}
	.rules-list-grab__el{
		position: relative;
		width: rem(18); height: rem(4);
		margin: rem(2.5);
		border-radius: rem(2);
		background: darken($grey, 5%);
		&:before, &:after{
			content: '';
			position: absolute;
			width: 100%;  height: 100%;
			border-radius: rem(2);
			background: darken($grey, 5%);
		}
		&:before{
			left: 0;  top: 0;
			transform: translate3d(0,-220%, 0);
		}
		&:after{
			left: 0;  bottom: 0;
			transform: translate3d(0,220%, 0);
		}
	}
