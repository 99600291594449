.identification-container{
	@extend .trackers-container;
}

.identification-subcontainer{
	@extend .trackers-subcontainer;
}

.identification-item-subcontainer{
	width:100%;
}

.form-identification__title{
	@extend .form-config__title
}

.form-identification__container{
	@extend .form-config__container-flex;

	flex-direction: column;
	margin-bottom:rem(24);
}
.form-identification__description{
	margin:rem(24) 0;
}
.form-identification__button{
	@extend .form-config__button
}
